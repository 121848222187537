import { Container, Sprite, Text } from "pixi.js";
import gsap from "gsap";
import { GameView } from "../../../GameView";
import { Translator } from "../../../common/src/utils/translation/Translator";
import { FontManager } from "../../../common/src/utils/FontManager";

export class SplashMultiplierPage extends Container {
  private reelContainer!: Container;
  private scaleObjects: Container[] = [];
  private arrowSprite!: Sprite;
  private text!: Text;

  constructor() {
    super();
    this.createReel();
    this.createBigArrow();
    this.createCombos();
    this.startComboAnimation();
    this.text = this.createText("multiplier_feature_text");
    this.addChild(this.text);
  }

  private createText(translationKey: string): Text {
    const text = new Text({
      text: Translator.instance.getText(translationKey),
      style: {
        fontFamily: FontManager.instance.defaultFontFamily,
        fontSize: 20,
        fill: 0xffffff,
        align: "center",
      },
    });
    text.resolution = 2;
    text.anchor.set(0.5, 0.5);
    text.position.set(
      GameView.instance.width / 2,
      GameView.instance.height / 2 + 103
    );
    return text;
  }

  private createReel(): void {
    this.reelContainer = new Container();
    const reelSprite = Sprite.from("reel2");
    reelSprite.anchor.set(0.5);
    this.reelContainer.addChild(reelSprite);
    this.reelContainer.position.set(
      GameView.instance.width / 2 + 4,
      GameView.instance.height / 2 - 47
    );
    this.reelContainer.scale.set(0.8);
    this.addChild(this.reelContainer);
  }

  private createCombos(): void {
    const data = [
      { texture: 2, x: -164, y: 110, gap: 75, comboY: 8, scale: 0.8 },
      { texture: 4, x: -62, y: 80, gap: 85, comboY: 11, scale: 0.8 },
      { texture: 6, x: 16, y: 20, gap: 95, comboY: 13, scale: 0.8 },
      { texture: 10, x: 131, y: -82, gap: 28, comboY: 57, scale: 0.8 },
    ];

    data.forEach(({ x, y, comboY, gap, texture, scale }, index) => {
      const container = new Container();
      container.position.set(x, y);

      const multi = Sprite.from(`x${texture}`);
      multi.anchor.set(0.5);
      multi.scale.set(scale);
      container.addChild(multi);

      const combo = Sprite.from(`combo_${index + 1}`);
      combo.anchor.set(0.5);
      combo.position.set(gap, comboY);
      combo.scale.set(scale);
      container.addChild(combo);

      this.reelContainer.addChild(container);
      this.scaleObjects.push(container);
    });
  }

  private createBigArrow(): void {
    this.arrowSprite = Sprite.from("arrow4");
    this.arrowSprite.anchor.set(0.5);
    this.arrowSprite.position.set(-85, 5);
    this.arrowSprite.scale.set(0.8);
    this.reelContainer.addChild(this.arrowSprite);
  }

  public startComboAnimation(): void {
    const timeline = gsap.timeline({ repeat: -1 });

    // Animate first 3 scaleObjects
    this.scaleObjects.forEach((scaleObj) => {
      timeline.to(scaleObj.scale, {
        x: 1.2,
        y: 1.2,
        duration: 0.6,
        ease: "power3.out",
      });
    });

    // Animate arrowSprite
    timeline.to(this.arrowSprite.scale, {
      x: 1,
      y: 1,
      duration: 0.4,
      ease: "power2.in",
    });

    // Animate last combo
    for (let i = 0; i < 5; i++) {
      timeline
        .to(this.scaleObjects[3].scale, {
          x: 1.2,
          y: 1.2,
          duration: 0.4,
          ease: "power2.in",
        })
        .to(this.scaleObjects[3].scale, {
          x: 1,
          y: 1,
          duration: 0.6,
          ease: "none",
        });
    }

    timeline
      .to(this.scaleObjects[3].scale, {
        x: 1.2,
        y: 1.2,
        duration: 0.4,
        ease: "power2.in",
      })
      .to(this.arrowSprite.scale, {
        x: 1,
        y: 1,
        duration: 0.4,
        ease: "power2.in",
      });

    // Reset all scaleObjects
    this.scaleObjects.forEach((combo) => {
      timeline.to(combo.scale, {
        x: 1,
        y: 1,
        duration: 0.6,
        ease: "power2.in",
      });
    });
    timeline.to(this.arrowSprite.scale, {
      x: 0.8,
      y: 0.8,
      duration: 0.4,
      ease: "power2.out",
    });
    timeline.to({}, { duration: 0.7 }); // Delay
  }
  public hide(): void {
    gsap.killTweensOf("*");
    this.visible = false;
    this.destroy({ children: true });
  }
}
