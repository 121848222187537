import { Container, Sprite, Text } from "pixi.js";
import { GameView } from "../../GameView";
import { BitmapTextExtended } from "../../common/src/utils/BitmapTextExtended";
import { Translator } from "../../common/src/utils/translation/Translator";
export class FreeSpinPanel extends Container {
  private static _instance: FreeSpinPanel | null = null;
  private amountBitmapText!: BitmapTextExtended;

  private textSprite!: Sprite;
  private textSprite1!: Sprite;
  private constructor() {
    super();
    this.setupContainer();
    this.createTextSprite();
    this.createAmountBitmapText();
  }

  public static get instance(): FreeSpinPanel {
    if (!this._instance) {
      this._instance = new FreeSpinPanel();
    }
    return this._instance;
  }

  private setupContainer(): void {
    this.scale.set(0.85, 0.85);
    this.x = GameView.instance.width / 2 - 100;
    this.y = 50;
    this.visible = false;
  }

  private createTextSprite(): void {
    this.textSprite =
      Translator.instance.language === "th"
        ? Sprite.from("fsTitleprefixTH")
        : Sprite.from("fs_panel_text");
    this.addChild(this.textSprite);
    this.textSprite.y = -2;

    this.textSprite.scale.set(0.88);

    if (Translator.instance.language === "th") {
      this.textSprite1 = Sprite.from("fsTitleSufixTH");
      this.textSprite1.position.set(170, 0);
      this.addChild(this.textSprite1);
    }
  }

  private createAmountBitmapText(): void {
    this.amountBitmapText = new BitmapTextExtended({
      text: "10",
      style: {
        fontFamily: "fs_bm",
        fontSize: 70,
      },
    });
    this.amountBitmapText.anchor.set(0.5);
    this.amountBitmapText.position.set(
      Translator.instance.language === "th" ? 135 : 169,
      -3
    );
    this.addChild(this.amountBitmapText);
  }

  public onFreeSpinStart(): void {
    this.visible = true;
    this.amountBitmapText.setText("10");
  }

  public onFreeSpinEnd(): void {
    this.visible = false;
  }

  public updateFreeSpinsAmount(amount: number): void {
    this.amountBitmapText.setText(String(amount));
  }
}
