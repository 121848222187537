import Game from "../Game";

export class Tween {
  static EASE = {
    LINEAR: (v: number) => v,
    BOUNCE: (v: number) => easeOutBounce(v),
    EASE_OUT: easeOutExpo,
    EASE_OUT_3: easeOutCubic,
    EASE_IN: easeInCubic,
    EASE_OUT_ELASTIC: easeOutElastic,
    EASE_IN_BACK: easeInBack,
    EASE_IN_OUT_BACK: easeInOutBack,
  };

  static async start(
    targetFunction: (value: number) => void,
    from: number,
    to: number,
    duration: number,
    easingFunction: (value: number) => number = Tween.EASE.LINEAR,
    stopEvent?: () => Promise<void>
  ): Promise<void> {
    if (stopEvent) {
      (async () => {
        await stopEvent();
        stop = true;
      })();
    }
    let stop = false;
    let val = 0;
    const diff = to - from;
    let time = Date.now();
    let remainingTime = duration;

    while (remainingTime > 0) {
      const delta = Date.now() - time;
      remainingTime -= delta;

      if (stop) remainingTime = 0;

      time = Date.now();
      val = easingFunction(1 - remainingTime / duration);
      targetFunction(from + val * diff);
      await new Promise((resolve) => requestAnimationFrame(resolve));
    }

    targetFunction(to);
  }
}

function easeOutBounce(x: number): number {
  const n1 = 7.5625;
  const d1 = 2.75;

  if (x < 1 / d1) {
    return n1 * x * x;
  } else if (x < 2 / d1) {
    return n1 * (x -= 1.5 / d1) * x + 0.75;
  } else if (x < 2.5 / d1) {
    return n1 * (x -= 2.25 / d1) * x + 0.9375;
  } else {
    return n1 * (x -= 2.625 / d1) * x + 0.984375;
  }
}

function easeOutExpo(x: number): number {
  return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
}

function easeOutElastic(x: number): number {
  const c4 = (2 * Math.PI) / 3;

  return x === 0
    ? 0
    : x === 1
    ? 1
    : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
}

function easeInBack(x: number): number {
  const c1 = 1.70158;
  const c3 = c1 + 1;

  return c3 * x * x * x - c1 * x * x;
}

function easeInCubic(x: number): number {
  return x * x;
}

function easeOutCubic(x: number): number {
  return 1 - Math.pow(1 - x, 3);
}

function easeInOutBack(x: number): number {
  const c1 = 1.70158;
  const c2 = c1 * 1.525;

  return x < 0.5
    ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
    : (Math.pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
}
