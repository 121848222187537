import { SoundEffectManager } from "../../../sounds/SoundEffectManager";
import { SOUNDS } from "../../../sounds/Sounds";
import { Reel, ReelLayer } from "./Reel";
import { BaseSymbol } from "./symbols/BaseSymbol";
import { CardSymbol } from "./symbols/CardSymbol";
import { WildSymbol } from "./symbols/WildSymbol";

export class ReelWildsHandler {
  constructor(private reel: Reel) {}

  public performShowCardFaceAnimation() {
    this.reel.symbols.forEach((symbol: BaseSymbol) => {
      if (symbol instanceof WildSymbol) {
        symbol.performShowCardFaceAnimation();
        SoundEffectManager.instance.playSound(SOUNDS.CARD_FLIP, false);
        this.reel.changeSymbolLayer(symbol, ReelLayer.WildWin);
      }
    });
  }

  public getMegaWilds() {
    return {
      wildsOnReel: this.reel.bigWildSymbols,
      dummySymbols: this.reel.bigWildPlaceholderSymbols,
    }; // we get this during the cascade
  }
}
