import { TranslationType } from "./TranslationType";

export const TranslationsDictionary: TranslationType = {
  Continue: {
    en: "Continue",
    th: "ดำเนินการต่อ",
  },
  confirm: {
    en: "Confirm",
    th: "ตกลง",
  },
  "Don't show next time": {
    en: "Don't show next time",
    th: "ไม่แสดงในครั้งต่อไป",
  },
  "Loading {value}%": {
    en: "Loading {value}%",
    th: "กำลังโหลด {value}%",
  },
  volatility: {
    en: "Volatility:",
    th: "พริก :",
  },
  feature_demo_playing: {
    en: "Feature Demo playing",
    th: "การเล่นสุดพิเศษ",
  },
  splash_feature_one: {
    en: "Up Bet to get more rewards",
    //TODO need update
    th: "ไพ่โป๊กเกอร์ทอง สปินเชื่อมต่อ WILD\nลุ้นรับรางวัลใหญ่",
  },
  splash_feature_two: {
    en: "Lucky Wheel 100% Winning",
    //TODO need update
    th: "กำจัดตัวคูณ combo ยิ่งเยอะ\n อัตราการจ่ายคืนยิ่งสูง",
  },
  try_demo_mode: {
    en: "Try DEMO MODE",
    th: "ทดลองเล่นเกมทันที",
  },
  close_demo_mode: {
    en: "Close Demo Mode to enter the game",
    th: "ปิดโหมดสาธิตเพื่อเข้าสู่เกม",
  },
  auto_spin_enabled: {
    en: "Auto Spin Enabled",
    th: "เปิดใช้งานการหมุนอัตโนมัติ",
  },
  auto_spin_disabled: {
    en: "Auto Spin Disabled",
    th: "ปิดใช้งานการหมุนอัตโนมัติ",
  },
  turbo_spin_enabled: {
    en: "Turbo Spin Enabled",
    th: "เปิดใช้งานการหมุนเร็ว",
  },
  turbo_spin_disabled: {
    en: "Turbo Spin Disabled",
    th: "ปิดใช้งานการหมุนเร็ว",
  },
  balance: {
    en: "Balance",
    th: "สมบัติ",
  },
  demo_mode: {
    en: "DEMO MODE",
    th: "โหมดสาธิต",
  },
  win_demo_mode: {
    en: "DEMO WIN",
    th: "โหมดสาธิต",
  },
  win: {
    en: "WIN",
    th: "คะแนนชนะ",
  },
  maximum_win_reached: {
    en: "MAXIMUM WIN REACHED",
    th: "ชนะสูงสุด",
  },
  hold_for_setting: {
    en: "Hold for setting",
    th: "กดค้างไว้เพื่อตั้งค่า",
  },
  tap_to_autoplay: {
    en: "Tap to autoplay",
    th: "คลิกเพื่อเล่นอัตโนมัติ",
  },
  bet: {
    en: "Bet",
    th: "เดิมพัน",
  },
  system: {
    en: "System",
    th: "ระบบ",
  },
  error_message: {
    en: "error message",
    th: "ข้อความผิดพลาด",
  },
  code: {
    en: "code",
    th: "รหัส",
  },
  demo_bet: {
    en: "Demo Bet",
    th: "เดิมพัน,",
  },
  total_spin: {
    en: "Total Spin",
    th: "สปันทั้งหมด",
  },
  total_spins: {
    en: "Total Spins",
    th: "สปันทั้งหมด",
  },
  single_win_ration_exceeds: {
    en: "Single win ratio exceeds",
    th: "คะแนนชนะเลิศเกิน",
  },
  stop_if_balance_less_than: {
    en: "Stop if balance<",
    th: "เหรียนที่ < เป้าหมาย\nหยุดลงแล้ว",
  },
  stop_if_balance_more_than: {
    en: "Stop if balance>",
    th: "เหรียญที่ > เป้าหมาย\nหยุดลงแล้ว",
  },
  stop_on_fs_won: {
    en: 'Stop if "Free Game" is activated',
    th: "เข้าสู่ ฟรีเกมส์ หยุด",
  },
  stop_on_bonus_won: {
    en: 'Stop if "Bonus Game" is activated',
    th: "เข้าสู่ ฟรีเกมส์ หยุด",
  },
  cancel: {
    en: "Cancel",
    th: "ยกเลิก",
  },
  start: {
    en: "Start",
    th: "เริ่ม",
  },
  enter_special_feature: {
    en: "Enter Demo Mode",
    th: "เข้าสู่การแสดงสุดพิเศษ",
  },
  features: {
    en: "Features",
    th: "รายการพิเศษ",
  },
  buy_and_play: {
    en: "Buy & Play",
    th: "ช่อพร้อมใช้งานทัน\nที่",
  },
  total_price: {
    en: "Total Price",
    th: "ยอดรวม ทั้งหมด",
  },
  price: {
    en: "Price",
    th: "จำนวน",
  },
  quantity: {
    en: "Quantity",
    th: "จำนวนที่ซื้อ",
  },
  buy_modal_desc: {
    en: "Click 'Buy & Play' to purchase and activate\nthe featured game automatically.",
    th: "ซื้อเกมพิเศษพร้อมใช้งานทันที",
  },
  press_quick_stop: {
    en: "Press quick stop",
    th: "กดหยุดด่วน",
  },
  invalid_responce_from_server: {
    en: "Invalid response from server.\n Please try again later",
    th: "การตอบกลับจากเซิร์ฟเวอร์ไม่ถูกต้อง\n กรุณาลองใหม่อีกครั้งในภายหลัง",
  },
  unknown_server_error: {
    en: "Unknown Server Error.\n Please try again later",
    th: "เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุจากเซิร์ฟเวอร์\n กรุณาลองใหม่อีกครั้งในภายหลัง",
  },
  insufficient_balance: {
    en: "Insufficient balance,\n please reduce your bet to continue playing.",
    th: "ยอดเงินของคุณไม่เพียงพอ",
  },
  transaction: {
    en: "Transaction",
    th: "หมายเหตุ",
  },

  locked_wheel: {
    en: "Bet Up Game\n\nTo unlock Lucky Wheel",
    //TOD need change
    th: "กดค้างไว้เพื่อตั้งค่า",
  },

  // Super Ace
  wild_feature_text: {
    en: "After the Golden poker wins,\nit will turn into wild in the same place.",
    th: "ไพ่โป๊กเกอร์ทอง สปินเชื่อมต่อ WILD\nลุ้นรับรางวัลใหญ่",
  },
  multiplier_feature_text: {
    en: "Getting more combos\nwill lead to higher multiplier.",
    th: "กำจัดตัวคูณ combo ยิ่งเยอะ\n อัตราการจ่ายคืนยิ่งสูง",
  },

  //Romax
  rx_feature_one: {
    en: "Clear consecutively to win more spins\nand challenge big winnings",
    th: "เคลียร์ติดต่อกันเพื่อชนะการหมุนมากขึ้น\nและท้าทายการชนะรางวัลใหญ่",
  },
  rx_feature_two: {
    en: "Defeat more beast to win more rewards",
    th: "เอาชนะสัตว์ร้ายให้มากขึ้นเพื่อ\nรับรางวัลมากขึ้น",
  },

  // Fortune Gems
  fg_feature_one: {
    en: "Gain more winnings lines to win\nbig prizes!",
    th: "รับแถวการชนะมากขึ้นเพื่อรับรางวัล\nรางวัลใหญ่!",
  },
  fg_feature_two: {
    en: "Any win can receive multiplier\nmultipliers!",
    th: "การชนะใดๆ ก็ตามสามารถรับตัวคูณ\nตัวคูณได้!",
  },

  // Money Coming
  mc_feature_one: {
    en: "Up Bet to get more rewards.",
    th: "เดิมพันเพื่อรับรางวัลมากขึ้น",
  },
  mc_feature_two: {
    en: "Lucky Wheel 100% winning.",
    th: "วงล้อนำโชคชนะ 100%",
  },
};
