// pixi
import { Container, Text } from "pixi.js";

// sounds
import { SoundEffectManager } from "../../sounds/SoundEffectManager";
import { SOUNDS } from "../../sounds/Sounds";

// entities
import { TSpine } from "../../utils/entities/TSpine";

export interface ISpineButtonAnimations {
  normal: string;
  hover?: string;
  active?: string;
}

export class SpineButton extends Container {
  public text: Text;
  public value: number = 0;
  public spine: TSpine;
  protected originalScale = { x: 1, y: 1 };
  private animations: ISpineButtonAnimations;

  constructor(
    spine: { skeleton: string; atlas: string; },
    animations: ISpineButtonAnimations,
    text: string = "",
    fontFamily?: string
  ) {
    super();
    this.cursor = "pointer";
    this.zIndex = 100;

    this.spine = TSpine.from({
      skeleton: spine.skeleton,
      atlas: spine.atlas,
      scale: 1,
      visible: true
    });

    this.spine.skeleton.setToSetupPose();

    this.addChild(this.spine);

    this.animations = animations;

    const style: any = {
      fontSize: 25,
      fill: 0xffffff,
    };

    if (fontFamily) {
      style.fontFamily = fontFamily;
    }

    this.text = new Text({
      text,
      style,
    });
    this.text.resolution = 2;
    this.text.anchor.set(0.5, 0.5);
    this.text.zIndex = 500;
    this.addChild(this.text);

    this.init();
  }

  private init(): void {
    this.spine.setImmediately(this.animations.normal, true);
    this.setupListeners();
  }

  protected setupListeners(): void {
    this.on("click", () => this.onClick());
    this.on("tap", () => this.onClick());
    this.on("pointerdown", () => this.onPointerDown());
    this.on("pointerupoutside", () => this.onPointerUp());
    this.on("pointerup", () => this.onPointerUp());
  }

  protected onClick(): void {
    SoundEffectManager.instance.playSound(SOUNDS.BUTTON_CLICK, false);
  }

  protected onPointerDown(): void {
    this.scale.set(this.originalScale.x * 0.9, this.originalScale.y * 0.9); // 90% of original size
  }

  protected onPointerUp(): void {
    this.scale.set(this.originalScale.x, this.originalScale.y);
  }

  public setOriginalScale(scale: { x: number; y: number } | number): void {
    if (typeof scale === "number") {
      this.originalScale = { x: scale, y: scale };
    } else {
      this.originalScale = scale;
    }
  }
}
