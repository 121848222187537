import { Container, Graphics, Sprite, Text, TextStyle } from "pixi.js";
import { Button } from "../../../ui/components/Button";
import { CurrencyManager } from "../../../common/src/utils/currency/CurrencyManager";
import { Input, InputOptions } from "../../../ui/components/Input";
import { GameView } from "../../../GameView";
import { Translator } from "../../../common/src/utils/translation/Translator";
import { RoundHandler } from "../../../round_handler/round_handler/RoundHandler";
import { ValueListInput } from "../../../ui/components/ValueListInput";
import { BuyBonusManager } from "./BuyBonusManager";
import { BuyBonusButton } from "./BuyBonusButton";
import Decimal from "decimal.js";
import { BetAmountSelector } from "../../control_panel/bet_amount_selector_button/BetAmountSelector";
import { BalanceDisplay } from "../../control_panel/BalanceDisplay";
import { ErrorMessageModal } from "../../popups/ErrorMessageModal";
import gsap from "gsap";
import { FontFamily, FontManager } from "../../../common/src/utils/FontManager";
import { SpineButton } from "../../../ui/components/SpineButton";

export class BuyBonusModal extends Container {
  private static _instance: BuyBonusModal;

  private buyAndPlayButton!: SpineButton;
  public quantityInput!: Input;
  private betAmountInput!: ValueListInput;
  private priceInput!: Input;
  private totalPriceInput!: Input;
  private buyBonusPrice: Decimal = CurrencyManager.instance.buyBonusPrice;
  private betAmountSelectorActiveButtonIndex: number = 0;

  private constructor() {
    super();
    this.initializePosition();
    this.createModalElements();
    this.createInputs();
    this.visible = false;
  }

  public static get instance(): BuyBonusModal {
    if (!this._instance) {
      this._instance = new BuyBonusModal();
    }
    return this._instance;
  }

  private initializePosition(): void {
    this.position.set(243, 379);
  }

  private createModalElements(): void {
    this.createOverlay();
    this.createBackground();
    this.createTitle();
    this.createYellowField();
    this.createBuyBonusImage();
    this.createConfirmButton();
    this.createCloseButton();
  }

  private createOverlay(): void {
    const overlay = new Graphics()
      .rect(0, 0, GameView.instance.width, GameView.instance.height)
      .fill({ color: 0x000000, alpha: 0.7 });
    overlay.label = "OVERLAY_GRAPHIC";
    overlay.pivot.set(overlay.width / 2, overlay.height / 2);
    overlay.scale.set(1.4);
    this.addChild(overlay);
  }

  private createBackground(): void {
    const background = Sprite.from("modal_bb");
    background.scale.set(1.025);
    background.anchor.set(0.5);
    background.alpha = 0.95;
    this.addChild(background);
  }

  private createTitle(): void {
    const title = Sprite.from(
      `buy_bonus_title_${Translator.instance.language}`
    );
    title.anchor.set(0.5);
    title.position.set(0, -216);
    title.scale.set(0.74);
    this.addChild(title);
  }

  private createYellowField(): void {
    const container = new Container();
    this.addChild(container);

    const field = Sprite.from("buy_bonus_modal_text_yellow_bg");
    field.anchor.set(0.5);
    field.position.set(0, -163);
    field.width = 464;
    field.height = 58;
    field.tint = 0xffead2;
    container.addChild(field);

    const text = new Text({
      text: Translator.instance.getText("buy_modal_desc"),
      style: this.getYellowFieldTextStyle(),
    });
    text.resolution = 2;
    text.tint = 0xFFEDA5;
    text.anchor.set(0.5);
    text.position.set(0, -166);
    container.addChild(text);
  }

  private getYellowFieldTextStyle(): Partial<TextStyle> {
    return {
      fontFamily:
        Translator.instance.language === "en"
          ? "Times New Roman"
          : FontManager.instance.defaultFontFamily,
      fontSize: Translator.instance.language === "th" ? 20 : 16,
      lineHeight: 18,
      fill: 0xF0D636,
      align: "center"
    };
  }

  private createBuyBonusImage(): void {
    const container = new Container();
    container.position.set(0, -84);
    container.scale.set(0.44);
    container.label = "BUY_BONUS_IMAGE";
    container.tint = 0xffe3b7;
    this.addChild(container);

    const image = Sprite.from("buy_bonus_image");
    image.anchor.set(0.5);
    container.addChild(image);

    const freeGameSprite = Sprite.from("free_game");
    freeGameSprite.anchor.set(0.5);
    freeGameSprite.position.set(0, 40);
    freeGameSprite.scale.set(0.9);
    container.addChild(freeGameSprite);
  }

  private createConfirmButton(): void {
    this.buyAndPlayButton = new SpineButton(
      { skeleton: 'green-button_glow.json', atlas: 'green-button_glow.atlas' },
      { normal: 'idle' },
      Translator.instance.getText("buy_and_play"),
      FontManager.instance.defaultFontFamily
    );
    this.buyAndPlayButton.text.style.align = "center";
    this.buyAndPlayButton.text.style.fontSize = 34;
    this.buyAndPlayButton.text.style.fontFamily = Translator.instance.language === "en"
      ? "Times New Roman"
      : FontManager.instance.defaultFontFamily;
    this.buyAndPlayButton.text.style.fill = 0xfff2c4;
    this.buyAndPlayButton.position.y = 197;
    this.buyAndPlayButton.scale.set(0.75, 0.7);
    this.buyAndPlayButton.setOriginalScale({x: 0.75, y: 0.7});

    this.buyAndPlayButton.on("click", () => this.onBuyAndPlayButtonClick());
    this.buyAndPlayButton.on("tap", () => this.onBuyAndPlayButtonClick());

    this.addChild(this.buyAndPlayButton);
  }

  private createCloseButton(): void {
    const xButton = new Button("button_x");
    xButton.position.set(217, -217);
    xButton.scale.set(0.75);
    xButton.on("click", () => this.hide());
    xButton.on("tap", () => this.hide());

    this.addChild(xButton);
  }

  private createInputs(): void {
    const currencyManager = CurrencyManager.instance;
    const bets = currencyManager.getBetAmounts();
    const posX = 60;
    this.betAmountInput = this.createBetAmountInput(
      bets.map((bet) => Number(bet)),
      posX,
      0,
      `${Translator.instance.getText(
        "bet"
      )}(${CurrencyManager.instance.getCurrencySymbol()})`
    );
    this.quantityInput = this.createInput(
      { min: 1, max: 99, value: 1, step: 1 },
      posX,
      40,
      Translator.instance.getText("quantity")
    );

    this.priceInput = this.createInput(
      {
        value: Number(
          new Decimal(this.betAmountInput.value).times(this.buyBonusPrice)
        ),
        disableControls: true,
        prefix: CurrencyManager.instance.getCurrencySymbol() + " ",
      },
      posX,
      80,
      Translator.instance.getText("price")
    );

    this.totalPriceInput = this.createInput(
      {
        value: Number(
          new Decimal(this.betAmountInput.value).times(
            new Decimal(this.quantityInput.value).times(this.buyBonusPrice)
          )
        ),
        disableControls: true,
        prefix: CurrencyManager.instance.getCurrencySymbol() + " ",
      },
      posX,
      120,
      Translator.instance.getText("total_price")
    );

    this.setupInputListeners();
  }

  private createInput(
    config: InputOptions,
    x: number,
    y: number,
    text: string
  ): Input {
    const input = new Input(config);
    input.position.set(x, y);
    input.scale.x = 1.25;
    input.scale.y = 1.05;
    input.alpha = 0.9;
    this.addChild(input);

    const label = new Text({ text, style: this.getInputLabelStyle() });
    label.resolution = 2;
    label.anchor.set(1, 0.5);
    label.position.x = -115;
    input.addChild(label);

    return input;
  }

  private createBetAmountInput(
    valueList: number[],
    x: number,
    y: number,
    text: string
  ): ValueListInput {
    const input = new ValueListInput(false, valueList);
    input.position.set(x, y);
    input.scale.x = 1.25;
    input.scale.y = 1.05;
    input.alpha = 0.9;
    this.addChild(input);

    const label = new Text({ text, style: this.getInputLabelStyle() });
    label.resolution = 2;
    label.anchor.set(1, 0.5);
    label.position.x = -115;
    input.addChild(label);

    return input;
  }

  private getInputLabelStyle(): Partial<TextStyle> {
    return {
      fontFamily:
        Translator.instance.language === "en"
          ? "Times New Roman"
          : FontManager.instance.defaultFontFamily,
      fontSize: 20,
      fill: 0xffffff,
    };
  }

  private setupInputListeners(): void {
    const updatePrices = () => {
      this.priceInput.updateValue(
        Number(new Decimal(this.betAmountInput.value).times(this.buyBonusPrice))
      );
      this.totalPriceInput.updateValue(
        Number(
          new Decimal(this.betAmountInput.value).times(
            new Decimal(this.quantityInput.value).times(this.buyBonusPrice)
          )
        )
      );
    };

    this.betAmountInput.setOnChange(updatePrices);
    this.quantityInput.setOnChange(updatePrices);
  }

  private onBuyAndPlayButtonClick(): void {
    this.betAmountSelectorActiveButtonIndex =
      BetAmountSelector.instance.activeButtonIndex;
    BetAmountSelector.instance.changeBet(this.betAmountInput.activeBetIndex);
    BuyBonusManager.instance.betAmount = this.betAmountInput.value;
    BuyBonusManager.instance.quantity = this.quantityInput.value;

    if (
      Number(this.totalPriceInput.value) >
      Number(BalanceDisplay.instance.balance)
    ) {
      ErrorMessageModal.instance.showModal(
        Translator.instance.getText("insufficient_balance")
      );
      return;
    }

    BuyBonusManager.instance.isBuyBonusInProgress = true;
    BuyBonusButton.instance.updateSpriteToFreeGame();
    RoundHandler.instance.handleRound();

    this.hide();
  }

  public resetBetAmountSelector(): void {
    BetAmountSelector.instance.changeBet(
      this.betAmountSelectorActiveButtonIndex
    );
  }

  public show(): void {
    this.visible = true;
  }

  public hide(): void {
    gsap.killTweensOf("*");
    this.visible = false;
  }
}
