export class ResponseFilesDropdown {
  private static _instance: ResponseFilesDropdown;
  private testJsonContainer: HTMLDivElement;
  private sel: HTMLSelectElement;

  private constructor() {
    this.testJsonContainer = document.createElement("div");
    this.sel = document.createElement("select");
    this.setupContainer();
    // Set default value to "ApiCall"
    localStorage.setItem("LOCAL_TEST_JSON_FILE", "ApiCall");
  }

  public static get instance(): ResponseFilesDropdown {
    if (!ResponseFilesDropdown._instance) {
      ResponseFilesDropdown._instance = new ResponseFilesDropdown();
    }
    return ResponseFilesDropdown._instance;
  }

  public generate(): void {
    this.populateSelect();
    this.addEventListenerToSelect();
    this.appendToDocument();
  }

  public getSelectedValue(): string {
    return this.sel.value;
  }

  private setupContainer(): void {
    this.testJsonContainer.style.position = "fixed";
    this.testJsonContainer.style.left = "0px";
    this.testJsonContainer.style.top = "0px";
    this.testJsonContainer.style.zIndex = "1";
    this.testJsonContainer.style.background = "rgb(255, 255, 255)";
    this.testJsonContainer.style.padding = "10px";
    this.testJsonContainer.style.opacity = "0.5";
  }

  private populateSelect(): void {
    // Add "ApiCall" as the first option
    const apiCallOption = document.createElement("option");
    apiCallOption.value = "ApiCall";
    apiCallOption.innerHTML = "ApiCall";
    apiCallOption.selected = true;
    this.sel.appendChild(apiCallOption);

    const testFileNames: string = process.env.testFileNames || "";
    testFileNames.split(",").forEach((file: string) => {
      const opt = document.createElement("option");
      opt.value = file;
      opt.innerHTML = file;
      opt.selected = file === process.env.API_URL;
      this.sel.appendChild(opt);
    });
  }

  private addEventListenerToSelect(): void {
    this.sel.addEventListener("change", (e) => {
      localStorage.setItem(
        "LOCAL_TEST_JSON_FILE",
        (e.target as HTMLSelectElement).value
      );
    });
  }

  private appendToDocument(): void {
    this.testJsonContainer.appendChild(this.sel);
    document.body.appendChild(this.testJsonContainer);
  }
}
