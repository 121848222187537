import { SymbolType } from "../../game/reelset/reel/symbols/SymbolType";
import { WinningCombination } from "./WinningCombination";

export class Cascade {
  reelWindow: { [key: number]: SymbolType[] };
  winningReelWindow: { [key: number]: SymbolType[] };
  totalWin: number;
  winningCombinations: WinningCombination[];

  constructor(data: any) {
    this.reelWindow = data.reelWindow;
    this.winningReelWindow = data.winningReelWindow;
    this.totalWin = data.totalWin;
    this.winningCombinations =
      data.winningCombinations?.map(
        (combo: any) => new WinningCombination(combo)
      ) || [];
  }
}
