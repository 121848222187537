import { Sprite, Texture } from "pixi.js";
import { GameView } from "../GameView";

export class Background extends Sprite {
  private static _instance: Background;

  private constructor() {
    super();
    this.texture = Texture.from("background");
    this.anchor.set(0.5);
    this.scale.set(0.76);
    this.position.set(
      GameView.instance.width / 2,
      GameView.instance.height / 2 + 20
    );
    this.zIndex = -1;
  }

  public static get instance(): Background {
    if (!this._instance) {
      this._instance = new Background();
    }
    return this._instance;
  }
}
