import { Container, Sprite } from "pixi.js";
import { GameView } from "../../GameView";
import gsap from "gsap";
import { LayerManager } from "../../utils/layers/LayerManager";
import { LayersConfig } from "../../utils/layers/LayersConfig";

export class DotSpawner extends Container {
  private static _instance: DotSpawner;
  private spawnDots: boolean = false;
  private constructor() {
    super();
    this.dotSpawner();
  }

  public static get instance(): DotSpawner {
    if (!DotSpawner._instance) {
      DotSpawner._instance = new DotSpawner();

      LayerManager.instance
        .getLayer(LayersConfig.BASE_LAYERS.OVERLAY)
        .addChild(DotSpawner._instance);
    }
    return DotSpawner._instance;
  }

  public setSpawnDots(value: boolean): void {
    this.spawnDots = value;
  }

  private async createDot(bottom: boolean) {
    const dot = Sprite.from("shine_dot2");

    // Random positioning
    const x = Math.random() * GameView.instance.width;
    const y = bottom
      ? GameView.instance.height - 250 + Math.random() * 100
      : 100 + Math.random() * 100;

    dot.position.set(x, y);

    // Random scaling and initial distance
    const scale = 0.6 + Math.random() * 0.3;
    const distance = 20 + Math.random() * 40;

    dot.scale.set(scale);
    dot.tint = 0xffff66;
    this.addChild(dot);

    let delta = 0;

    await gsap.to(dot, {
      duration: 2,
      onUpdate: () => {
        delta += 0.01;
        dot.scale.set(scale * (1 - delta));
        dot.alpha = 1 - delta;
        dot.y = bottom ? y - distance * delta : y + distance * delta;
      },
    });
    gsap.killTweensOf(dot);
    dot.destroy();
  }

  private async dotSpawner(): Promise<void> {
    if (this.spawnDots) {
      this.createDot(Math.random() > 0.5);
    }

    gsap.delayedCall(0.2, this.dotSpawner.bind(this));
  }
}
