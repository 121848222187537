import { gsap } from "gsap";
import { Sprite } from "pixi.js";
import { GameView } from "../../GameView";
import { BasePopup } from "../../ui/components/BasePopup";
import { BitmapTextExtended } from "../../common/src/utils/BitmapTextExtended";

export class FreeSpinSummaryPopup extends BasePopup {
  private static _instance: FreeSpinSummaryPopup;
  private winText!: BitmapTextExtended;

  private constructor() {
    super();
    this.initializeEntities();
  }

  public static get instance(): FreeSpinSummaryPopup {
    if (!FreeSpinSummaryPopup._instance) {
      FreeSpinSummaryPopup._instance = new FreeSpinSummaryPopup();
    }
    return FreeSpinSummaryPopup._instance;
  }

  private initializeEntities(): void {
    this.setupContainer();
    this.setupBackground();
    this.setupTreasure();
    this.setupSummaryText();
    this.setupRedSprite();
    this.createWinText();
  }

  private setupContainer() {
    this.x = GameView.instance.width / 2;
    this.zIndex = 210;
    this.visible = false;
  }

  private setupRedSprite() {
    const redSprite = Sprite.from("red");
    redSprite.anchor.set(0.5);
    redSprite.scale.set(1.53);
    redSprite.position.set(0, 23);
    redSprite.zIndex = 100;
    this.addChild(redSprite);
  }

  private setupSummaryText() {
    const summaryText = Sprite.from("summary_text");
    summaryText.anchor.set(0.5);
    summaryText.scale.set(0.7);
    summaryText.position.set(0, -107);
    summaryText.zIndex = 100;
    this.addChild(summaryText);
  }

  private setupTreasure() {
    const treasure = Sprite.from("summary_treasure");
    treasure.anchor.set(0.5);
    treasure.scale.set(0.77);
    treasure.position.set(0, 320);
    treasure.zIndex = 101;
    this.addChild(treasure);
  }

  private setupBackground() {
    const background = Sprite.from("bg_gold");
    background.anchor.set(0.5);
    background.scale.set(0.77);
    background.position.set(0, -200);
    this.addChild(background);
  }

  private createWinText() {
    this.winText = new BitmapTextExtended({
      text: "1500",
      style: {
        fontFamily: "big_win_bm",
        fontSize: 50,
      },
    });
    this.winText.anchor.set(0.5);
    this.winText.position.set(0, 19);
    this.winText.zIndex = 100;
    this.addChild(this.winText);
  }

  public async setValue(value: number): Promise<void> {
    await new Promise((resolve) => setTimeout(resolve, 200));
    this.show();
    this.winText.visible = true;
    this.winText.setText("0.00");

    const winCounter = { newValue: 0 };

    gsap.to(winCounter, {
      duration: 4,
      newValue: value,
      ease: "none",
      onUpdate: () => {
        this.winText.setText(winCounter.newValue.toFixed(2));
      },
    });
  }

  public show(): void {
    this.winText.visible = false;
    this.coinContainer.position.set(0, 50);
    super.show();
  }
}
