import { Container } from "pixi.js";
import { LayersConfig } from "./LayersConfig";

export interface ILayer {
  name: string;
  children?: ILayer[];
}

export class LayerManager {
  private static _instance: LayerManager;
  private _layers: Map<string, Container> = new Map();

  // Private constructor to ensure singleton pattern
  private constructor() {}

  // Singleton instance getter
  public static get instance(): LayerManager {
    if (!LayerManager._instance) {
      LayerManager._instance = new LayerManager();
    }
    return LayerManager._instance;
  }

  public createTreeStructure(stage: Container): void {
    this.addLayer(
      [{ name: LayersConfig.BASE_LAYERS.GAME, children: LayersConfig.layers }],
      stage
    );
  }

  public addLayer(layers: ILayer | ILayer[], parent: Container): void {
    if (!Array.isArray(layers)) {
      layers = [layers];
    }

    layers.forEach((layer) => {
      const container = new Container();
      container.label = layer.name;
      parent.addChild(container);
      this._layers.set(layer.name, container);

      if (layer.children) {
        this.addLayer(layer.children, container);
      }
    });
  }

  public getLayer(name: string): Container {
    if (!this._layers.has(name)) {
      throw new Error(`Layer with name ${name} not found`);
    }

    return this._layers.get(name)!;
  }
}
