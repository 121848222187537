import { SoundEffectManager } from "../../../sounds/SoundEffectManager";
import { SoundManager } from "../../../sounds/SoundManager";
import { Button } from "../../../ui/components/Button";

export class SoundButton extends Button {
  private static _instance: SoundButton;

  protected originalScale = { x: 0.9, y: 0.9 };

  private constructor() {
    super(undefined); // No texture passed initially
    this.setup();
  }

  public static get instance(): SoundButton {
    if (!this._instance) {
      this._instance = new SoundButton();
    }
    return this._instance;
  }

  public toggleSound(): void {
    SoundManager.instance.toggleSound();

    // Update the sprite based on the sound state
    this.updateSprite(
      `button_sound_${SoundManager.instance.isPlaySounds ? "on" : "off"}`,
      true
    );
  }

  private setup(): void {
    // Set the initial sprite to "sound on"
    this.updateSprite("button_sound_on", true);

    // Set initial position and scale
    this.position.set(36, 35 + 3 * 53); // Custom position based on your layout
    this.scale.set(this.originalScale.x, this.originalScale.y);

    // Make the button interactive
    this.interactive = true;

    // Add event listeners for click and tap
    this.on("click", this.toggleSound.bind(this));
    this.on("tap", this.toggleSound.bind(this));
  }
}
