import { Container, Sprite } from "pixi.js";

import gsap from "gsap";
import { GameView } from "../../GameView";
import { BasePopup } from "../../ui/components/BasePopup";
import { BitmapTextExtended } from "../../common/src/utils/BitmapTextExtended";

export class FreeSpinIntroPopup extends BasePopup {
  private static _instance: FreeSpinIntroPopup;
  private winText!: BitmapTextExtended;
  private textContainer!: Container;

  private constructor() {
    super();
    this.x = GameView.instance.width / 2;
    this.y = GameView.instance.height / 2;
    this.initializeBackground();
    this.initializeTextEntities();
    this.visible = false;
  }

  public static get instance(): FreeSpinIntroPopup {
    if (!FreeSpinIntroPopup._instance) {
      FreeSpinIntroPopup._instance = new FreeSpinIntroPopup();
    }
    return FreeSpinIntroPopup._instance;
  }

  public async hide(): Promise<void> {
    gsap.killTweensOf(this.textContainer);
    gsap.killTweensOf(this.winText.scale);
    super.hide();
  }

  public show(): void {
    super.show();
    this.showText();
    this.bounceText();
  }

  private initializeBackground(): void {
    const background = Sprite.from("bg_green");
    background.anchor.set(0.5);
    background.scale.set(0.77, 0.77);
    background.position.set(0, -100);
    this.addChild(background);
    this.zIndex = 50;
  }

  private initializeTextEntities(): void {
    this.textContainer = new Container();
    this.textContainer.zIndex = 60;
    this.addChild(this.textContainer);

    const introText1 = Sprite.from("intro_text_1");
    introText1.anchor.set(0.5);
    introText1.position.set(0, -160);
    this.textContainer.addChild(introText1);

    const introText2 = Sprite.from("intro_text_2");
    introText2.anchor.set(0.5);
    introText2.position.set(100, 0);
    this.textContainer.addChild(introText2);

    const introText3 = Sprite.from("intro_text_3");
    introText3.anchor.set(0.5);
    introText3.position.set(0, 160);
    introText3.scale.set(0.8, 0.8);
    this.textContainer.addChild(introText3);

    this.createWinText();
  }

  private createWinText(): void {
    this.winText = new BitmapTextExtended({
      text: "",
      style: {
        fontFamily: "fsc_bm",
        fontSize: 60,
      },
    });
    this.winText.anchor.set(0.5);
    this.winText.position.x = -60;
    this.winText.zIndex = 100;
    this.winText.setText("10");
    this.text.addChild(this.winText);
  }

  private showText(): void {
    this.textContainer.scale = 0.6;
    this.textContainer.alpha = 0;

    gsap.to(this.textContainer.scale, {
      x: 0.8,
      y: 0.8,
      duration: 0.3,
      ease: "back.out(3)",
    });

    gsap.to(this.textContainer, {
      alpha: 1,
      duration: 0.3,
    });
  }

  private bounceText(): void {
    gsap
      .timeline({ repeat: -1, yoyo: true })
      .to(this.winText.scale, {
        x: 1,
        y: 1,
        duration: 1,
        ease: "power1.inOut",
      })
      .to(this.winText.scale, {
        x: 0.8,
        y: 0.8,
        duration: 1,
        ease: "power1.inOut",
      });
  }
}
