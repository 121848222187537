export const SOUNDS: { [key: string]: string } = {
  SCATTER_COLLECT: "scatter_collect.mp3",
  SCATTER_LAND: "scatter_land_0.mp3",
  CARD_LAND: "card_land2.mp3",
  CARD_FLIP: "card_flip.mp3",
  WIN: "win.mp3",
  WIN_AFTER: "symbol_disappear.mp3",
  BIG_WILD_EXPAND: "big_wild_expand.mp3",
  BIG_WILD_ANIMATION: "big_wild_animation.mp3",
  FS_INTRO: "fs_intro.mp3",
  FS_SUMMARY: "fs_summary.mp3",
  SUPER_WIN: "super_win_fx.mp3",
  MEGA_WIN: "mega_win_fx.mp3",
  BIG_WIN: "big_win_fx.mp3",
  COMBO_SHINE_0: "combo_shine_0.mp3",
  COMBO_SHINE_1: "combo_shine_2.mp3",
  COMBO_SHINE_2: "combo_shine_3.mp3",
  COMBO_SHINE_3: "combo_shine_4.mp3",
  ANTICIPATION: "anticipation.mp3",
  BUTTON_CLICK: "button_click.mp3",
  SPIN_BUTTON_CLICK: "spin_button_click.mp3",
  REEL_FILL: "reel_fill.mp3",
  WIN_NICE: "win_nice.mp3",
  WIN_WOW: "win_wow.mp3",
  WIN_LUCKY: "win_lucky.mp3",
  WIN_J: "win_jack.mp3",
  WIN_Q: "win_queen.mp3",
  WIN_K: "win_king.mp3",
  WIN_A: "win_ace.mp3",
  WIN_H: "win_heart.mp3",
  WIN_S: "win_spade.mp3",
  WIN_C: "win_club.mp3",
  WIN_D: "win_diamond.mp3",
  WIN_2: "win_2fx.mp3",
  WIN_3: "win_3fx.mp3",
  WIN_4: "win_4fx.mp3",
  WIN_5: "win_5fx.mp3",
  WIN_6: "win_6fx.mp3",
  WIN_10: "win_10fx.mp3",
};
