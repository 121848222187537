import Game from "./Game";
import { ResponseFilesDropdown } from "./common/src/utils/ResponseFilesDropdown";
import SandboxContainer from "./SandboxContainer";

const buildEnv = process.env.buildEnv;
if (buildEnv === "sandbox") {
  new SandboxContainer();
} else {
  new Game(buildEnv!);
}

if (buildEnv != "prod" && buildEnv != "sandbox") {
  ResponseFilesDropdown.instance.generate();
}
