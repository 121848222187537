import { RoundHandler } from "../../../round_handler/round_handler/RoundHandler";
import { BalanceDisplay } from "../BalanceDisplay";
import { BetAmountSelector } from "../bet_amount_selector_button/BetAmountSelector";
import { AutoplaySettingsWindow } from "../settings_button/autoplay_settings_button/AutoplaySettingsWindow";
import { WinAmountDisplay } from "../WinAmountDisplay";
import { AutoPlayButton } from "./AutoPlayButton";

export class AutoplayManager {
  private static _instance: AutoplayManager;

  private remainingSpins: number = 0;
  public isAutoPlaying: boolean = false;

  public static get instance(): AutoplayManager {
    if (!AutoplayManager._instance) {
      AutoplayManager._instance = new AutoplayManager();
    }
    return AutoplayManager._instance;
  }

  public stopAutoplay(isShowToast: boolean = true) {
    this.isAutoPlaying = false;
    this.remainingSpins = 0;
    AutoPlayButton.instance.stopAutoplay(isShowToast);
  }

  public async startAutoplay(freeGame: boolean = false) {
    AutoPlayButton.instance.startAutoplay();
    this.isAutoPlaying = true;
    this.remainingSpins =
      AutoplaySettingsWindow.instance.totalSpinsInput.value - 1;

    if (freeGame && !this.checkAutoplayConditions(freeGame)) {
      this.stopAutoplay();
    }

    while (this.isAutoPlaying && this.checkAutoplayConditions()) {
      await this.spin();
      this.remainingSpins--;
    }
    this.stopAutoplay();
  }

  private checkAutoplayConditions(freeGame: boolean = false): boolean {
    const currentBalance = BalanceDisplay.instance.balance;
    const lastWinAmount = WinAmountDisplay.instance.currentWinAmount;

    if (
      AutoplaySettingsWindow.instance.totalSpinCheckBox.isChecked &&
      this.remainingSpins === 0
    ) {
      return false;
    }

    if (
      AutoplaySettingsWindow.instance.stopIfFreeGameActivatedCheckBox
        .isChecked &&
      RoundHandler.instance.isTriggerFreeGame
    ) {
      return false;
    }

    if (
      AutoplaySettingsWindow.instance.stopIfBalanceLessThanCheckBox.isChecked &&
      currentBalance <
        AutoplaySettingsWindow.instance.stopIfBalanceLessThanInput.value
    ) {
      return false;
    }

    if (
      AutoplaySettingsWindow.instance.stopIfBalanceMoreThanCheckBox.isChecked &&
      currentBalance >
        AutoplaySettingsWindow.instance.stopIfBalanceMoreThanInput.value
    ) {
      return false;
    }

    if (
      AutoplaySettingsWindow.instance.stopIfSingleWinRatioExceedsCheckbox
        .isChecked
    ) {
      const currentBet = BetAmountSelector.instance.betAmount;
      const winRatio = lastWinAmount / Number(currentBet);
      if (
        winRatio >
        AutoplaySettingsWindow.instance.stopIfSingleWinRatioExceedsInput.value
      ) {
        return false;
      }
    }

    if (
      freeGame &&
      AutoplaySettingsWindow.instance.stopIfFreeGameActivatedCheckBox.isChecked
    ) {
      return false;
    }

    return true;
  }

  private async spin() {
    BetAmountSelector.instance.hide();
    await RoundHandler.instance.handleRound();
  }
}
