import { Config } from "../../../config/Config";
import { Container, Sprite } from "pixi.js";

export class SuspenseLights extends Container {
  private static _instance: SuspenseLights;
  private anticipationLines: Sprite[] = [];
  private anticipationShines: Sprite[] = [];

  private constructor() {
    super();
    this.createAnticipationLines();
    this.createAnticipationShines();
    this.showSuspenseLights(-1);
  }

  public static get instance(): SuspenseLights {
    if (!SuspenseLights._instance) {
      SuspenseLights._instance = new SuspenseLights();
    }
    return SuspenseLights._instance;
  }

  public showSuspenseLights(reelIndex: number): void {
    // Hide all lights first
    this.anticipationLines.forEach((elem) => (elem.visible = false));
    this.anticipationShines.forEach((elem) => (elem.visible = false));

    // If reelIndex is -1, we don't show any lights
    if (reelIndex === -1) return;

    // Show anticipation lines and shine for the specified reel index
    this.anticipationLines[reelIndex].visible = true;
    if (this.anticipationLines[reelIndex + 1]) {
      this.anticipationLines[reelIndex + 1].visible = true;
    }
    this.anticipationShines[reelIndex].visible = true;
  }

  private createAnticipationLines(): void {
    for (let i = 0; i < Config.reelAmount + 1; ++i) {
      const light = Sprite.from("anticipation_line");
      light.anchor.set(0.5, 0.5);
      light.scale.set(4, 1.5);
      light.visible = false;

      light.position.set(
        Config.reelSize.offsetX +
          Config.reelSize.symbolWidth * i +
          Config.reelSize.gapX * i -
          3,
        Config.reelSize.offsetY + 170
      );

      this.addChild(light);
      this.anticipationLines.push(light);
    }
  }

  private createAnticipationShines(): void {
    for (let i = 0; i < Config.reelAmount; ++i) {
      const shine = Sprite.from("shine_large");
      shine.anchor.set(0.5, 0.5);
      shine.tint = 0xffff66;
      shine.alpha = 0.7;
      shine.scale.set(0.5, 0.4);
      shine.visible = false;
      shine.blendMode = "add";

      shine.position.set(
        Config.reelSize.offsetX +
          Config.reelSize.symbolWidth * (i + 0.5) +
          Config.reelSize.gapX * i,
        Config.reelSize.offsetY - 10
      );

      this.addChild(shine);
      this.anticipationShines.push(shine);
    }
  }
}
