// try again
export enum GameId {
  SuperAce = 49,
  FortuneGems = 109,
  WildAce = 181,
  MoneyComing = 51,
  RomaX = 102,
  GoldenEmpire = 103,
  MegaAce = 134,
}
