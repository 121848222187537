import { Container, Sprite, Texture } from "pixi.js";
import { Config } from "../../../../config/Config";
import { Delay } from "../../../../common/src/utils/Delay";
import { ReelSet } from "../../ReelSet";
import { SoundEffectManager } from "../../../../sounds/SoundEffectManager";
import { SOUNDS } from "../../../../sounds/Sounds";
import { SymbolType } from "./SymbolType";
import { WildSymbol } from "./WildSymbol";
import { gsap } from "gsap";
import { CustomEase } from "gsap/all";

export class BigWildSymbol extends WildSymbol {
  private cardCircleLightSprite!: Sprite;

  constructor(
    symbolIndex: number,
    reelIndex: number,
    symbolValue: SymbolType = SymbolType.BigWild,
    isFaceDown: boolean = false
  ) {
    super(symbolIndex, reelIndex, symbolValue);
    this.createBigWildSymbol();
  }

  protected createBigWildSymbol(): void {
    this.cardFrameSprite.texture = Texture.from("card_frame_2");
    this.cardFrameSprite.anchor.set(0.5, 0.5);

    this.cardCircleLightSprite = new Sprite(Texture.from("circle_light")); // shine_tiny circle_light
    this.cardCircleLightSprite.anchor.set(0.5);
    this.cardCircleLightSprite.zIndex = 30;
    this.cardCircleLightSprite.visible = false;
    this.cardCircleLightSprite.alpha = 0;
    this.cardCircleLightSprite.tint = 0xffff77;
    this.cardCircleLightSprite.label = "CARD_CIRCLE_LIGHT";
    this.container.addChild(this.cardCircleLightSprite);

    this.cardShineSprite.visible = false;
    this.cardShineSprite.alpha = 0;
  }

  public async performBigWildExpandAnimation(): Promise<void> {
    SoundEffectManager.instance.playSound(SOUNDS.BIG_WILD_ANIMATION, false);
    await this.performBeforeExpandAnimation();
    const cards: Sprite[] = [];

    for (const targetSymbol of ReelSet.instance.wildsHandler
      .originalBigWildExpandTargetSymbols) {
      const destX =
        this.container.x +
        (targetSymbol.reelIndex - this.reelIndex) *
          (Config.reelSize.symbolWidth + Config.reelSize.gapX);

      const destY =
        this.container.y +
        (this.symbolIndex - targetSymbol.symbolIndex) *
          (Config.reelSize.symbolHeight + Config.reelSize.gapY);

      const card = new Sprite(Texture.from(`symbol_${SymbolType.BigWild}`));
      cards.push(card);
      card.anchor.set(0.5, 0.5);
      card.zIndex = 10;
      card.position = this.container.position.clone();
      this.container.parent.addChild(card);

      SoundEffectManager.instance.playSound(SOUNDS.BIG_WILD_EXPAND, false);
      this.rotateSideToSide();

      await gsap.to(card, {
        x: destX,
        y: destY,
        duration: 0.166,
        ease: "linear",
      });
    }

    await this.performAfterExpandAnimation();
    cards.forEach((card) => card.destroy());
  }

  public showSmallSparkles(): void {
    // make 2 sparks appear top left and bottom right
    const spark1 = Sprite.from("sparks");
    spark1.anchor.set(0.5);
    spark1.zIndex = 80;
    spark1.visible = false;
    spark1.scale = 0.8;
    spark1.position.set(-30, -30);
    this.container.addChild(spark1);

    const spark2 = Sprite.from("sparks");
    spark2.anchor.set(0.5);
    spark2.zIndex = 80;
    spark2.visible = false;
    spark2.scale = 0.8;
    spark2.position.set(30, 30);
    this.container.addChild(spark2);

    // more spar1 to top left
    gsap.to(spark1, {
      x: -50,
      y: -50,
      alpha: 0,
      visible: true,
      duration: 0.3,
      delay: 0.3,
      ease: "power2.in",
    });

    // more spar2 to bottom right
    gsap.to(spark2, {
      x: 50,
      y: 50,
      alpha: 0,
      visible: true,
      duration: 0.3,
      delay: 0.3,
      ease: "power2.in",
      // remove on callback
      onComplete: () => {
        spark1.destroy();
        spark2.destroy();
      },
    });
  }

  private async rotateSideToSide(): Promise<void> {
    return new Promise((resolve) => {
      // Create a GSAP timeline
      const tl = gsap.timeline({
        onComplete: () => resolve(),
      });

      // Animate rotation to a bit to the right, then back to 0 with a bounce
      tl.to(this.container, {
        rotation: 0.1, // Rotate by 15 degrees; adjust as needed
        duration: 0.045,
        ease: "linear",
      })
        .to(this.container, {
          rotation: -0.1, // Rotate by 15 degrees; adjust as needed
          duration: 0.09,
          ease: "sine.out",
        })
        .to(this.container, {
          rotation: 0, // Rotate by 15 degrees; adjust as needed
          duration: 0.045,
          ease: "linear",
        });
    });
  }

  //1254 ms
  public async performBeforeExpandAnimation(): Promise<void> {
    // Make the card shine sprite visible and set its initial alpha
    this.cardShineSprite.visible = false;

    this.cardCircleLightSprite.visible = true;
    this.cardBackShineLargeSprite.visible = true;

    this.cardCircleLightSprite.scale.set(2.2, 2.2);
    this.cardBackShineLargeSprite.scale.set(0.7, 0.7);

    this.cardCircleLightSprite.alpha = 1;
    this.cardBackShineLargeSprite.alpha = 1;

    this.container.scale.set(this.wildSymbolScale.x, this.wildSymbolScale.y);

    // after flip shine and fade out 266ms
    gsap
      .timeline()
      .to([this.cardBackShineLargeSprite], {
        alpha: 0,
        duration: 0.233,
        ease: "linear",
      })
      .to(
        [this.cardCircleLightSprite],
        {
          alpha: 0,
          duration: 0.233,
          ease: "linear",
        },
        "<"
      )
      // 8 frames only card chine increase 266ms
      .to([this.cardBackShineLargeSprite], {
        alpha: 1,
        duration: 0.533,
        ease: "linear",
      })
      // All shine together until scaling 300ms
      .to(
        [this.cardCircleLightSprite],
        {
          alpha: 1,
          duration: 0.533,
          ease: "linear",
        },
        "<"
      )
      .call(() => {
        this.cardBackShineLargeSprite.alpha = 0.4;
        this.cardCircleLightSprite.alpha = 0.4;
      })
      // scale up and down 133ms
      .to([this.container.scale], {
        x: this.wildSymbolScale.x * 1.15,
        y: this.wildSymbolScale.y * 1.15,
        duration: 0.066,
        ease: "linear",
      })
      .to([this.container.scale], {
        x: this.wildSymbolScale.x * 1.05,
        y: this.wildSymbolScale.y * 1.05,
        duration: 0.066,
        ease: "linear",
      })
      .call(() => {
        this.cardBackShineLargeSprite.alpha = 0.2;
        this.cardCircleLightSprite.alpha = 0.2;
      })
      .call(() => {
        this.rotateSideToSide().then(() => this.rotateSideToSide());
      });

    await Delay.delay(1254); // 1254ms
  }

  // Perform the after expand animation
  public async performAfterExpandAnimation(): Promise<void> {
    // Total duration: ~1500ms (1.5 seconds)

    // Create small sparkle effects (duration not specified, runs concurrently)
    this.showSmallSparkles();

    // Set up initial states for various sprite components
    this.cardShineSprite.visible = false;
    this.cardShineSprite.alpha = 0;

    this.cardCircleLightSprite.visible = true;
    this.cardCircleLightSprite.alpha = 0;

    this.cardBackShineLargeSprite.visible = true;
    this.cardBackShineLargeSprite.alpha = 0;

    // All animations start concurrently at 0ms

    // Timeline for scaling animations (t0)
    // Duration: 1091ms
    const t0 = gsap.timeline();
    t0.to(this.container.scale, {
      x: this.wildSymbolScale.x * 1.15,
      y: this.wildSymbolScale.y * 1.15,
      duration: 0.05, // 50ms
      ease: "linear",
    })
      .to(this.container.scale, {
        x: this.wildSymbolScale.x,
        y: this.wildSymbolScale.y,
        duration: 0.05, // 50ms
      })
      .to(this.container.scale, {
        x: this.wildSymbolScale.x * 1.15,
        y: this.wildSymbolScale.y * 1.15,
        duration: 0.05, // 50ms
      })
      .to(this.container.scale, {
        x: this.wildSymbolScale.x * 1.1,
        y: this.wildSymbolScale.y * 1.1,
        duration: 0.05, // 50ms
      })
      .to(this.container.scale, {
        x: this.wildSymbolScale.x * 1.1,
        y: this.wildSymbolScale.y * 1.1,
        duration: 22 * 0.033, // 22 * 33ms = 726ms
      })
      .to(this.container.scale, {
        x: this.wildSymbolScale.x,
        y: this.wildSymbolScale.y,
        duration: 5 * 0.033, // 5 * 33ms = 165ms
      });

    // Animate the card shine
    // Duration: 300ms
    gsap
      .timeline()
      .to([this.cardBackShineLargeSprite], {
        alpha: 1,
        duration: 0.133,
        ease: "linear",
      })
      // All shine together until scaling 300ms
      .to(
        [this.cardCircleLightSprite],
        {
          alpha: 1,
          duration: 0.133,
          ease: "linear",
        },
        "<"
      )
      .call(() => {
        this.cardBackShineLargeSprite.alpha = 0;
        this.cardCircleLightSprite.alpha = 0;
      });

    // Timeline for circle light alpha animation (t2)
    // Duration: 400ms
    const t2 = gsap.timeline();
    t2.to(this.cardCircleLightSprite, {
      alpha: 1,
      duration: 0.2, // 200ms
    }).to(this.cardCircleLightSprite, {
      alpha: 0,
      duration: 0.2, // 200ms
    });

    // Timeline for circle light scale animation (t3)
    // Duration: 400ms
    const t3 = gsap.timeline();
    t3.to(this.cardCircleLightSprite.scale, {
      x: 2,
      y: 2,
      duration: 0.2, // 200ms
    }).to(this.cardCircleLightSprite.scale, {
      x: 3,
      y: 3,
      duration: 0.2, // 200ms
    });

    // Wait for 1500ms
    // This starts immediately and runs concurrently with the animations
    await Delay.delay(1500);

    // Total elapsed time: 1500ms
  }

  public override destroy(): void {
    // Stop any ongoing animations specific to BigWildSymbol
    gsap.killTweensOf(this.cardCircleLightSprite);
    gsap.killTweensOf(this.cardBackShineLargeSprite);

    // Destroy specific sprites for BigWildSymbol
    this.destroySprite(this.cardCircleLightSprite);

    // Call the parent class destroy method
    super.destroy();
  }

  // Remove the private destroySprite method as it's now in BaseSymbol
}
