// FreespinHandler.ts

import { Round } from "../round_data/Round";
import {
  MultiplierPanel,
  MultiplierType,
} from "../../game/header/MultiplierPanel";

import { FreeSpinPanel } from "../../game/header/FreeSpinPanel";
import { GameLogo } from "../../game/header/GameLogo";
import { SpotLights } from "../../game/header/SpotLights";

import { Delay } from "../../common/src/utils/Delay";
import { RegularSpinHandler } from "./RegularSpinHandler";
import { SOUNDS } from "../../sounds/Sounds";
import { SpinButton } from "../../game/control_panel/SpinButton";
import { SoundEffectManager } from "../../sounds/SoundEffectManager";
import { BackgroundMusicManager } from "../../sounds/BackgroundMusicManager";
import { FreeSpinIntroPopup } from "../../game/popups/FreeSpinIntroPopup";
import { FreeSpinRetriggerPopup } from "../../game/popups/FreeSpinRetriggerPopup";
import { FreeSpinSummaryPopup } from "../../game/popups/FreeSpinSummaryPopup";
import { BuyBonusButton } from "../../game/header/buy_bonus/BuyBonusButton";
import { BuyBonusManager } from "../../game/header/buy_bonus/BuyBonusManager";
import { BuyBonusModal } from "../../game/header/buy_bonus/BuyBonusModal";
import { DotSpawner } from "../../ui/lights/DotSpawler";

import { AutoplayManager } from "../../game/control_panel/auto_play_button/AutoplayManager";
import { RoundHandler } from "./RoundHandler";

export class FreeSpinHandler {
  private static _instance: FreeSpinHandler;
  private regularSpinHandler: RegularSpinHandler;
  public isFreeSpinStarted: boolean = false;
  public freeSpinsLeft: number = 0;

  private constructor() {
    this.regularSpinHandler = RegularSpinHandler.instance;
  }

  public static get instance(): FreeSpinHandler {
    if (!FreeSpinHandler._instance) {
      FreeSpinHandler._instance = new FreeSpinHandler();
    }
    return FreeSpinHandler._instance;
  }

  public async handleFreeSpin(roundData: Round): Promise<void> {
    for (let spinIndex = 0; spinIndex < roundData.spins.length; spinIndex++) {
      const spinData = roundData.spins[spinIndex];
      await this.regularSpinHandler.handleRegularSpin(spinData);
      this.freeSpinsLeft -= 1;

      if (spinData.isTriggerFreeGame) {
        if (spinIndex === 0) {
          this.freeSpinsLeft = 10;
          await this.handleInitialFreeSpinTrigger();
        } else {
          this.freeSpinsLeft += 5;
          await this.handleRetrigger();
        }
      }

      FreeSpinPanel.instance.updateFreeSpinsAmount(this.freeSpinsLeft);
      await this.waitForSpinButtonClick();
    }

    await this.handleFreeSpinEnd(roundData.totalWin);
  }

  private async waitForSpinButtonClick() {
    if (AutoplayManager.instance.isAutoPlaying) {
      AutoplayManager.instance.startAutoplay(true);
    }

    if (!AutoplayManager.instance.isAutoPlaying) {
      if (!this.isFreeSpinStarted) {
        SpinButton.instance.enable();

        while (!this.isFreeSpinStarted) {
          await Delay.delay(100);
        }
        SpinButton.instance.disable();
      }
    }
  }

  private async handleInitialFreeSpinTrigger(): Promise<void> {
    SoundEffectManager.instance.playSound(SOUNDS.FS_INTRO, false);
    GameLogo.instance.visible = false;
    BackgroundMusicManager.instance.playFreeSpinBackgroundMusic();
    FreeSpinIntroPopup.instance.show();
    await Delay.delay(2500);
    FreeSpinIntroPopup.instance.hide();
    await Delay.delay(500);
    FreeSpinPanel.instance.visible = true;
    FreeSpinPanel.instance.updateFreeSpinsAmount(this.freeSpinsLeft);
    MultiplierPanel.instance.changeMultiplierType(MultiplierType.FreeSpin);
    DotSpawner.instance.setSpawnDots(true);
    await MultiplierPanel.instance.playAnimation();

    SpotLights.instance.showHideLights(true);
  }

  private async handleRetrigger(): Promise<void> {
    FreeSpinRetriggerPopup.instance.show();
    await Delay.delay(2000);
    FreeSpinRetriggerPopup.instance.hide();
    FreeSpinPanel.instance.updateFreeSpinsAmount(this.freeSpinsLeft);
    await Delay.delay(3000);
  }

  private async handleFreeSpinEnd(totalWin: number): Promise<void> {
    FreeSpinSummaryPopup.instance.setValue(totalWin);
    DotSpawner.instance.setSpawnDots(false);
    SoundEffectManager.instance.playSound(SOUNDS.FS_SUMMARY, false);
    await Delay.delay(5000);
    BackgroundMusicManager.instance.playRegularBackgroundMusic();
    SpotLights.instance.showHideLights(false);
    if (
      BuyBonusManager.instance.isBuyBonusInProgress &&
      BuyBonusManager.instance.quantity == 0
    ) {
      BuyBonusButton.instance.updateToOriginalSprite();
      BuyBonusManager.instance.isBuyBonusInProgress = false;
      BuyBonusModal.instance.resetBetAmountSelector();
    }
    FreeSpinSummaryPopup.instance.hide();
    this.isFreeSpinStarted = false;
    FreeSpinPanel.instance.onFreeSpinEnd();
    MultiplierPanel.instance.changeMultiplierType(MultiplierType.Base);
    SpotLights.instance.showHideLights(false);
    GameLogo.instance.visible = true;
    this.freeSpinsLeft = 0;
  }
}
