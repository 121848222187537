import { Button } from "../../../ui/components/Button";

export class HistoryButton extends Button {
  private static _instance: HistoryButton;
  protected originalScale = { x: 0.9, y: 0.9 };

  private constructor() {
    super(undefined);
    this.setup();
  }

  static get instance(): HistoryButton {
    if (!HistoryButton._instance) {
      HistoryButton._instance = new HistoryButton();
    }
    return HistoryButton._instance;
  }

  private setup(): void {
    this.updateSprite("button_history", true);
    this.position.set(36, 35 + 1 * 53);
    this.scale.set(this.originalScale.x, this.originalScale.y);
    this.tint = 0x888888;
  }

  protected override onPointerDown(): void {}
}
