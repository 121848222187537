import { FontManager } from "../../common/src/utils/FontManager";
import { Translator } from "../../common/src/utils/translation/Translator";
import { Button } from "./Button";
import { Container, Graphics, Text, TextStyle } from "pixi.js";

export interface InputOptions {
  max?: number;
  min?: number;
  step?: number;
  value?: number;
  postfix?: string;
  prefix?: string;
  disableControls?: boolean;
}

export class Input extends Container {
  public value: number = 0;
  private max: number = Infinity;
  private min: number = -Infinity;
  private step: number = 1;
  private postfix: string = "";
  private prefix: string = "";
  private text: Text;
  private onChange: ((value: number) => void) | null = null;
  private updateInterval: NodeJS.Timeout | null = null;
  private updateTimeout: NodeJS.Timeout | null = null;

  constructor(options: InputOptions) {
    super();
    this.initializeOptions(options);
    this.text = this.createText();
    this.addChild(this.createBackground());
    this.addChild(this.text);
    if (!options.disableControls) this.setupButtons();
    this.updateText();
  }

  public updateValue(newValue: number): void {
    const oldValue = this.value;

    if (newValue < this.min) {
      this.value = this.min;
    } else if (newValue > this.max) {
      this.value = this.max;
    } else {
      this.value = newValue;
    }

    this.updateText();
    if (this.value !== oldValue && this.onChange) {
      this.onChange(this.value);
    }
  }

  public setOnChange(callback: () => void): void {
    this.onChange = callback;
  }

  private initializeOptions(options: InputOptions): void {
    if (options.max !== undefined) this.max = options.max;
    if (options.min !== undefined) this.min = options.min;
    if (options.step !== undefined) this.step = options.step;
    if (options.value !== undefined) this.value = options.value;
    if (options.postfix !== undefined) this.postfix = options.postfix;
    if (options.prefix !== undefined) this.prefix = options.prefix;
  }

  private createText(): Text {
    const textStyle: Partial<TextStyle> = {
      fontFamily:
        Translator.instance.language === "en"
          ? "Times New Roman"
          : FontManager.instance.defaultFontFamily,
      fontSize: 20,
      fill: 0xffffff,
    };
    const text = new Text({
      text: this.value.toString(),
      style: textStyle as TextStyle,
    });
    text.resolution = 1.4;
    text.anchor.set(0.5, 0.5);
    return text;
  }

  private createBackground(): Graphics {
    const rect = new Graphics().rect(-102, -18, 204, 36).fill(0x000000);
    rect.alpha = 0.5;
    return rect;
  }

  private setupButtons(): void {
    const addButton = this.createButton("buttonAdd", 85);
    addButton.on("pointerdown", () => this.startUpdating(this.step));
    addButton.on("pointerup", () => this.stopUpdating());
    addButton.on("pointerupoutside", () => this.stopUpdating());

    const subButton = this.createButton("buttonSub", -85);
    subButton.on("pointerdown", () => this.startUpdating(-this.step));
    subButton.on("pointerup", () => this.stopUpdating());
    subButton.on("pointerupoutside", () => this.stopUpdating());
  }

  private createButton(buttonName: string, xPos: number): Button {
    const button = new Button(buttonName);
    button.position.x = xPos;
    button.scale.set(0.72);
    button.setOriginalScale(0.72);
    this.addChild(button);
    return button;
  }

  private updateText(): void {
    this.text.text = `${this.prefix}${this.value}${this.postfix}`;
  }

  private startUpdating(step: number): void {
    this.updateValue(this.value + step);

    this.updateTimeout = setTimeout(() => {
      this.updateInterval = setInterval(() => {
        this.updateValue(this.value + step);
      }, 1000 / 20); // 20 steps per second
    }, 500); // 500ms delay before starting continuous update
  }

  private stopUpdating(): void {
    if (this.updateTimeout !== null) {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = null;
    }
    if (this.updateInterval !== null) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
  }
}
