import { FontFamily } from "../../common/src/utils/FontManager";
import { Button } from "./Button";
import { Container, Text, TextStyle } from "pixi.js";

export class Checkbox extends Container {
  public isChecked: boolean;
  protected isLocked: boolean;
  public text: Text;
  protected checkButton: Button;

  constructor(
    isChecked: boolean = false,
    text: string = "",
    isLocked: boolean = false
  ) {
    super();
    this.isChecked = isChecked;
    this.isLocked = isLocked;
    this.checkButton = this.createCheckButton();
    this.setupEventHandlers();
    this.addChild(this.checkButton);
    this.text = this.createText(text);
    this.addChild(this.text);
  }

  public setButtonSize(size: number): void {
    this.checkButton.width = size;
    this.checkButton.height = size;
  }

  public toggleCheckState(): void {
    this.isChecked = !this.isChecked;
  }

  public check(): void {
    this.isChecked = true;
    this.updateCheckboxSprite();
  }

  protected createCheckButton(): Button {
    const checkButton = new Button(
      this.isChecked ? "checkbox_on" : "checkbox_off"
    );
    // Scale up the button to make it bigger
    //checkButton.scale.set(1.5); // Increase scale by 20%
    return checkButton;
  }

  private createText(text: string): Text {
    const textStyle: Partial<TextStyle> = {
      fontFamily: FontFamily.FONT_AWESOME,
      fontSize: 22,
      fill: 0xffffff,
    };
    const newText = new Text({ text, style: textStyle as TextStyle });
    newText.resolution = 1.4;
    newText.anchor.set(0, 0.65);
    newText.position.x = 20;
    newText.position.y = 5;
    return newText;
  }

  private setupEventHandlers(): void {
    this.checkButton.on("click", () => {
      this.onClick();
    });
    this.checkButton.on("tap", () => {
      this.onClick();
    });
  }

  protected onClick() {
    if (this.isLocked) return;
    this.toggleCheckState();
    this.updateCheckboxSprite();
  }

  private updateCheckboxSprite() {
    this.checkButton.updateSprite(
      this.isChecked ? "checkbox_on" : "checkbox_off",
      true
    );
  }
}
