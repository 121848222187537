import { Container, Sprite, ColorMatrixFilter, FilterOptions } from "pixi.js";
import { Delay } from "../../common/src/utils/Delay";
import { SoundEffectManager } from "../../sounds/SoundEffectManager";
import gsap from "gsap";
import { GameView } from "../../GameView";

type IMultipliers = Record<MultiplierType, Sprite[]>;

export enum MultiplierType {
  Base = "base",
  FreeSpin = "fs",
}

export class MultiplierPanel extends Container {
  private static _instance: MultiplierPanel;
  private static readonly VALUES = {
    [MultiplierType.Base]: [1, 2, 3, 5],
    [MultiplierType.FreeSpin]: [2, 4, 6, 10],
  };
  private multipliers: IMultipliers = {} as IMultipliers;
  private currentType: MultiplierType = MultiplierType.Base;
  private multiplierIndex: number = 0;
  private shine!: Sprite;
  private background!: Sprite;

  private constructor() {
    super();
    this.createMultipliers();
    this.initializeShine();
    this.initializePanel();
  }

  public get multiplierValue(): number {
    return MultiplierPanel.VALUES[this.currentType][this.multiplierIndex];
  }

  public static get instance(): MultiplierPanel {
    if (!MultiplierPanel._instance) {
      MultiplierPanel._instance = new MultiplierPanel();
    }
    return MultiplierPanel._instance;
  }

  public async playAnimation(): Promise<void> {
    for (let multiplierIndex = 0; multiplierIndex < 4; ++multiplierIndex) {
      this.updateMultiplierIndex(multiplierIndex, true);
      SoundEffectManager.instance.playMultiplierAnimationSound(multiplierIndex);
      await Delay.delay(400);
    }
    this.updateMultiplierIndex(0);
  }

  public changeMultiplierType(type: MultiplierType): void {
    this.currentType = type;
    this.background.texture = Sprite.from(`multiplier_${type}`).texture;
    this.multipliers[
      type === MultiplierType.FreeSpin
        ? MultiplierType.Base
        : MultiplierType.FreeSpin
    ].forEach((sprite) => (sprite.visible = false));
    this.background.zIndex = -1;
    this.updateMultiplierIndex(0);
  }

  public async updateMultiplierIndex(
    newMultiplierIndex: number,
    isAnimation: boolean = false
  ): Promise<void> {
    if (newMultiplierIndex > 3) return;
    this.hideAllMultipliers(isAnimation);
    this.multipliers[this.currentType][newMultiplierIndex].visible = true;
    gsap.to(this.shine, { x: -120 + newMultiplierIndex * 80, duration: 0.3 });
    const prevValue = this.multiplierIndex;
    this.multiplierIndex = newMultiplierIndex;
    if (newMultiplierIndex <= prevValue || newMultiplierIndex === 0) return;

    await this.showLightEffect(newMultiplierIndex);
  }

  private createMultipliers(): void {
    for (const type of Object.values(MultiplierType)) {
      this.multipliers[type] = MultiplierPanel.VALUES[type].map((value, j) => {
        const childSprite = Sprite.from(`multiplier_x${value}`);
        childSprite.anchor.set(0.5);
        childSprite.position.set(j * 80 - 120, 0);
        if (type === MultiplierType.FreeSpin && j === 3) {
          childSprite.x = j * 80 - 117;
        }
        this.addChild(childSprite);
        return childSprite;
      });
    }
  }

  private initializePanel(): void {
    this.background = Sprite.from(`multiplier_${MultiplierType.Base}`);
    this.background.anchor.set(0.5);
    this.addChild(this.background);

    this.changeMultiplierType(MultiplierType.Base);
    this.position.set(GameView.instance.width / 2 + 2, 123);
    this.scale.set(0.83);
    this.updateMultiplierIndex(0);
  }

  private initializeShine(): void {
    this.shine = Sprite.from("shine_dot3");
    this.shine.anchor.set(0.5);
    this.shine.alpha = 0.2;
    this.shine.scale.set(1.5, 1.5);
    this.shine.y = -17;
    this.shine.blendMode = "add";
    this.addChild(this.shine);
    this.startShineAnimation();
  }

  private startShineAnimation(): void {
    gsap.to(this.shine, {
      pixi: { alpha: 0.5, scaleX: 3.2, scaleY: 2.9 },
      duration: 1.2,
      yoyo: true,
      repeat: -1,
      ease: "sine.inOut",
    });
  }

  private hideAllMultipliers(isAnimation: boolean): void {
    this.multipliers[this.currentType].forEach((sprite, index) => {
      if (!(isAnimation && index === 0)) sprite.visible = false;
    });
  }

  private async showLightEffect(i: number): Promise<void> {
    const light = Sprite.from("shine_tiny");
    light.anchor.set(0.5);
    light.scale.set(8);
    light.tint = 0xffbe50;
    light.x = -120 + i * 80;
    this.addChild(light);

    await gsap.to(light, {
      pixi: { scale: 4, alpha: 0 },
      duration: 0.3,
      ease: "power2.out",
    });

    this.removeChild(light);
  }
}
