// pixi
import { Container, Sprite, Text } from "pixi.js";

// gsap
import { gsap } from "gsap";

// managers
import { FontManager } from "../../common/src/utils/FontManager";

// i18n
import { Translator } from "../../common/src/utils/translation/Translator";

export class ProgressBar extends Container {
  private progressFrame: Sprite;
  private progressLine: Sprite;
  private progressMask: Sprite;
  private buttonSprite: Sprite;
  private loadingText: Text;

  private _progress: number = 0;

  get progress(): number {
    return this._progress;
  }

  set progress(value: number) {
    this._progress = value;

    const progressLineDefaultX = this.progressLine.width * -1 + 1;

    const percent = this.progressLine.width / 100;

    if (percent === 100) {
      this.progressLine.x = 1;

      this.loadingText.text = Translator.instance.getText("Loading {value}%", {
        value: '100',
      });
    } else {
      this.progressLine.x = progressLineDefaultX + (value * percent);

      this.loadingText.text = Translator.instance.getText("Loading {value}%", {
        value: Math.floor(value).toString(),
      });
    }

    // update visual
  }

  constructor() {
    super();

    this.progressFrame = Sprite.from('preloader_frame');
    this.progressLine = Sprite.from('preloader_line');
    this.progressMask = Sprite.from('preloader_mask');
    this.buttonSprite = Sprite.from('button_green');
    this.loadingText = new Text({
      style: {
        fontFamily: FontManager.instance.defaultFontFamily,
        fontSize: 18,
        fill: 0xffffff,
        stroke: 0x000000,
      },
    });

    this.init();
  }

  private init(): void {
    this.progressFrame.anchor.y = 0.5;

    this.progressMask.anchor.y = 0.5;
    this.progressMask.position.set(1, 1);

    this.progressLine.mask = this.progressMask;
    this.progressLine.anchor.y = 0.5;
    this.progressLine.position.set(this.progressMask.width * -1 + 1, 1);

    this.buttonSprite.anchor.y = 0.5;
    this.buttonSprite.height = this.progressFrame.height;
    this.buttonSprite.alpha = 0;

    this.loadingText.text = Translator.instance.getText("Loading {value}%", {
      value: '0',
    });
    this.loadingText.anchor.x = 0.5;
    this.loadingText.position.set(this.progressFrame.width / 2, 15);
    this.loadingText.resolution = 2;

    this.addChild(
      this.progressFrame,
      this.progressMask,
      this.progressLine,
      this.buttonSprite,
      this.loadingText
    );
  }

  public async revealButton(height: number): Promise<void> {
    const tl = gsap.timeline();

    tl
      .to(this.progressFrame, {
        duration: 0.3,
        alpha: 0
    }, 0)
      .to(this.progressLine, {
        duration: 0.3,
        alpha: 0
    }, 0)
      .to(this.buttonSprite, {
        duration: 0.6,
        alpha: 1,
    }, 0)
      .to(this.loadingText, {
        duration: 0.3,
        alpha: 0
    }, 0)

    tl
      .to(this.buttonSprite, {
        duration: 0.5,
        height: height,
        ease: 'expo.in'
      }, 0.1);

    await tl;
  }

  public hideButton() {
    this.buttonSprite.visible = false;
  }
}
