// RegularSpinHandler.ts

import { Spin } from "../round_data/Spin";

import { MultiplierPanel } from "../../game/header/MultiplierPanel";
import { WinAmountDisplay } from "../../game/control_panel/WinAmountDisplay";

import { Delay } from "../../common/src/utils/Delay";
import { Cascade } from "../round_data/Cascade";
import { SoundEffectManager } from "../../sounds/SoundEffectManager";
import { SOUNDS } from "../../sounds/Sounds";
import { ComboPanel } from "../../game/reelset/ComboPanel";

import { BalanceDisplay } from "../../game/control_panel/BalanceDisplay";

import { TurboSpinButton } from "../../game/control_panel/TurboSpinButton";
import { RoundHandler } from "./RoundHandler";
import { ReelSet } from "../../game/reelset/ReelSet";
import { BigWinPopup } from "../../game/popups/BigWinPopup";
import { SymbolType } from "../../game/reelset/reel/symbols/SymbolType";
import { WinAmountPopup } from "../../game/popups/WinAmountPopup";
import { ElapsedTimeLogger } from "../../common/src/utils/ElapsedTimeLogger";

export class RegularSpinHandler {
  private static _instance: RegularSpinHandler;

  private constructor() {}

  public static get instance(): RegularSpinHandler {
    if (!RegularSpinHandler._instance) {
      RegularSpinHandler._instance = new RegularSpinHandler();
    }
    return RegularSpinHandler._instance;
  }

  public async handleRegularSpin(spin: Spin): Promise<void> {
    await ReelSet.instance.performSymbolExitAnimation();

    for (
      let cascadeNumber = 0;
      cascadeNumber < spin.cascades.length;
      ++cascadeNumber
    ) {
      const cascade = spin.cascades[cascadeNumber];

      await ReelSet.instance.cascadeHandler.handleCascade(
        cascade,
        spin.cascades[cascadeNumber - 1]
      );

      await ReelSet.instance.wildsHandler.handleWilds();

      await this.handleWin(cascadeNumber, cascade);

      await this.handleAfterWin(cascade); // clear animation time
    }

    // dont show big win popup inside free spin
    if (!RoundHandler.instance.freeSpinHandler.isFreeSpinStarted) {
      await BigWinPopup.instance.displayPopupIfOverThreshold(spin.totalWin);
    }
    await ReelSet.instance.checkForScatterCollect();
    MultiplierPanel.instance.updateMultiplierIndex(0);
    ComboPanel.instance.updateValue(0);
  }

  private async handleWin(
    cascadeIndex: number,
    cascade: Cascade
  ): Promise<void> {
    if (cascade.totalWin === 0) return;

    WinAmountDisplay.instance.updateWinAmount(cascade.totalWin);
    MultiplierPanel.instance.updateMultiplierIndex(cascadeIndex);
    this.playWinSounds(cascade);
    ComboPanel.instance.updateValue(cascadeIndex + 1);
    ReelSet.instance.performSymbolWinAnimation(cascade.winningReelWindow);
    WinAmountPopup.instance.displayWin(cascade.totalWin);
    await Delay.delay(1000); // win animation time
  }

  private async handleAfterWin(cascade: Cascade) {
    if (cascade.totalWin > 0) {
      ReelSet.instance.performSymbolAfterWinAnimation(
        cascade.winningReelWindow
      );

      SoundEffectManager.instance.playSound(SOUNDS.WIN_AFTER, false);
      await Delay.delay(16 * 33); // win after animation time
      ReelSet.instance.unBlur();
    }

    if (!TurboSpinButton.instance.isTurboSpinEnabled) {
      await Delay.delay(3 * 33);
    }
  }

  private playWinSounds(cascadeData: Cascade) {
    SoundEffectManager.instance.playSound(SOUNDS.WIN, false);

    // Collect all winning symbols from winningReelWindow
    const winningSymbols: SymbolType[] = [];
    for (const reelIndex in cascadeData.winningReelWindow) {
      cascadeData.winningReelWindow[reelIndex].forEach(
        (symbol, symbolIndex) => {
          if (symbol && !symbol.includes("W") && !symbol.includes("$")) {
            winningSymbols.push(
              cascadeData.reelWindow[reelIndex][symbolIndex].replace(
                "g",
                ""
              ) as SymbolType
            );
          }
        }
      );
    }

    // Remove duplicates
    const uniqueWinningSymbols = [...new Set(winningSymbols)];

    // Play win details sound
    SoundEffectManager.instance.playWinDetails(
      uniqueWinningSymbols,
      MultiplierPanel.instance.multiplierValue
    );
  }
}
