import { Container, Sprite } from "pixi.js";
import { AutoPlayButton } from "./auto_play_button/AutoPlayButton";
import { TurboSpinButton } from "./TurboSpinButton";
import { SpinButton } from "./SpinButton";
import { BetAmountSelectorButton } from "./bet_amount_selector_button/BetAmountSelectorButton";
import { InfoButton } from "./settings_button/info_button/InfoButton";
import { SettingsButton } from "./settings_button/SettingsButton";
import { SoundButton } from "./settings_button/SoundButton";
import { AutoPlaySettingsButton } from "./settings_button/autoplay_settings_button/AutoPlaySettingsButton";
import { BetAmountSelector } from "./bet_amount_selector_button/BetAmountSelector";
import { HistoryButton } from "./settings_button/HistoryButton";
import { NetworkIndicator } from "./NetworkIndicator";
import { TransactionDisplay } from "./TransactionDisplay";
import { WinAmountDisplay } from "./WinAmountDisplay";
import { BalanceDisplay } from "./BalanceDisplay";

export class ControlPanel extends Container {
  private static _instance: ControlPanel;

  public settingsMenu: Sprite;
  private spinButton!: SpinButton;
  private betButton!: BetAmountSelectorButton;
  private fastPlayButton!: TurboSpinButton;
  private settingsButton!: SettingsButton;
  private historyButton!: HistoryButton;
  private autoPlaySettingsButton!: AutoPlaySettingsButton;
  private infoButton!: InfoButton;
  private soundButton!: SoundButton;
  private autoPlayButton!: AutoPlayButton;
  private networkIndicator!: NetworkIndicator;
  public settingsMenuContainer: Container;

  private constructor() {
    super();
    this.settingsMenu = Sprite.from("menu_bg");
    this.settingsMenuContainer = new Container();
    this.settingsMenuContainer.position.set(5, 500);
    this.settingsMenuContainer.zIndex = 10;
    this.addChild(this.settingsMenuContainer);
    this.settingsMenuContainer.addChild(this.settingsMenu);
    this.settingsMenuContainer.visible = false;
    this.visible = false;

    this.setupButtons();
  }

  public static get instance(): ControlPanel {
    if (!ControlPanel._instance) {
      ControlPanel._instance = new ControlPanel();
    }
    return ControlPanel._instance;
  }

  public showAll(): void {
    TransactionDisplay.instance.show();
    this.visible = true;
    WinAmountDisplay.instance.show();
    BetAmountSelector.instance.showText();
    BalanceDisplay.instance.visible = true;
  }

  private setupButtons(): void {
    this.spinButton = SpinButton.instance;
    this.betButton = BetAmountSelectorButton.instance;
    this.fastPlayButton = TurboSpinButton.instance;
    this.settingsButton = SettingsButton.instance;
    this.historyButton = HistoryButton.instance;
    this.autoPlaySettingsButton = AutoPlaySettingsButton.instance;
    this.infoButton = InfoButton.instance;
    this.soundButton = SoundButton.instance;
    this.autoPlayButton = AutoPlayButton.instance;
    this.networkIndicator = NetworkIndicator.instance;

    this.addChild(this.spinButton);
    this.addChild(this.betButton);
    this.addChild(this.fastPlayButton);
    this.addChild(this.settingsButton);
    this.addChild(this.autoPlayButton);
    this.addChild(this.networkIndicator);

    this.settingsMenuContainer.addChild(this.historyButton);
    this.settingsMenuContainer.addChild(this.autoPlaySettingsButton);
    this.settingsMenuContainer.addChild(this.infoButton);
    this.settingsMenuContainer.addChild(this.soundButton);

    // Setup click handlers for buttons that interact with the ControlPanel
    this.settingsButton.on("click", () => this.toggleMenu());
    this.settingsButton.on("tap", () => this.toggleMenu());
  }

  public hideSetttings(): void {
    this.settingsMenuContainer.visible = false;
  }

  public toggleMenu(): void {
    this.settingsMenuContainer.visible = !this.settingsMenuContainer.visible;
    if (this.settingsMenuContainer.visible) {
      BetAmountSelector.instance.betPanel.visible = false;
    }
  }
}
