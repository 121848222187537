import { Text, TextStyle, Container } from "pixi.js";

interface PanelOptions {
  fontSize?: number;
}

export class BasePanel extends Container {
  protected text: Text;

  constructor(options: PanelOptions = {}) {
    super();

    const textStyle: TextStyle = new TextStyle({
      fontSize: options.fontSize || 50,
      fill: 0xffffff,
      fontWeight: "bold",
      stroke: 0x000000,
    });

    this.text = new Text({ text: "", style: textStyle });
    this.text.anchor.set(0.5, 0.5); // Anchoring to center
    this.addChild(this.text);
  }

  setText(text: string): void {
    this.text.text = text;
  }
}
