import { Cascade } from "../../round_handler/round_data/Cascade";
import { Config } from "../../config/Config";
import { ReelSet } from "./ReelSet";
import { SoundEffectManager } from "../../sounds/SoundEffectManager";
import { TurboSpinButton } from "../control_panel/TurboSpinButton";
import { Delay } from "../../common/src/utils/Delay";
import { SOUNDS } from "../../sounds/Sounds";
import { ElapsedTimeLogger } from "../../common/src/utils/ElapsedTimeLogger";
import { ReelSetSuspenseHandler } from "./ReelSetSuspenseHandler";

export class ReelSetCascadeHandler {
  public isReelSetCascadeAnimationInProgress: boolean = false;

  constructor() {}

  public async handleCascade(
    cascadeData: Cascade,
    prevCascadeData: Cascade | null
  ): Promise<void> {
    this.isReelSetCascadeAnimationInProgress = true;
    ReelSet.instance.reelsContainer.mask = ReelSet.instance.upOnlyMask;
    // Check if Reel Fill or Cascade
    if (!prevCascadeData) {
      for (let reelIndex = 0; reelIndex < Config.reelAmount; ++reelIndex) {
        ReelSet.instance.reels[reelIndex].cascadeHandler.handleReelFill(
          cascadeData.reelWindow[reelIndex + 1]
        );
        if (!TurboSpinButton.instance.isTurboSpinEnabled) {
          await Delay.delay(reelIndex < 2 ? 4 * 33 : 7 * 33);
        }
      }

      await this.waitForTurboSpinCascadeToFinish();

      while (this.isReelSetCascadeAnimationInProgress) {
        await Delay.delay(1);
      }
      ReelSetSuspenseHandler.instance.stopReelSetSuspenseAnimation();
      await Delay.delay(166); //after reel fill animation wait until win animation starts
    } else {
      SoundEffectManager.instance.playSound(SOUNDS.CARD_LAND, false);
      for (let reelIndex = 0; reelIndex < Config.reelAmount; ++reelIndex) {
        await ReelSet.instance.reels[reelIndex].cascadeHandler.handleCascade(
          cascadeData.reelWindow[reelIndex + 1],
          prevCascadeData.winningReelWindow[reelIndex + 1]
        );
      }
      await this.waitForTurboSpinCascadeToFinish();

      const delayBeforeWinAnimation = TurboSpinButton.instance
        .isTurboSpinEnabled
        ? 33
        : 4 * 33;
      await Delay.delay(delayBeforeWinAnimation);
    }
    ReelSet.instance.reelsContainer.mask = null;
  }

  private async waitForTurboSpinCascadeToFinish() {
    if (TurboSpinButton.instance.isTurboSpinEnabled) {
      await Delay.delay(6 * 33);
    }
  }
}
