import { FontManager } from "../../common/src/utils/FontManager";
import { Translator } from "../../common/src/utils/translation/Translator";
import { Button } from "./Button";
import { Container, Graphics, Text, TextStyle } from "pixi.js";

export class ValueListInput extends Container {
  private _activeBetIndex: number = 0;

  private text: Text;
  private _value: number = 0;

  private valueList: number[] = [];
  private onChange: ((value: number) => void) | null = null;
  private updateInterval: NodeJS.Timeout | null = null;
  private updateTimeout: NodeJS.Timeout | null = null;

  constructor(disableControls: boolean = false, valueList: number[] = []) {
    super();
    this.valueList = valueList;
    this.text = this.createText();
    this.addChild(this.createBackground());
    this.addChild(this.text);
    if (!disableControls) this.setupButtons();
    this.updateValue(0);
  }

  public get value(): number {
    return this._value;
  }

  public setOnChange(callback: () => void): void {
    this.onChange = callback;
  }

  public get activeBetIndex(): number {
    return this._activeBetIndex;
  }
  public set activeBetIndex(value: number) {
    this._activeBetIndex = value;
  }

  private createText(): Text {
    const textStyle: Partial<TextStyle> = {
      fontFamily:
        Translator.instance.language === "en"
          ? "Times New Roman"
          : FontManager.instance.defaultFontFamily,
      fontSize: 20,
      fill: 0xffffff,
    };
    const text = new Text({
      text: this._activeBetIndex.toString(),
      style: textStyle as TextStyle,
    });
    text.resolution = 2;
    text.anchor.set(0.5, 0.5);
    return text;
  }

  private createBackground(): Graphics {
    const rect = new Graphics().rect(-102, -18, 204, 36).fill(0x000000);
    rect.alpha = 0.5;
    return rect;
  }

  private setupButtons(): void {
    const addButton = this.createButton("buttonAdd", 85);
    addButton.on("pointerdown", () => this.startUpdating(1));
    addButton.on("pointerup", () => this.stopUpdating());
    addButton.on("pointerupoutside", () => this.stopUpdating());

    const subButton = this.createButton("buttonSub", -85);
    subButton.on("pointerdown", () => this.startUpdating(-1));
    subButton.on("pointerup", () => this.stopUpdating());
    subButton.on("pointerupoutside", () => this.stopUpdating());
  }

  private createButton(buttonName: string, xPos: number): Button {
    const button = new Button(buttonName);
    button.position.x = xPos;
    button.scale.set(0.72);
    button.setOriginalScale(0.72);
    this.addChild(button);
    return button;
  }

  private updateValue(newIndex: number): void {
    if (newIndex < 0 || newIndex >= this.valueList.length) return;
    this._activeBetIndex = newIndex;
    this._value = this.valueList[this._activeBetIndex];
    this.text.text = this.valueList[this._activeBetIndex].toString();
    if (this.onChange) {
      this.onChange(this.valueList[this._activeBetIndex]);
    }
  }

  private startUpdating(step: number): void {
    this.updateValue(this._activeBetIndex + step);
    this.updateTimeout = setTimeout(() => {
      this.updateInterval = setInterval(() => {
        this.updateValue(this._activeBetIndex + step);
      }, 1000 / 20); // 20 steps per second
    }, 500); // 500ms delay before starting continuous update
  }

  private stopUpdating(): void {
    if (this.updateTimeout !== null) {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = null;
    }
    if (this.updateInterval !== null) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
  }
}
