// CurrencyConfig.ts

import Decimal from "decimal.js";
import { GameId } from "../../enums/GameId";

export interface CurrencyData {
  currencySymbol: string;
  bets: Decimal[];
  buyBonusPrice: Decimal;
}

export class CurrencyConfig {
  private static readonly defaultConfigs: { [key: string]: CurrencyData } = {
    THB: {
      currencySymbol: "฿",
      bets: [0.5, 1, 2, 3, 5, 10, 20, 30, 40, 50, 80, 100, 200, 500, 1000].map(
        (bet) => new Decimal(bet)
      ),
      buyBonusPrice: new Decimal(40.5),
    },
    USD: {
      currencySymbol: "$",
      bets: [
        0.03, 0.06, 0.15, 0.3, 0.6, 1.5, 3, 6, 9, 15, 22, 30, 37, 45, 60,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(1.15),
    },
    CNY: {
      currencySymbol: "¥",
      bets: [
        0.2, 0.4, 1, 2, 4, 10, 20, 40, 60, 100, 150, 200, 250, 300, 400,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(8.3),
    },
    IDR: {
      currencySymbol: "Rp",
      bets: [
        500, 1000, 2500, 5000, 10000, 25000, 50000, 100000, 150000, 250000,
        375000, 500000, 625000, 750000, 1000000,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(17800),
    },
    VND: {
      currencySymbol: "₫",
      bets: [
        750, 1500, 3750, 7500, 15000, 37500, 75000, 150000, 225000, 375000,
        562500, 750000, 937500, 1125000, 1500000,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(28000),
    },
    LAK: {
      currencySymbol: "₭",
      bets: [
        300, 600, 1500, 3000, 6000, 15000, 30000, 60000, 90000, 150000, 225000,
        300000, 375000, 450000, 600000,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(22000),
    },
    MYR: {
      currencySymbol: "RM",
      bets: [
        0.15, 0.3, 0.75, 1.5, 3, 7.5, 15, 30, 45, 75, 112, 150, 187, 225, 300,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(5.4),
    },
    MMK: {
      currencySymbol: "K",
      bets: [
        50, 100, 250, 500, 1000, 2500, 5000, 10000, 15000, 25000, 37500, 50000,
        62500, 75000, 100000,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(2400),
    },
    KHR: {
      currencySymbol: "៛",
      bets: [
        1000, 2000, 5000, 10000, 20000, 50000, 100000, 200000, 300000, 500000,
        750000, 1000000, 1250000, 1500000, 2000000,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(4700),
    },
    KRW: {
      currencySymbol: "₩",
      bets: [
        40, 80, 200, 400, 800, 2000, 4000, 8000, 12000, 20000, 30000, 40000,
        50000, 60000, 80000,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(1550),
    },
    PHP: {
      currencySymbol: "₱",
      bets: [
        1.5, 3, 7.5, 15, 30, 75, 150, 300, 450, 750, 1125, 1500, 1875, 2250,
        3000,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(64),
    },
    EUR: {
      currencySymbol: "€",
      bets: [
        0.025, 0.05, 0.125, 0.25, 0.5, 1.25, 2.5, 5, 7.5, 12.5, 18.75, 25,
        31.25, 37.5, 50,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(1.05),
    },
    TWD: {
      currencySymbol: "NT$",
      bets: [
        1, 2, 5, 10, 20, 50, 100, 200, 300, 500, 750, 1000, 1250, 1500, 2000,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(35),
    },
    INR: {
      currencySymbol: "₹",
      bets: [
        2, 4, 10, 20, 40, 100, 200, 400, 600, 1000, 1500, 2000, 2500, 3000,
        4000,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(95),
    },
    GBP: {
      currencySymbol: "£",
      bets: [0.02, 0.04, 0.1, 0.2, 0.4, 1, 2, 4, 6, 10, 15, 20, 25, 30, 40].map(
        (bet) => new Decimal(bet)
      ),
      buyBonusPrice: new Decimal(0.92),
    },
    JPY: {
      currencySymbol: "¥",
      bets: [
        3, 6, 15, 30, 60, 150, 300, 600, 900, 1500, 2250, 3000, 3750, 4500,
        6000,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(170),
    },
    AUD: {
      currencySymbol: "A$",
      bets: [
        0.04, 0.08, 0.2, 0.4, 0.8, 2, 4, 8, 12, 20, 30, 40, 50, 60, 80,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(1.75),
    },
    BRL: {
      currencySymbol: "R$",
      bets: [
        0.15, 0.3, 0.75, 1.5, 3, 7.5, 15, 30, 45, 75, 112.5, 150, 187.5, 225,
        300,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(5.8),
    },
    BDT: {
      currencySymbol: "৳",
      bets: [
        3, 6, 15, 30, 60, 150, 300, 600, 900, 1500, 2250, 3000, 3750, 4500,
        6000,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(125),
    },
    PKR: {
      currencySymbol: "₨",
      bets: [
        5, 10, 25, 50, 100, 250, 500, 1000, 1500, 2500, 3750, 5000, 6250, 7500,
        10000,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(330),
    },
    MXN: {
      currencySymbol: "MX$",
      bets: [
        0.5, 1, 2.5, 5, 10, 25, 50, 100, 150, 250, 375, 500, 625, 750, 1000,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(20),
    },
    NGN: {
      currencySymbol: "₦",
      bets: [
        10, 20, 50, 100, 200, 500, 1000, 2000, 3000, 5000, 7500, 10000, 12500,
        15000, 20000,
      ].map((bet) => new Decimal(bet)),
      buyBonusPrice: new Decimal(530),
    },
  };

  private static readonly gameConfigs: {
    [key in GameId]?: { [currency: string]: CurrencyData };
  } = {
    [GameId.RomaX]: {
      THB: {
        currencySymbol: "฿",
        bets: [
          1.2, 3, 6, 9, 15, 30, 45, 60, 90, 150, 210, 300, 600, 900, 1200,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(43.8),
      },
      USD: {
        currencySymbol: "$",
        bets: [
          0.035, 0.09, 0.18, 0.27, 0.45, 0.9, 1.35, 1.8, 2.7, 4.5, 6.3, 9, 18,
          27, 36,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(1.25),
      },
      CNY: {
        currencySymbol: "¥",
        bets: [
          0.24, 0.6, 1.2, 1.8, 3, 6, 9, 12, 18, 30, 42, 60, 120, 180, 240,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(9),
      },
      IDR: {
        currencySymbol: "Rp",
        bets: [
          600, 1500, 3000, 4500, 7500, 15000, 22500, 30000, 45000, 75000,
          105000, 150000, 300000, 450000, 600000,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(19300),
      },
      VND: {
        currencySymbol: "₫",
        bets: [
          900, 2250, 4500, 6750, 11250, 22500, 33750, 45000, 67500, 112500,
          157500, 225000, 450000, 675000, 900000,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(30400),
      },
      LAK: {
        currencySymbol: "₭",
        bets: [
          360, 900, 1800, 2700, 4500, 9000, 13500, 18000, 27000, 45000, 63000,
          90000, 180000, 270000, 360000,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(23900),
      },
      MYR: {
        currencySymbol: "RM",
        bets: [
          0.18, 0.45, 0.9, 1.35, 2.25, 4.5, 6.75, 9, 13.5, 22.5, 31.5, 45, 90,
          135, 180,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(5.9),
      },
      MMK: {
        currencySymbol: "K",
        bets: [
          60, 150, 300, 450, 750, 1500, 2250, 3000, 4500, 7500, 10500, 15000,
          30000, 45000, 60000,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(2610),
      },
      KHR: {
        currencySymbol: "៛",
        bets: [
          1200, 3000, 6000, 9000, 15000, 30000, 45000, 60000, 90000, 150000,
          210000, 300000, 600000, 900000, 1200000,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(51100),
      },
      KRW: {
        currencySymbol: "₩",
        bets: [
          48, 120, 240, 360, 600, 1200, 1800, 2400, 3600, 6000, 8400, 12000,
          24000, 36000, 48000,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(1680),
      },
      PHP: {
        currencySymbol: "₱",
        bets: [
          1.8, 4.5, 9, 13.5, 22.5, 45, 67.5, 90, 135, 225, 315, 450, 900, 1350,
          1800,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(69.5),
      },
      EUR: {
        currencySymbol: "€",
        bets: [
          0.03, 0.075, 0.15, 0.225, 0.375, 0.75, 1.125, 1.5, 2.25, 3.75, 5.25,
          7.5, 15, 22.5, 30,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(1.14),
      },
      TWD: {
        currencySymbol: "NT$",
        bets: [
          1.2, 3, 6, 9, 15, 30, 45, 60, 90, 150, 210, 300, 600, 900, 1200,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(38),
      },
      INR: {
        currencySymbol: "₹",
        bets: [
          2.4, 6, 12, 18, 30, 60, 90, 120, 180, 300, 420, 600, 1200, 1800, 2400,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(103),
      },
      GBP: {
        currencySymbol: "£",
        bets: [
          0.024, 0.06, 0.12, 0.18, 0.3, 0.6, 0.9, 1.2, 1.8, 3, 4.2, 6, 12, 18,
          24,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(1),
      },
      JPY: {
        currencySymbol: "¥",
        bets: [
          3.6, 9, 18, 27, 45, 90, 135, 180, 270, 450, 630, 900, 1800, 2700,
          3600,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(185),
      },
      AUD: {
        currencySymbol: "A$",
        bets: [
          0.048, 0.12, 0.24, 0.36, 0.6, 1.2, 1.8, 2.4, 3.6, 6, 8.4, 12, 24, 36,
          48,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(1.9),
      },
      BRL: {
        currencySymbol: "R$",
        bets: [
          0.18, 0.45, 0.9, 1.35, 2.25, 4.5, 6.75, 9, 13.5, 22.5, 31.5, 45, 90,
          135, 180,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(6.3),
      },
      BDT: {
        currencySymbol: "৳",
        bets: [
          3.6, 9, 18, 27, 45, 90, 135, 180, 270, 450, 630, 900, 1800, 2700,
          3600,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(136),
      },
      PKR: {
        currencySymbol: "₨",
        bets: [
          6, 15, 30, 45, 75, 150, 225, 300, 450, 750, 1050, 1500, 3000, 4500,
          6000,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(358),
      },
      MXN: {
        currencySymbol: "MX$",
        bets: [
          0.6, 1.5, 3, 4.5, 7.5, 15, 22.5, 30, 45, 75, 105, 150, 300, 450, 600,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(21.7),
      },
      NGN: {
        currencySymbol: "₦",
        bets: [
          12, 30, 60, 90, 150, 300, 450, 600, 900, 1500, 2100, 3000, 6000, 9000,
          12000,
        ].map((bet) => new Decimal(bet)),
        buyBonusPrice: new Decimal(575),
      },
    },
  };

  public static getConfig(
    currency: string,
    gameId?: GameId
  ): CurrencyData | undefined {
    const upperCurrency = currency.toUpperCase();

    if (gameId) {
      const gameConfig = this.gameConfigs[gameId];
      if (gameConfig) {
        return gameConfig[upperCurrency];
      }
    }

    return this.defaultConfigs[upperCurrency];
  }

  public static isCurrencySupported(currency: string): boolean {
    return !!this.defaultConfigs[currency.toUpperCase()];
  }

  public static getSupportedCurrencies(): string[] {
    return Object.keys(this.defaultConfigs);
  }
}
