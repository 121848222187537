import { BitmapText, TextOptions } from "pixi.js";
import { MathUtils } from "./MathUtils";

export class BitmapTextExtended extends BitmapText {
  public forceWidth: number;

  /**
   * Creates an instance of BitmapTextExtended.
   * @param {TextOptions} options
   * @param {number} [forceWidth=0]
   */
  constructor(options: TextOptions, forceWidth: number = 0) {
    super(options);
    this.forceWidth = forceWidth;
    this.setText(this.text);
  }

  forceToWidth(): void {
    if (this.forceWidth) {
      const originalWidth = this.getLocalBounds().width;
      const scale = this.forceWidth / originalWidth;
      // Prevents crazy values like 0.9999999999999999
      const roundedToTwoDecimals = MathUtils.roundToTwoDecimals(scale);
      // We want to clamp the scale to 0-1
      const clampedScale = MathUtils.clamp(roundedToTwoDecimals, 0, 1);

      this.scale.set(clampedScale);
    }
  }

  /**
   * Sets the text of the BitmapText and forces it to the width specified in the constructor by
   * the forceWidth parameter.
   * @param value
   */
  setText(value: string) {
    this.text = value;
    this.forceToWidth();
  }
}
