import { GameView } from "../../GameView";
import { Sprite, Container } from "pixi.js";

export class GameLogo extends Container {
  private static _instance: GameLogo;
  private panelSprite!: Sprite;

  private constructor() {
    super();
    this.initializePanel();
  }

  public static get instance(): GameLogo {
    if (!this._instance) {
      this._instance = new GameLogo();
    }
    return this._instance;
  }

  private initializePanel(): void {
    this.panelSprite = Sprite.from("gamelogo");
    this.panelSprite.anchor.set(0.5);
    this.panelSprite.scale.set(0.6);
    this.position.set(GameView.instance.width / 2, 73);
    this.zIndex = 40;
    this.addChild(this.panelSprite);
  }
}
