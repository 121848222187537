import { BackgroundMusicManager } from "../sounds/BackgroundMusicManager";
import { SoundEffectManager } from "../sounds/SoundEffectManager";
import { EventEmitter } from "eventemitter3";

export class SoundsPreloader extends EventEmitter {
  private static _instance: SoundsPreloader;

  public static get instance(): SoundsPreloader {
    if (!SoundsPreloader._instance) {
      SoundsPreloader._instance = new SoundsPreloader();
    }
    return SoundsPreloader._instance;
  }

  public _loaded: boolean = false;
  public _interaction: boolean = false;
  public _ready: boolean = false;

  public set loaded(value: boolean) {
    this._loaded = value;

    if (this._interaction && this._ready) {
      this.playOnReady();
    }
  }

  public set interaction(value: boolean) {
    this._interaction = value;

    if (this._loaded && this._ready) {
      this.playOnReady();
    }
  }

  public set ready(value: boolean) {
    this._ready = value;

    if (this._loaded && this._interaction) {
      this.playOnReady();
    }
  }

  protected constructor() {
    super();
    this.handleInteraction = this.handleInteraction.bind(this);
  }

  public setInteractionEvent(): void {
    document.body.addEventListener("click", this.handleInteraction);
  }

  public async load(): Promise<void> {
    const tasks = [
      this.loadTask(
        SoundEffectManager.instance.preLoadButtonClickSound(),
        "buttonClick"
      ),
      this.loadTask(
        BackgroundMusicManager.instance.loadRegularMusic(),
        "regularMusic"
      ),
      this.loadTask(
        BackgroundMusicManager.instance.loadFreeSpinMusic(),
        "freeSpinMusic"
      ),
      this.loadTask(SoundEffectManager.instance.preloadSounds(), "otherSounds"),
      this.loadTask(
        BackgroundMusicManager.instance.decodeRegularMusic(),
        "decodeRegular"
      ),
      this.loadTask(
        BackgroundMusicManager.instance.decodeFreeSpinMusic(),
        "decodeFreeSpinMusic"
      ),
    ];

    const totalTasks = tasks.length;
    let completedTasks = 0;

    await Promise.allSettled(
      tasks.map((task) =>
        task.then(() => {
          completedTasks++;
          const progress = completedTasks / totalTasks;
          this.emit("soundsProgress", progress);
        })
      )
    );

    this.loaded = true;
  }

  private async loadTask(task: Promise<any>, taskName: string): Promise<void> {
    try {
      await task;
    } catch (error) {
      console.error(`Error loading ${taskName}:`, error);
    }
  }

  private playOnReady(): void {
    BackgroundMusicManager.instance.playRegularBackgroundMusic();
  }

  // events

  private handleInteraction(): void {
    this.interaction = true;

    document.body.removeEventListener("click", this.handleInteraction);
  }
}
