import * as PIXI from "pixi.js";
import { GameView } from "../../GameView";
import { BasePopup } from "../../ui/components/BasePopup";
import { BitmapTextExtended } from "../../common/src/utils/BitmapTextExtended";

export class FreeSpinRetriggerPopup extends BasePopup {
  private static _instance: FreeSpinRetriggerPopup | null = null;

  private constructor() {
    super();
    this.initialize();
  }

  public static get instance(): FreeSpinRetriggerPopup {
    if (!this._instance) {
      this._instance = new FreeSpinRetriggerPopup();
    }
    return this._instance;
  }

  private initialize(): void {
    const centerX = GameView.instance.width / 2;
    const centerY = GameView.instance.height / 2;

    // Position the popup container in the center
    this.x = centerX;
    this.y = centerY;

    // Set the pivot to center the container
    this.pivot.set(this.width / 2, this.height / 2);

    // Position elements relative to the container's center
    this.createChildSprite("retrigger_text_2", 0, -130, 1, 1, 0, true);
    this.createBitmapText("5", 0, 0);
    this.createChildSprite("retrigger_text_1", 0, 130, 1, 1, 0, true);

    // Position the coin animation
    this.positionCoinAnimation(0, 0);

    this.hide();
  }

  private positionCoinAnimation(x: number, y: number): void {
    // Assuming there's a method to get or create the coin animation
    const coinAnimation = this.getCoinAnimation();
    if (coinAnimation) {
      coinAnimation.x = x;
      coinAnimation.y = y;
      coinAnimation.anchor.set(0.5, 0.5);
    }
  }

  // Add this method if it doesn't exist
  private getCoinAnimation(): PIXI.Sprite | null {
    // Return the coin animation sprite if it exists
    // You might need to adjust this based on how the coin animation is implemented
    return this.getChildByName("coin_animation") as PIXI.Sprite | null;
  }

  private createBitmapText(
    text: string,
    x: number,
    y: number
  ): BitmapTextExtended {
    const bitmapText = new BitmapTextExtended({
      text,
      style: {
        fontFamily: "fsc_bm",
        fontSize: 60,
      },
    });
    bitmapText.anchor.set(0.5, 0.5);
    bitmapText.position.set(x, y);
    bitmapText.setText(text);
    this.addChild(bitmapText);
    return bitmapText;
  }
}
