import { Spin } from "./Spin";

export class Round {
  totalWin: number;
  spins: Spin[];

  constructor(data: any) {
    this.totalWin = data.totalWin;
    this.spins = data.spins.map((spinData: any) => new Spin(spinData));
  }
}
