import { Container, Sprite, Texture } from "pixi.js";

export class VolatilityBar extends Container {
  private emptyTexture: Texture;
  private fullTexture: Texture;
  private readonly maxVolatility = 5;

  constructor(private volatility: number, private emptyPepperAlpha?: number) {
    super();
    this.emptyTexture = Texture.from("volatility_empty");
    this.fullTexture = Texture.from("volatility_full");
    this.createVolatilityBar();
  }

  private createVolatilityBar(): void {
    for (let i = 0; i < this.maxVolatility; i++) {
      const isFull = i < this.volatility;
      const texture = isFull ? this.fullTexture : this.emptyTexture;
      const sprite = new Sprite(texture);
      isFull
        ? (sprite.alpha = 1)
        : (sprite.alpha = this.emptyPepperAlpha ? this.emptyPepperAlpha : 0.5);
      sprite.anchor.set(0.5);
      sprite.x = i * (sprite.width + (!isFull ? 2 : 0));
      sprite.y = -3;
      this.addChild(sprite);
    }
  }
}
