import { Application, ApplicationOptions, Container } from "pixi.js";
import { Config } from "./config/Config";
import { LayerManager } from "./utils/layers/LayerManager";
import { LayersConfig } from "./utils/layers/LayersConfig";
import { initDevtools } from "@pixi/devtools";

export class GameView {
  public app!: Application;
  public stage!: Container;

  // TODO This is definetly not the way to handle tick rate
  public tickRate = 1;
  private static _instance: GameView;
  public static APP_OPTIONS = {
    background: "#000000",
    resizeTo: window,
    antialias: true,
    eventMode: "dynamic" as const,
    eventFeatures: {
      move: true,
      globalMove: false,
      click: true,
    },
  } satisfies Partial<ApplicationOptions>;

  private constructor() {}

  //   public fullScreenHandler(): void {
  //     //requestFullScreen
  //     if (useDevice().isMobile) {
  //       if (document.fullscreenElement) {
  //         document.exitFullscreen();
  //       } else {
  //         document.documentElement.requestFullscreen();
  //       }
  //     }
  //   }

  public static get instance(): GameView {
    if (!GameView._instance) {
      GameView._instance = new GameView();
    }
    return GameView._instance;
  }

  async setupPixiApp(): Promise<void> {
    this.app = new Application();
    await this.app.init(GameView.APP_OPTIONS);
    this.stage = this.app.stage;
    document.body.appendChild(this.app.canvas);
    initDevtools(GameView.instance.app);
    LayerManager.instance.createTreeStructure(this.stage);
  }

  public get width(): number {
    return this.calculateWidth();
  }

  public get height(): number {
    return this.calculateHeight();
  }

  // Calculate the width based on reel configuration
  private calculateWidth(): number {
    return (
      Config.reelSize.offsetX * 2 +
      Config.reelAmount * Config.reelSize.symbolWidth +
      (Config.reelAmount - 1) * Config.reelSize.gapX
    );
  }

  // Calculate the height based on reel configuration
  private calculateHeight(): number {
    return (
      Config.reelSize.offsetY * 2 +
      Config.symbolsPerReel * Config.reelSize.symbolHeight +
      (Config.symbolsPerReel - 1) * Config.reelSize.gapY
    );
  }

  public moveToCenter(): void {
    const width = window.innerWidth;
    const height = window.innerHeight;

    const ratio = Math.min(
      width / this.calculateWidth(),
      height / this.calculateHeight()
    );

    const x = (width - this.calculateWidth() * ratio) / 2;
    const y = (height - this.calculateHeight() * ratio) / 2;

    // Apply scaling and position
    LayerManager.instance
      .getLayer(LayersConfig.BASE_LAYERS.GAME)
      .scale.set(ratio, ratio);
    LayerManager.instance
      .getLayer(LayersConfig.BASE_LAYERS.GAME)
      .position.set(x, y);
  }
}
