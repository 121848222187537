import { ILayer } from "./LayerManager";

export class LayersConfig {
  static BASE_LAYERS = {
    GAME: "GAME",
    HEADER: "HEADER",
    SCREENS: "SCREENS",
    CONTROL_PANEL: "CONTROL_PANEL",
    BACKGROUND: "BACKGROUND",
    BLACK_BARS: "BLACK_BARS",
    OVERLAY: "OVERLAY",
    FOOTER: "FOOTER",
    REEL_SET: "REEL_SET",
  };

  static HEADER_LAYERS = {
    SPOT_LIGHTS: "SPOT_LIGHTS",
    MULTIPLIERS_PANEL: "MULTIPLIERS_PANEL",
    GAME_LOGO: "GAME_LOGO",
    MESSAGE_LOOP: "MESSAGE_LOOP",

    BUY_BONUS_BUTTON: "BUY_BONUS_BUTTON",
  };

  static REELS_SET_LAYERS = {
    INTRO_LIGHTS: "INTRO_LIGHTS",
    REELS: "REELS",
    COMBO: "COMBO",
    SUSPENCE: "SUSPENCE",
  };

  static layers: ILayer[] = [
    {
      name: LayersConfig.BASE_LAYERS.BACKGROUND,
    },
    {
      name: LayersConfig.BASE_LAYERS.HEADER,
      children: [
        { name: LayersConfig.HEADER_LAYERS.SPOT_LIGHTS },
        { name: LayersConfig.HEADER_LAYERS.GAME_LOGO },
        { name: LayersConfig.HEADER_LAYERS.MULTIPLIERS_PANEL },
        { name: LayersConfig.REELS_SET_LAYERS.COMBO },
        { name: LayersConfig.HEADER_LAYERS.MESSAGE_LOOP },
        { name: LayersConfig.HEADER_LAYERS.BUY_BONUS_BUTTON },
      ],
    },
    {
      name: LayersConfig.BASE_LAYERS.REEL_SET,
      children: [
        { name: LayersConfig.REELS_SET_LAYERS.INTRO_LIGHTS },
        { name: LayersConfig.REELS_SET_LAYERS.REELS },
        { name: LayersConfig.REELS_SET_LAYERS.SUSPENCE },
        { name: LayersConfig.REELS_SET_LAYERS.COMBO },
      ],
    },
    { name: LayersConfig.BASE_LAYERS.CONTROL_PANEL },
    { name: LayersConfig.BASE_LAYERS.FOOTER },
    {
      name: LayersConfig.BASE_LAYERS.OVERLAY,
    },
    { name: LayersConfig.BASE_LAYERS.SCREENS },
    { name: LayersConfig.BASE_LAYERS.BLACK_BARS },
  ];
}
