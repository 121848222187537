import { Sprite, Container } from "pixi.js";
import { GameView } from "../../GameView";

export class NetworkIndicator extends Container {
  private static _instance: NetworkIndicator;
  private networkSprite!: Sprite;

  public static get instance(): NetworkIndicator {
    if (!NetworkIndicator._instance) {
      NetworkIndicator._instance = new NetworkIndicator();
    }
    return NetworkIndicator._instance;
  }

  constructor() {
    super();
    this.setup();
  }

  private setup() {
    this.networkSprite = Sprite.from("icon_network_4");
    this.networkSprite.anchor.set(0.5, 0.5);
    this.networkSprite.scale.set(0.77, 0.77);

    this.addChild(this.networkSprite);
    this.position.set(
      GameView.instance.width - 30,
      GameView.instance.height - 34
    );
  }
}
