import { BaseSymbol } from "./BaseSymbol";
import { Sprite, Container, Texture } from "pixi.js";
import { gsap } from "gsap";
import { SymbolType } from "./SymbolType";
import { Delay } from "../../../../common/src/utils/Delay";
import { CoinSpinAnimation } from "../../../../ui/animations/CoinSpinAnimation";
import { Spine } from "@pixi/spine-pixi";
import { AssetManager } from "../../../../common/src/utils/assets/AssetManager";

export class ScatterSymbol extends BaseSymbol {
  private scatterTextSprite!: Sprite;
  private scatterSprite!: Sprite;
  private scatter_anim!: Spine;
  constructor(symbolIndex: number, reelIndex: number) {
    super(symbolIndex, reelIndex, SymbolType.Scatter);
    this.createSymbol();
  }

  protected createSymbol(): void {
    this.scatter_anim = Spine.from({
      skeleton: "scatter_anim.json",
      atlas: "scatter_anim.atlas",
      scale: 1,
    });
    this.scatter_anim.position.set(0, 85);
    this.scatter_anim.visible = false;
    this.container.addChild(this.scatter_anim);

    this.scatterSprite = Sprite.from("scatter_0");
    this.scatterSprite.anchor = 0.5;
    this.scatterSprite.y = -5;
    this.scatterSprite.zIndex = 10;
    this.container.addChild(this.scatterSprite);

    this.scatterTextSprite = Sprite.from("scatter_text");
    this.scatterTextSprite.anchor = 0.5;
    this.scatterTextSprite.x = 0;
    this.scatterTextSprite.y = 45;
    this.scatterTextSprite.zIndex = 20;
    this.container.addChild(this.scatterTextSprite);
    this.container.scale.set(0.84, 0.84);
  }

  private hideSprites(): void {
    this.scatterSprite.visible = false;
    this.scatterTextSprite.visible = false;
  }

  private clearListeners(): void {
    if (this.scatter_anim && this.scatter_anim.state) {
      this.scatter_anim.state.clearListeners();
    }
  }

  public async performScatterSuspenseAnimation(): Promise<void> {
    this.hideSprites();
    this.clearListeners();
    if (this.scatter_anim && this.scatter_anim.state) {
      this.scatter_anim.state.timeScale = 1;
      this.scatter_anim.state.setAnimation(0, "suspense_anim", true);
      this.scatter_anim.visible = true;
    }
  }

  public async performWinFreeSpinAnimation(): Promise<void> {
    this.hideSprites();
    this.clearListeners();
    if (this.scatter_anim && this.scatter_anim.state) {
      this.scatter_anim.state.timeScale = 1;

      return new Promise<void>((resolve) => {
        this.scatter_anim.state.addListener({
          complete: () => {
            this.clearListeners();
            this.performScatterIdleAnimation();
            resolve();
          },
        });

        this.scatter_anim.state.setAnimation(0, "win_anim", false);
        this.scatter_anim.visible = true;
      });
    }
    return Promise.resolve();
  }

  public async performSymbolLandAnimation(): Promise<void> {
    this.performScatterIdleAnimation();
  }

  // Idle animation for Scatter Symbol
  public async performScatterIdleAnimation(): Promise<void> {
    this.hideSprites();
    this.clearListeners();
    if (this.scatter_anim && this.scatter_anim.state) {
      this.scatter_anim.state.timeScale = 1;
      this.scatter_anim.state.setAnimation(0, "idle_anim", true);
      this.scatter_anim.visible = true;
    }
  }

  public hide(): void {
    this.container.visible = false;
  }

  public show(): void {
    this.container.visible = true;
  }

  public override destroy(): void {
    // Destroy specific resources for ScatterSymbol
    this.destroySprite(this.scatterTextSprite);
    this.destroySprite(this.scatterSprite);

    if (this.scatter_anim) {
      this.clearListeners();
      this.scatter_anim.destroy();
    }

    // Call the parent class destroy method
    super.destroy();
  }
}
