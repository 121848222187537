import { GameView } from "../../GameView";
import { Container } from "pixi.js";
import gsap from "gsap";
import { BitmapTextExtended } from "../../common/src/utils/BitmapTextExtended";
import { Config } from "../../config/Config";

export class WinAmountPopup extends Container {
  private static _instance: WinAmountPopup;
  private winAmountPopupText: BitmapTextExtended;

  private constructor() {
    super();
    this.position.set(
      GameView.instance.width / 2,
      GameView.instance.height / 2
    );
    this.winAmountPopupText = new BitmapTextExtended(
      {
        text: "",
        style: {
          fontFamily: "big_win_bm",
          fontSize: 80,
        },
      },
      Config.bitmapMaxWidth
    );
    this.winAmountPopupText.anchor.set(0.5);
    this.addChild(this.winAmountPopupText);
    this.visible = false;
  }

  public static get instance(): WinAmountPopup {
    if (!WinAmountPopup._instance) {
      WinAmountPopup._instance = new WinAmountPopup();
    }
    return WinAmountPopup._instance;
  }

  public async displayWin(amount: number): Promise<void> {
    this.winAmountPopupText.setText(amount.toString());
    await this.play();
  }

  public async play(): Promise<void> {
    this.visible = true;
    this.alpha = 0;
    this.scale.set(0.5);

    await gsap
      .timeline()
      .to(this, {
        pixi: { scale: 1, alpha: 1 },
        duration: 0.3,
        ease: "power1.out",
      })
      .to(this, {
        pixi: { scale: 0.7, alpha: 0 },
        duration: 0.3,
        ease: "power1.out",
        delay: 0.5,
      });
  }
}
