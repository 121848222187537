import { BasePanel } from "../../ui/components/BasePanel";
import { Sprite, Container } from "pixi.js";
import { GameView } from "../../GameView";
import { Delay } from "../../common/src/utils/Delay";
import gsap from "gsap";
import { BitmapTextExtended } from "../../common/src/utils/BitmapTextExtended";

export class ComboPanel extends BasePanel {
  private static _instance: ComboPanel;
  private static readonly WORD = "combo";
  private bgSprite!: Sprite;
  private textContainer!: Container;
  private amountBitmapText!: BitmapTextExtended;
  private letters: Sprite[] = [];

  private constructor() {
    super();
    this.initializeComponents();
    this.visible = false;
  }

  public static get instance(): ComboPanel {
    if (!this._instance) {
      this._instance = new ComboPanel();
    }
    return this._instance;
  }

  private setupContainer(): void {
    this.scale.set(0.4);
    this.position.set(GameView.instance.width / 2 + 5, 217);
  }

  public updateValue(value: number): void {
    this.visible = value !== 0;
    this.amountBitmapText.setText(String(value));
    this.playAnimation();
  }

  private initializeComponents(): void {
    this.setupContainer();
    this.createLetters();
    this.createBackgroundSprite();
    this.createAmountBitmapText();
  }

  private createLetters() {
    this.textContainer = new Container();

    ComboPanel.WORD.split("").forEach((char, index) => {
      const letterSprite = Sprite.from(`combo_${char}`);
      letterSprite.anchor.set(0.5);
      letterSprite.scale.set(2);
      letterSprite.x = -200 + index * 65 + (index === 3 ? 8 : 0);
      this.textContainer.addChild(letterSprite);
      this.letters.push(letterSprite);
    });

    this.addChild(this.textContainer);
  }

  private createBackgroundSprite() {
    this.bgSprite = Sprite.from("red2");
    this.bgSprite.anchor.set(0.5);
    this.bgSprite.zIndex = -1;
    this.bgSprite.alpha = 0.6;
    this.bgSprite.scale.set(5, 3.4);
    this.bgSprite.position.set(-20, 0);
    this.addChild(this.bgSprite);
  }

  private createAmountBitmapText() {
    this.amountBitmapText = new BitmapTextExtended({
      text: "3",
      style: {
        fontFamily: "combo_bm",
        fontSize: 80,
      },
    });
    this.amountBitmapText.anchor.set(0.5);
    this.amountBitmapText.position.set(150, -17);
    this.addChild(this.amountBitmapText);
  }

  private async playAnimation(): Promise<void> {
    for (let i = 0; i < this.letters.length; ++i) {
      const letter = this.letters[i];

      gsap
        .timeline()
        .to(letter, {
          y: -27,
          stagger: 0.1,
          yoyo: true,
          repeat: 1,
          duration: 0.1,
        })
        .to(letter, {
          y: 27,
          stagger: 0.1,
          yoyo: true,
          repeat: 1,
          duration: 0.1,
        });

      await Delay.delay(80);
    }
  }
}
