import { Application, Container } from "pixi.js";

import { BackgroundMusicManager } from "./sounds/BackgroundMusicManager";
import { SoundEffectManager } from "./sounds/SoundEffectManager";

import gsap from "gsap";
import PixiPlugin from "gsap/PixiPlugin";
import * as PIXI from "pixi.js";
import { CustomEase } from "gsap/all";

// Import your test objects here

import { GameView } from "./GameView";
import ValueListInputSandbox from "./sandbox/ValueListInputSandbox";
import ScatterSandbox from "./sandbox/ScatterSandbox";
import BuyBonusButtonSandbox from "./sandbox/BuyBonusButtonSandbox";
import { AssetManager } from "./common/src/utils/assets/AssetManager";
import GoldCardSandbox from "./sandbox/GoldCardSandbox";
import WildCardSandbox from "./sandbox/WildCardSandbox";
import SpineAnimationTester from "./sandbox/SpineAnimationTester";
import IntroLightsSandbox from "./sandbox/IntroLightsSandbox";
import BuyBonusModalSandbox from "./sandbox/BuyBonusModalSandbox";
declare global {
  interface Window {
    __PIXI_APP__: Application | undefined;
  }
}

export default class SandboxContainer {
  private app!: Application;
  private container!: Container;
  private assetManager!: AssetManager;

  constructor() {
    this.init();
  }

  private async loadAssets(): Promise<void> {
    await AssetManager.instance.loadManifest();
    await AssetManager.instance.loadJiliScreenAssets();
    AssetManager.instance.loadSplashScreenAssets();
    await AssetManager.instance.loadAssets();
    await BackgroundMusicManager.instance.loadRegularMusic();
    await SoundEffectManager.instance.preloadSounds();
  }

  private addTestObject(): void {
    // const sandbox = new BuyBonusModalSandbox();
    // const sandbox = new ValueListInputSandbox();
    // const sandbox = new ScatterSandbox();
    // const sandbox = new GoldCardSandbox();
    // const sandbox = new BuyBonusButtonSandbox();
    // const sandbox = new WildCardSandbox();
    // const sandbox = new IntroLightsSandbox();
    // const sandbox = new SpineAnimationTester();
    const sandbox = new BuyBonusModalSandbox();
    GameView.instance.stage.addChild(sandbox);
  }

  private async init(): Promise<void> {
    await BackgroundMusicManager.instance.loadRegularMusic();
    PixiPlugin.registerPIXI(PIXI);
    gsap.registerPlugin(CustomEase);
    gsap.registerPlugin(PixiPlugin);
    await GameView.instance.setupPixiApp();
    window.__PIXI_APP__ = GameView.instance.app;
    GameView.instance.moveToCenter();
    this.setupResizeListener();
    await this.loadAssets();
    this.addTestObject();
  }

  private setupResizeListener(): void {
    window.addEventListener("resize", () => {
      setTimeout(() => GameView.instance.moveToCenter());
      GameView.instance.moveToCenter();
    });
  }
}
