import { SoundEffectManager } from "../../sounds/SoundEffectManager";
import { SOUNDS } from "../../sounds/Sounds";
import { gsap } from "gsap";

import { BetAmountSelector } from "../../game/control_panel/bet_amount_selector_button/BetAmountSelector";
import { Sprite, Texture } from "pixi.js";
import { GameView } from "../../GameView";
import { BasePopup } from "../../ui/components/BasePopup";
import { BitmapTextExtended } from "../../common/src/utils/BitmapTextExtended";
import { Config } from "../../config/Config";

export class BigWinPopup extends BasePopup {
  private static _instance: BigWinPopup;

  private state: number = 0;
  private winSizeLabel!: Sprite;
  private winWordLabel!: Sprite;
  private winText!: BitmapTextExtended;

  private constructor() {
    super();

    // put this at half the screen width
    this.x = GameView.instance.width / 2;
    this.y = 426;
    this.init();
    this.hide();
  }

  public static get instance(): BigWinPopup {
    if (!BigWinPopup._instance) {
      BigWinPopup._instance = new BigWinPopup();
    }
    return BigWinPopup._instance;
  }

  public async displayPopupIfOverThreshold(winAmount: number): Promise<void> {
    const winMultiplier =
      winAmount / Number(BetAmountSelector.instance.betAmount);
    if (winMultiplier > 30) {
      SoundEffectManager.instance.playSound(SOUNDS.SUPER_WIN, false);
      await this.displayBigWin(winAmount, 5000, "super");
    } else if (winMultiplier > 20) {
      SoundEffectManager.instance.playSound(SOUNDS.MEGA_WIN, false);
      await this.displayBigWin(winAmount, 1500, "mega");
    } else if (winMultiplier > 10) {
      SoundEffectManager.instance.playSound(SOUNDS.BIG_WIN, false);
      await this.displayBigWin(winAmount, 1500, "big");
    }
  }

  public show(): void {
    super.show();
    this.animateTextAppearance();
  }

  private init(): void {
    this.zIndex = 50;

    this.createShineLargeSprite();
    this.createLabels();
    this.createRedSprite();
    this.createWinText();
  }

  private createShineLargeSprite(): void {
    this.createChildSprite("shine_large", null, -100, 1.3, 1.3, 30, true);
  }

  private createLabels(): void {
    this.winSizeLabel = this.createChildSprite(
      "big",
      null,
      -160,
      0.9,
      0.9,
      100,
      true,
      this.text
    );
    this.winWordLabel = this.createChildSprite(
      "big",
      null,
      -60,
      0.9,
      0.9,
      100,
      true,
      this.text
    );
  }

  private createRedSprite(): void {
    this.createChildSprite("red", null, 60, 1.53, 1.53, 90, true);
  }

  private createWinText(): void {
    this.winText = new BitmapTextExtended(
      {
        text: "",
        style: {
          fontFamily: "big_win_bm",
          fontSize: 60,
        },
      },
      Config.bitmapMaxWidth
    );
    this.winText.anchor.set(0.5);
    this.winText.position.set(0, 60);
    this.winText.zIndex = 100;

    this.addChild(this.winText);
  }

  private animateTextAppearance(): void {
    this.text.scale.set(0.6);
    this.text.alpha = 0;
    gsap.to(this.text, {
      duration: 0.5,
      scale: 1,
      alpha: 1,
      ease: "elastic.out(1, 0.3)",
    });
  }

  private async displayBigWin(
    winAmount: number,
    duration: number,
    winSizeWord: string
  ): Promise<void> {
    this.show();
    this.winText.setText("0.00");
    this.setLabels(winSizeWord);
    await this.animateWinText(winAmount, duration);
    await new Promise((resolve) => setTimeout(resolve, 200));
    this.hide();
  }

  private async animateWinText(
    winAmount: number,
    duration: number
  ): Promise<void> {
    const winCounter = {
      value: 0,
    };

    await gsap.to(winCounter, {
      duration: duration / 1000,
      value: winAmount,
      onUpdate: () => {
        this.winText.setText(winCounter.value.toFixed(2));
      },
    });
  }

  private setLabels(winSizeWord: string): void {
    this.winSizeLabel.texture = Texture.from(`${winSizeWord}`);
    this.winSizeLabel.anchor.set(0.5);

    this.winWordLabel.texture = Texture.from(`${winSizeWord}_win`);
    this.winWordLabel.anchor.set(0.5);
  }
}
