import { Container, Sprite, Text, TextStyle, Texture } from "pixi.js";
import { GameView } from "../../GameView";
import { Button } from "../../ui/components/Button";

import { Translator } from "../../common/src/utils/translation/Translator";
import { FontManager } from "../../common/src/utils/FontManager";

interface Error {
  message: string;
  code: string;
}

export class ErrorMessageModal extends Container {
  private static _instance: ErrorMessageModal;
  private title!: Text;
  private messageText!: Text;
  private codeText!: Text;
  private confirmButton!: Button;
  private sprite!: Sprite;

  private constructor() {
    super();
    this.setup();
    this.setupTitle();
    this.setupMessage();
    this.setupCode();
    this.hideModal();
  }

  public static get instance(): ErrorMessageModal {
    if (!this._instance) {
      this._instance = new ErrorMessageModal();
    }
    return this._instance;
  }

  public showModal(message: string | Error): void {
    if (typeof message === "string") {
      this.messageText.text = message;
      this.codeText.alpha = 0;
    } else {
      this.messageText.text = message.message;
      this.codeText.text = message.code;
      this.codeText.alpha = 1;
    }
    GameView.instance.tickRate = 0;
    this.visible = true;
  }

  public hideModal(): void {
    GameView.instance.tickRate = 1;
    this.visible = false;
  }

  private setup(): void {
    this.sprite = Sprite.from("modal");
    this.sprite.anchor.set(0.5);
    this.position.set(
      GameView.instance.width / 2,
      GameView.instance.height / 2
    );
    this.scale.set(0.8);
    this.zIndex = 9500;

    this.addChild(this.sprite);

    this.confirmButton = new Button(
      "button_green",
      Translator.instance.getText("confirm")
    );
    this.addChild(this.confirmButton);
    this.confirmButton.position.y = 112;
    this.confirmButton.scale.set(0.8, 0.8);
    this.confirmButton.on("click", () => this.hideModal());
    this.confirmButton.on("tap", () => this.hideModal());
  }

  private setupTitle(): void {
    this.title = new Text({
      text: Translator.instance.getText("system"),
      style: {
        fontFamily: FontManager.instance.defaultFontFamily,
        fontSize: 30,
        fill: 0xffff00,
        stroke: { color: 0x000000, width: 1 },
      },
    });
    this.title.anchor.set(0.5, 0.5);
    this.title.position.y = -125;
    this.addChild(this.title);
  }

  private setupMessage(): void {
    this.messageText = new Text({
      text: Translator.instance.getText("error_message"),
      style: {
        fontFamily: FontManager.instance.defaultFontFamily,
        fontSize: 18,
        align: "center",
        fill: 0xffffff,
        stroke: { color: 0x000000, width: 1 },
      },
    });
    this.messageText.anchor.set(0.5, 0.5);
    this.messageText.position.y = 0;
    this.addChild(this.messageText);
  }

  private setupCode(): void {
    this.codeText = new Text({
      text: Translator.instance.getText("code"),
      style: {
        fontFamily: FontManager.instance.defaultFontFamily,
        fontSize: 18,
        align: "center",
        fill: 0xffffff,
        stroke: { color: 0x000000, width: 1 },
      },
    });
    this.codeText.anchor.set(0.5, 0.5);
    this.codeText.position.y = 70;
    this.codeText.position.x = 130;
    this.addChild(this.codeText);
  }
}
