import { Button } from "../../../../ui/components/Button";
import { InfoWindow } from "./InfoWindow";

export class InfoButton extends Button {
  private static _instance: InfoButton;
  protected originalScale = { x: 0.9, y: 0.9 };

  private constructor() {
    super(undefined);
    this.setup();
  }

  static get instance(): InfoButton {
    if (!InfoButton._instance) {
      InfoButton._instance = new InfoButton();
    }
    return InfoButton._instance;
  }
  private setup(): void {
    this.updateSprite("button_pt", true);
    this.position.set(36, 35 + 2 * 53);
    this.scale.set(this.originalScale.x, this.originalScale.y);
    this.on("click", () => InfoWindow.instance.openWindow());
    this.on("tap", () => InfoWindow.instance.openWindow());
  }
}
