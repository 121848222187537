import { Container, Sprite, Text } from "pixi.js";
import gsap from "gsap";
import { GameView } from "../../../GameView";
import { Translator } from "../../../common/src/utils/translation/Translator";
import { FontManager } from "../../../common/src/utils/FontManager";

export class SplashWildsPage extends Container {
  private reel!: Container;
  private wilds: Sprite[] = [];
  private text!: Text;

  constructor() {
    super();
    this.text = this.createText("wild_feature_text");
    this.addChild(this.text);
    this.createReel();
    this.createWilds();
    this.createArrows();
    this.startWildAnimation();
  }

  private createText(translationKey: string): Text {
    const text = new Text({
      text: Translator.instance.getText(translationKey),
      style: {
        fontFamily: FontManager.instance.defaultFontFamily,
        fontSize: 20,
        fill: 0xffffff,
        align: "center",
      },
    });
    text.resolution = 2;
    text.height = 40;
    text.width = 330;
    text.scale.set(1);
    text.anchor.set(0.5, 0.5);
    text.position.set(
      GameView.instance.width / 2,
      GameView.instance.height / 2 + 103
    );
    return text;
  }

  private createReel(): void {
    this.reel = new Container();
    const reelSprite = Sprite.from("reel1");
    reelSprite.anchor.set(0.5);
    this.reel.addChild(reelSprite);
    this.reel.position.set(
      GameView.instance.width / 2 + 5,
      GameView.instance.height / 2 - 45
    );
    this.reel.scale.set(0.77);
    this.addChild(this.reel);
  }

  private createWilds(): void {
    const data = [
      { texture: 1, x: 0, y: 105, rotation: 0, scale: 0.8 },
      { texture: 1, x: 85, y: -105, rotation: 0.2, scale: 0.8 },
      { texture: 1, x: 170, y: 105, rotation: 0, scale: 0.8 },
      { texture: 2, x: -100, y: -10, rotation: 0, scale: 0.8 },
    ];

    data.forEach((item) => {
      const wild = Sprite.from(`wild${item.texture}`);
      wild.anchor.set(0.5);
      wild.position.set(item.x, item.y);
      wild.rotation = item.rotation;
      wild.scale.set(item.scale);
      this.reel.addChild(wild);
      this.wilds.push(wild);
    });
  }

  private createArrows(): void {
    const data = [
      { texture: 1, x: -10, y: -105, rotation: 0 },
      { texture: 2, x: 65, y: 40, rotation: 0 },
      { texture: 3, x: -50, y: 80, rotation: 0 },
    ];

    data.forEach((item) => {
      const arrow = Sprite.from(`arrow${item.texture}`);
      arrow.anchor.set(0.5);
      arrow.position.set(item.x, item.y);
      arrow.rotation = item.rotation;
      arrow.scale.set(0.85);
      this.reel.addChild(arrow);
    });
  }

  public startWildAnimation(): void {
    const timeline = gsap.timeline({ repeat: -1 });
    // put all scales from the wilds in an array
    const scaleReferences = this.wilds.map((wild) => wild.scale);

    timeline.to(scaleReferences, {
      x: 1,
      y: 1,
      duration: 0.8,
      ease: "power1.inOut",
    });
    timeline.to(scaleReferences, {
      x: 0.8,
      y: 0.8,
      duration: 0.8,
      ease: "power1.inOut",
    });
    timeline.to(scaleReferences, {
      x: 1,
      y: 1,
      duration: 0.4,
      ease: "power1.inOut",
    });
    timeline.to(scaleReferences, {
      x: 0.8,
      y: 0.8,
      duration: 0.4,
      ease: "power1.inOut",
    });
  }
  public hide(): void {
    gsap.killTweensOf("*");
    this.visible = false;
    this.destroy({ children: true });
  }
}
