import { Container, Graphics, Sprite, Texture } from "pixi.js";
import { GameView } from "../../GameView";

import gsap from "gsap";

import { Delay } from "../../common/src/utils/Delay";
import { Translator } from "../../common/src/utils/translation/Translator";
import { shuffleArray } from "../../utils/helpers";

export class MessageLoop extends Container {
  private static _instance: MessageLoop;
  private hidden: boolean = false;
  private iteration: number = 0;
  private msgIndex: number = 0;
  private messageAmount: number = 3;
  private messageSprite!: Sprite;
  private texts: string[] = [];

  private constructor() {
    super();
    this.setupContainer();
    this.setupMask();
    this.setupTexts();
    this.setupMessage();
    this.startMessageLoop();
  }

  public static get instance(): MessageLoop {
    if (!MessageLoop._instance) {
      MessageLoop._instance = new MessageLoop();
    }
    return MessageLoop._instance;
  }

  public show(): void {
    this.hidden = false;
    this.visible = true;
  }

  public hide(): void {
    this.hidden = true;
    this.visible = false;
  }

  private setupContainer(): void {
    this.position.set(GameView.instance.width / 2 - 180, 142);
  }

  private setupMask(): void {
    const mask = new Graphics().rect(0, 0, 360, 50).fill(0xffffff);
    this.mask = mask;
    this.addChild(mask);
  }

  private setupTexts(): void {
    for (let i = 0; i < this.messageAmount; i++) {
      this.texts.push(`info_${i}_${Translator.instance.language}`)
    }

    this.texts = shuffleArray<string>(this.texts);
  }

  private setupMessage(): void {
    this.messageSprite = Sprite.from(`info_0_${Translator.instance.language}`);
    this.messageSprite.anchor.y = 0.5;
    this.messageSprite.scale.set(0.7);
    this.messageSprite.position.y = 25;
    this.addChild(this.messageSprite);
  }

  private async startMessageLoop(): Promise<void> {
    while (true) {
      if (this.hidden) {
        await Delay.delay(2000);
      } else {
        this.iteration += 1;

        this.setMessage(this.msgIndex);
        this.messageSprite.position.y = -25;
        this.messageSprite.position.x = 0;
        this.msgIndex = this.msgIndex === this.messageAmount - 1 ? 0 : this.msgIndex + 1;

        await Delay.delay(1500);
        const width = this.messageSprite.width;
        const xTime = (width * 5) / 1000;
        const yTime = 0.5;
        await gsap.to(this.messageSprite.position, {
          y: 25,
          duration: yTime,
          ease: "back.out(1.7)",
        });
        await gsap.to(this.messageSprite.position, {
          x: -width,
          duration: xTime,
          ease: "linear",
        });
        this.messageSprite.position.x = 0;
        await gsap.to(this.messageSprite.position, {
          x: -width,
          duration: xTime,
          ease: "linear",
          delay: 1.0,
        });
      }
      this.messageSprite.position.x = -5000;
      await Delay.delay(4000);
    }
  }

  private setMessage(index: number): void {
    const textureId = this.texts[index];
    this.messageSprite.texture = Texture.from(textureId);
    this.messageSprite.anchor.y = 0.5;
  }
}
