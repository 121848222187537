import { Text, Container } from "pixi.js";
import { GameView } from "../../GameView";
import { CurrencyManager } from "../../common/src/utils/currency/CurrencyManager";

import { Translator } from "../../common/src/utils/translation/Translator";
import { FontFamily, FontManager } from "../../common/src/utils/FontManager";

export class BalanceDisplay extends Container {
  private static _instance: BalanceDisplay;
  private balanceWordText!: Text;
  public balanceAmountText!: Text;
  public demoBalanceText!: Text;
  public balance: number = 0;
  private currencySymbolText!: Text;

  private constructor() {
    super();
    this.createBalanceView();
  }

  public static get instance(): BalanceDisplay {
    if (!this._instance) {
      this._instance = new BalanceDisplay();
    }
    return this._instance;
  }

  public getInitialBalanceFromQueryString(buildEnv: string): void {
    const urlParams = new URLSearchParams(window.location.search);
    const balanceParam = urlParams.get("b");
    if (balanceParam) {
      this.balance = parseFloat(balanceParam);

      if (buildEnv != "prod") {
        // Remove the balance parameter from the URL
        urlParams.delete("b");
        const newUrl = window.location.pathname + "?" + urlParams.toString();
        window.history.replaceState({}, "", newUrl);
      }
    } else if (buildEnv != "prod") {
      this.balance = 5000.35;
    }
    this.updateBalance(this.balance);
  }

  public startDemoMode() {
    this.balanceAmountText.visible = false;
    this.balanceWordText.visible = false;
    this.currencySymbolText.visible = false;
    this.demoBalanceText.visible = true;
  }

  public endDemoMode() {
    this.balanceAmountText.visible = true;
    this.balanceWordText.visible = true;
    this.currencySymbolText.visible = true;
    this.demoBalanceText.visible = false;
  }

  private createBalanceView(): void {
    this.zIndex = 100;

    this.balanceWordText = new Text({
      text: Translator.instance.getText("balance"),
      style: {
        fontFamily:
          Translator.instance.language === "en"
            ? "Times New Roman"
            : FontFamily.KUI_CHAI,
        fontSize: 18,
        fill: 0xffd545,
      },
    });
    this.balanceWordText.resolution = 2;
    this.addChild(this.balanceWordText);

    this.currencySymbolText = new Text({
      text: CurrencyManager.instance.getCurrencySymbol(),
      style: {
        fontFamily: FontManager.instance.defaultFontFamily,
        fontSize: 20,
        fill: 0xffffff,
        fontWeight: "bold",
      },
    });
    this.currencySymbolText.resolution = 1.3;
    this.addChild(this.currencySymbolText);

    this.balanceAmountText = new Text({
      text: CurrencyManager.instance.formatAmountDecimalWithCommas(
        this.balance
      ),
      style: {
        fontFamily: FontFamily.HELVETICA,
        fontSize: 18,
        fill: 0xffffff,
        fontWeight: "bold",
      },
    });
    this.balanceAmountText.resolution = 1.5;
    this.addChild(this.balanceAmountText);
    this.updateElementPositions();
    this.createDemoBalanceText();
    this.visible = false;
  }

  private updateElementPositions(): void {
    this.position.set(GameView.instance.width / 2, 816);

    // Set balanceAmountText's right edge at the center
    this.balanceAmountText.anchor.set(0, 0.2); // Anchor to left edge
    this.balanceAmountText.x = 0; // Left edge at center
    this.balanceAmountText.y = 2;
    this.balanceAmountText.scale.set(1, 1.05);

    // Position currencySymbolText to the left of balanceAmountText
    this.currencySymbolText.anchor.set(1, 0.2); // Anchor to right edge
    this.currencySymbolText.x = -3; // 10 pixels gap
    this.currencySymbolText.y = 1;

    // Position balanceWordText to the left of currencySymbolText
    this.balanceWordText.anchor.set(1, 0); // Anchor to right edge
    this.balanceWordText.x = -30; // 15 pixels gap
  }

  private createDemoBalanceText() {
    this.demoBalanceText = new Text({
      text: Translator.instance.getText("demo_mode"),
      style: {
        fontFamily: FontManager.instance.defaultFontFamily,
        fontSize: 16,
        fill: 0xffff00,
        fontWeight: "bold",
      },
    });
    this.demoBalanceText.resolution = 2;
    this.addChild(this.demoBalanceText);
    this.demoBalanceText.position.set(0, 0);
    this.demoBalanceText.anchor.x = 0.5;
    this.demoBalanceText.visible = false;
  }

  public updateBalance(newBalance: number): void {
    this.balance = newBalance;
    this.balanceAmountText.text =
      CurrencyManager.instance.formatAmountDecimalWithCommas(this.balance);
    this.updateElementPositions();
  }
}
