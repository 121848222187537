// pixi
import { Assets } from "pixi.js";

// pixi-spine
import { AtlasAttachmentLoader, SkeletonData, SkeletonJson, Spine, TrackEntry } from '@pixi/spine-pixi';
import { Skin } from '@pixi/spine-pixi';

export interface ISpineAnimation {
    name: string;
    duration: number;
}

interface ISkeletonObject {
    spineData: any;
    animations: {
        [key: string]: ISpineAnimation;
    };
    skins: {
        [key: string]: string;
    };
    skeleton: SkeletonData;
}

type TEvent = 'complete' | 'start' | 'interrupt' | 'repeat';

interface IEvents {
    complete: Array<() => void>;
    repeat: Array<() => void>;
    start: Array<() => void>;
    interrupt: Array<() => void>;
}

//@ts-ignore
export class TSpine extends Spine {
    private events: IEvents = {
        complete: [],
        repeat: [],
        start: [],
        interrupt: []
    };

    private playingTracks: number[] = [0];

    public playing: boolean = false;

    public animations: {[key: string]: ISpineAnimation};
    public skins: {[key: string]: string};

    static from(data: { skeleton: string; atlas: string; scale?: number; visible?: boolean; }): TSpine {
        const attachmentLoader = new AtlasAttachmentLoader(Assets.get(data.atlas));
        const skeletonJson = new SkeletonJson(attachmentLoader);

        if (typeof data.scale === 'number') {
            skeletonJson.scale = data.scale;
        }

        const skeletonObject = Assets.get(data.skeleton);
        const skeletonData = skeletonJson.readSkeletonData(skeletonObject);

        const tSpine = new TSpine({
            skeletonObject: skeletonObject,
            skeletonData: skeletonData
        });

        tSpine.visible = Boolean(data.visible);

        return tSpine;
    }

    protected constructor(data: { skeletonObject: ISkeletonObject; skeletonData: SkeletonData; }) {
        super(data.skeletonData);

        this.animations = data.skeletonObject.animations;
        this.skins = data.skeletonObject.skins;

        this.running = false;
        this.skeleton.setToSetupPose();
        this.skeleton.setSlotsToSetupPose();
    }

    // getters, setters

    public set running(value: boolean) {
        this.autoUpdate = value;
    }

    public get running(): boolean {
        return this.autoUpdate;
    }

    public set speed(value: number) {
        this.state.timeScale = value;
    }

    public get speed(): number {
        return this.state.timeScale;
    }

    // events

    private emitStarted(): void {
        for (const cb of this.events.start) {
            cb();
        }
    }

    private emitCompleted(): void {
        for (const cb of this.events.complete) {
            cb();
        }
    }

    private emitRepeated(): void {
        for (const cb of this.events.repeat) {
            cb();
        }
    }

    private emitInterrupted(): void {
        for (const cb of this.events.interrupt) {
            cb();
        }
    }

    public addEvent(type: TEvent, callback: () => void): void {
        if (this.events[type].includes(callback)) {
            return ;
        }

        this.events[type].push(callback);
    }

    public removeEvent(type: TEvent, callback: () => void): void {
        this.events[type] = this.events[type].filter(cb => cb !== callback);
    }

    public addOnce(type: TEvent, callback: () => void): void {
        if (this.events[type].includes(callback)) {
            return ;
        }

        const eventWrapper = () => {
            callback();
            this.events[type] = this.events[type].filter(cb => cb !== eventWrapper);
        }

        this.events[type].push(eventWrapper);
    }

    private setEvents(trackIndex: number): void {
        const currentTrack = this.state.tracks[trackIndex] as TrackEntry;
        const lastTrack = this.findLastTrack(currentTrack);
        const loop = lastTrack.loop;

        lastTrack.listener = {
            complete: () => {
                if (loop) {
                    this.emitRepeated();
                } else {
                    this.emitCompleted();
                }
            },
            interrupt: () => {
                this.emitInterrupted();
            }
        };
    }

    private clearEvents(): void {
        const keys = Object.getOwnPropertyNames(this.events);

        for (const key of keys) {
            // @ts-ignore
            this.events[key] = [];
        }
    }

    // lifecycle

    public async setImmediately(animation: string | string[], loop: boolean = false): Promise<void> {
        if (typeof animation === 'string') {
            this.state.setAnimation(0, animation, loop);
        } else if (Array.isArray(animation)) {
            for (let i = 0; i < animation.length; i++) {
                const anim = animation[i];

                if (i === animation.length - 1) {
                    if (i === 0) {
                        this.state.setAnimation(0, anim, loop);
                    } else {
                        this.state.addAnimation(0, anim, loop);
                    }
                } else if (i === 0) {
                    this.state.setAnimation(0, anim, false);
                } else {
                    this.state.addAnimation(0, anim, false);
                }
            }
        }

        await this.start();
    }

    public async addImmediately(animation: string | string[], loop: boolean = false): Promise<void> {
        if (typeof animation === 'string') {
            this.state.addAnimation(0, animation, loop);
        } else if (Array.isArray(animation)) {
            for (let i = 0; i < animation.length; i++) {
                const anim = animation[i];

                if (i !== animation.length - 1) {
                    this.state.addAnimation(0, anim, false);
                } else if (i === animation.length - 1) {
                    this.state.addAnimation(0, anim, loop);
                }
            }
        }

        await this.start();
    }

    public setAnimation(animation: string | string[], loop: boolean = false): void {
        if (typeof animation === 'string') {
            this.state.setAnimation(0, animation, loop);
        } else if (Array.isArray(animation)) {
            for (let i = 0; i < animation.length; i++) {
                const anim = animation[i];

                if (i === animation.length - 1) {
                    if (i === 0) {
                        this.state.setAnimation(0, anim, loop);
                    } else {
                        this.state.addAnimation(0, anim, loop);
                    }
                } else if (i === 0) {
                    this.state.setAnimation(0, anim, false);
                } else {
                    this.state.addAnimation(0, anim, false);
                }
            }
        }
    }

    public addAnimation(animation: string | string[], loop: boolean = false): void {
        if (typeof animation === 'string') {
            this.state.addAnimation(0, animation, loop);
        } else if (Array.isArray(animation)) {
            for (let i = 0; i < animation.length; i++) {
                const anim = animation[i];

                if (i !== animation.length - 1) {
                    this.state.addAnimation(0, anim, false);
                } else if (i === animation.length - 1) {
                    this.state.addAnimation(0, anim, loop);
                }
            }
        }
    }

    public start(): Promise<void> {
        const currentTrack = this.state.tracks[0];
        let loop: boolean = false;

        if (currentTrack) {
            loop = this.findLastTrack(currentTrack).loop;
        }


        if (!this.visible) {
            this.show();
        }

        this.setEvents(0);

        return new Promise(res => {
            this.addOnce(loop ? 'repeat' : 'complete', () => res());
            this.running = true;
            this.emitStarted();
        });
    }

    public pause(): void {
        this.running = false;
    }

    public resume(): void {
        this.running = true;
    }

    public stop(): void {
        this.running = false;
    }

    public softStop(): Promise<void> {
        return new Promise(res => {
            for (const track of this.state.tracks) {
                if (track) {
                    const lastTrack = this.findLastTrack(track);
                    if (lastTrack.loop) {
                        this.addOnce('repeat', () => {
                            lastTrack.loop = false;
                            this.running = false;
                            res();
                        });
                    }
                }
            }
        });
    }

    public reset(hide: boolean = true): void {
        this.running = false;
        this.update(0);
        this.state.clearTracks();
        this.state.trackEntryPool.clear();
        this.state.setEmptyAnimations();
        this.skeleton.setSlotsToSetupPose();
        this.skeleton.setBonesToSetupPose();


        if (hide) {
            this.hide();
        }
    }

    public show(): void {
        this.visible = true;
    }

    public hide(): void {
        this.visible = false;
    }

    public destroy(): void {
        this.running = false;
        this.clearEvents();
        super.destroy()
    }

    public static(skinName: string | string[] = 'default', animationName?: string) {
        if (!animationName) {
            this.state.setEmptyAnimation(0, 0);
        } else {
            if (!this.animations[animationName]) {
                console.error(`animation ${animationName} doesn't exists!`);
                return;
            }

            this.state.setAnimation(0, animationName, false);
        }

        if (skinName === 'default') {
            // @ts-ignore
            this.skeleton.setSkin(null);
            this.skeleton.setSlotsToSetupPose();
        } else if (typeof skinName === 'string') {
            this.setSkin(skinName);
        } else if (Array.isArray(skinName)) {
            this.setCombinedSkin(...skinName);
        }

        this.update(0);
        this.running = false;
    };

    public setSkin(skinName: string): void {
        if (!this.skins[skinName]) {
            console.error(`skin ${skinName} doesn't exists!`);
            return;
        }

        //@ts-ignore
        this.skeleton.setSkin(null);
        this.skeleton.setSkinByName(skinName);
        this.skeleton.setSlotsToSetupPose();
    }

    public setCombinedSkin(...skinNames: string[]): void {
        const newSkin = <any>new Skin('combined');

        for (const skinName of skinNames) {
            if (!this.skins[skinName]) {
                console.error(`skin ${skinName} doesn't exists!`);
                return;
            }

            newSkin.addSkin(this.skeleton.data.findSkin(skinName));
        }

        // @ts-ignore
        this.skeleton.setSkin(newSkin);
        this.skeleton.setSlotsToSetupPose();
    }

    // system

    private findLastTrack(track: TrackEntry): TrackEntry {
        if (!track.next) {
            return track;
        }

        return this.findLastTrack(track.next);
    }
}
