import Decimal from "decimal.js";
import { CurrencyConfig, CurrencyData } from "./CurrencyConfig";
import { GameId } from "../../enums/GameId";

export class CurrencyManager {
  private static _instance: CurrencyManager;
  private currentCurrency: string = "THB"; // Default currency

  public static get instance(): CurrencyManager {
    if (!CurrencyManager._instance) {
      CurrencyManager._instance = new CurrencyManager();
    }
    return CurrencyManager._instance;
  }

  private getConfig(gameId?: GameId): CurrencyData | undefined {
    return CurrencyConfig.getConfig(
      this.currentCurrency,
      gameId as GameId | undefined
    );
  }

  public detectCurrencyFromQueryString(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const currencyParam = urlParams.get("c");
    if (currencyParam && CurrencyConfig.isCurrencySupported(currencyParam)) {
      this.currentCurrency = currencyParam.toUpperCase();
    }
  }

  public getCurrentCurrency(): string {
    return this.currentCurrency;
  }

  public getCurrencySymbol(gameId?: GameId): string {
    const config = this.getConfig(gameId);
    return config?.currencySymbol || "";
  }

  public get buyBonusPrice(): Decimal {
    const config = this.getConfig();
    return config ? config.buyBonusPrice : new Decimal(40.5); // Default to 'THB' if not found
  }

  public getBetAmounts(gameId?: GameId): Decimal[] {
    const config = this.getConfig(gameId);
    return config ? config.bets : [];
  }

  public formatAmount(amount: number, gameId?: GameId): string {
    return `${this.getCurrencySymbol(gameId)} ${this.formatAmountDecimal(
      amount
    )}`;
  }

  public formatAmountDecimal(
    amount: number,
    fractionDigits: number = 2
  ): string {
    return `${amount.toFixed(fractionDigits)}`;
  }

  public formatAmountDecimalWithCommas(amount: number): string {
    // Convert to string with 2 decimal places
    const roundedAmount = amount.toFixed(2);

    // Remove trailing zeros and unnecessary decimal point
    const fixedAmount = roundedAmount.replace(/\.?0+$/, "");

    // Split into whole and decimal parts
    const parts = fixedAmount.split(".");

    // Add commas to the whole number part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join whole and decimal parts, but only if there's a decimal part
    return parts.length > 1 ? parts.join(".") : parts[0];
  }

  public isCurrencySupported(currency: string): boolean {
    return CurrencyConfig.isCurrencySupported(currency);
  }

  public setCurrency(currency: string): void {
    if (this.isCurrencySupported(currency)) {
      this.currentCurrency = currency.toUpperCase();
    } else {
      console.warn(
        `Currency ${currency} is not supported. Keeping current currency.`
      );
    }
  }

  public getSupportedCurrencies(): string[] {
    return CurrencyConfig.getSupportedCurrencies();
  }
}
