import { AnimatedSprite, Assets, Texture } from "pixi.js";

const coinTextureNames = [
  "scatter_1",
  "scatter_2",
  "scatter_3",
  "scatter_4",
  "scatter_5",
  "scatter_6",
];

export class CoinSpinAnimation extends AnimatedSprite {
  constructor() {
    const textures = coinTextureNames.map((name) => Assets.get(name));

    super(textures);

    this.animationSpeed = 0.3;
    this.anchor.set(0.5);
    this.loop = true;
    this.play();
  }

  restartPlaying(): void {
    this.gotoAndPlay(0);
  }
}
