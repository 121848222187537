import { Input, InputOptions } from "../../../../ui/components/Input";
import { Button } from "../../../../ui/components/Button";
import { Container, Graphics, setPositions, Sprite, TextStyle } from "pixi.js";
import { Checkbox } from "../../../../ui/components/Checkbox";
import { AutoplayManager } from "../../auto_play_button/AutoplayManager";
import { BalanceDisplay } from "../../BalanceDisplay";
import { CurrencyManager } from "../../../../common/src/utils/currency/CurrencyManager";
import { GameView } from "../../../../GameView";
import { Translator } from "../../../../common/src/utils/translation/Translator";
import { FontFamily } from "../../../../common/src/utils/FontManager";
import gsap from "gsap";

// components
import { ISpineButtonAnimations, SpineButton } from "../../../../ui/components/SpineButton";

export class AutoplaySettingsWindow extends Container {
  private static _instance: AutoplaySettingsWindow | null = null;

  private title!: Sprite;
  public totalSpinsInput!: Input;
  public totalSpinCheckBox!: Checkbox;

  public stopIfSingleWinRatioExceedsInput!: Input;
  public stopIfSingleWinRatioExceedsCheckbox!: Checkbox;
  public stopIfBalanceLessThanInput!: Input;
  public stopIfBalanceLessThanCheckBox!: Checkbox;
  public stopIfBalanceMoreThanInput!: Input;
  public stopIfBalanceMoreThanCheckBox!: Checkbox;
  public stopIfFreeGameActivatedCheckBox!: Checkbox;
  public startButton!: SpineButton;
  public cancelButton!: Button;
  public setTotalSpin50Button!: Button;
  public setTotalSpin100Button!: Button;
  public setTotalSpin200Button!: Button;
  public setTotalSpin500Button!: Button;
  public setTotalSpin999Button!: Button;
  public isWindowOpen: boolean = false;
  private sprite!: Sprite;

  private constructor() {
    super();
    this.setup();
    this.setupFrame();
    this.setupTitle();
    this.createBackground();
    this.createInputsAndCheckboxes();
    this.createButtons();
    this.hide();
    this.scale = 0.94;
    this.position.y -= 70;
    this.alpha = 0.95;
  }

  public static get instance(): AutoplaySettingsWindow {
    if (!this._instance) {
      this._instance = new AutoplaySettingsWindow();
    }
    return this._instance;
  }

  public show(): void {
    if (
      this.stopIfBalanceLessThanInput.value > BalanceDisplay.instance.balance
    ) {
      this.stopIfBalanceLessThanInput.updateValue(
        Math.floor(BalanceDisplay.instance.balance * 0.4)
      );
    }
    if (
      this.stopIfBalanceMoreThanInput.value < BalanceDisplay.instance.balance
    ) {
      this.stopIfBalanceMoreThanInput.updateValue(
        Math.floor(BalanceDisplay.instance.balance * 1.5)
      );
    }
    this.visible = true;
    this.isWindowOpen = true;
  }

  public hide(): void {
    this.visible = false;
    this.isWindowOpen = false;
  }

  private setup(): void {
    this.position.set(
      GameView.instance.width / 2,
      GameView.instance.height / 2
    );
    this.scale.set(0.95);
  }

  private startAutoplay(): void {
    AutoplayManager.instance.startAutoplay();
    this.hide();
  }

  private setupFrame(): void {
    this.sprite = Sprite.from("modal_ap");
    this.sprite.anchor.set(0.5);
    this.addChild(this.sprite);
  }

  private setupTitle() {
    this.title = Sprite.from(`autospin_setting_${Translator.instance.language}`);
    this.title.anchor.set(0.5);
    this.title.position.set(
      Translator.instance.language === "en" ? 7 : 0,
      Translator.instance.language === "en" ? -194 : -203
    );
    this.title.scale.set(0.83);
    this.addChild(this.title);
  }

  private createBackground(): void {
    const background = new Graphics()
      .rect(-1000, -2000, 2000, 4000)
      .fill(0x000000);
    background.alpha = 0.5;
    background.zIndex = -1;
    background.on("click", () => this.hide());
    background.on("tap", () => this.hide());
    this.addChild(background);
  }

  private createInputsAndCheckboxes(): void {
    this.totalSpinsInput = this.createInput(
      { min: 5, max: 999, value: 100 },
      129,
      -135
    );

    this.totalSpinsInput.setOnChange(() => {
      this.totalSpinCheckBox.check();
    });

    this.totalSpinCheckBox = this.createCheckbox(
      true,
      Translator.instance.getText("total_spins"),
      -215,
      -144,
      {
        fontSize: 24
      }
    );

    this.totalSpinCheckBox.setButtonSize(39);

    this.stopIfSingleWinRatioExceedsInput = this.createInput(
      { min: 1, value: 100, postfix: "X" },
      129,
      -22
    );

    this.stopIfSingleWinRatioExceedsInput.setOnChange(() => {
      this.stopIfSingleWinRatioExceedsCheckbox.check();
    });

    this.stopIfSingleWinRatioExceedsCheckbox = this.createCheckbox(
      false,
      Translator.instance.getText("single_win_ration_exceeds"),
      -215,
      -22,
      {
        fontSize: 20,
        letterSpacing: 0.6
      }
    );

    this.stopIfSingleWinRatioExceedsCheckbox.setButtonSize(39);

    this.stopIfBalanceLessThanInput = this.createInput(
      {
        min: 1,
        value: Math.floor(BalanceDisplay.instance.balance * 0.4),
        step: 10,
        prefix: CurrencyManager.instance.getCurrencySymbol(),
      },
      129,
      28
    );

    this.stopIfBalanceLessThanInput.setOnChange(() => {
      this.stopIfBalanceLessThanCheckBox.check();
    });

    this.stopIfBalanceLessThanCheckBox = this.createCheckbox(
      false,
      Translator.instance.getText("stop_if_balance_less_than"),
      -215,
      28,
      {
        fontSize: 22,
        fontWeight: '400',
        letterSpacing: 0.6
      }
    );

    this.stopIfBalanceLessThanCheckBox.setButtonSize(39);

    this.stopIfBalanceMoreThanInput = this.createInput(
      {
        min: 1,
        value: Math.floor(BalanceDisplay.instance.balance * 1.5),
        step: 10,
        prefix: CurrencyManager.instance.getCurrencySymbol(),
      },
      129,
      78
    );

    this.stopIfBalanceMoreThanInput.setOnChange(() => {
      this.stopIfBalanceMoreThanCheckBox.check();
    });

    this.stopIfBalanceMoreThanCheckBox = this.createCheckbox(
      false,
      Translator.instance.getText("stop_if_balance_more_than"),
      -215,
      78,
      {
        fontSize: 22,
        fontWeight: '400',
        letterSpacing: 0.6
      }
    );

    this.stopIfBalanceMoreThanCheckBox.setButtonSize(39);

    this.stopIfFreeGameActivatedCheckBox = this.createCheckbox(
      false,
      Translator.instance.getText("stop_on_fs_won"),
      -215,
      129,
      {
        fontSize: 24,
        fontWeight: '400',
        letterSpacing: 0.4
      }
    );

    this.stopIfFreeGameActivatedCheckBox.setButtonSize(39);
  }

  private createButtons(): void {
    this.cancelButton = this.createButton(
      "button_red",
      Translator.instance.getText("cancel"),
      -106,
      196,
      0.8,

      () => this.hide()
    );

    this.cancelButton.text.style.fontSize = 33;
    this.cancelButton.text.alpha = 0.7;

    this.startButton = this.createSpineButton(
      { skeleton: 'green-button_glow.json', atlas: 'green-button_glow.atlas' },
      { normal: 'idle' },
      Translator.instance.getText("start"),
      100,
      196,
      0.8,
      () => this.startAutoplay()
    );

    this.startButton.text.style.fontSize = 33;
    this.startButton.text.alpha = 0.7;

    this.createSpinButtons();
    this.styleSpinButtons();
  }

  private createSpinButtons(): void {
    this.setTotalSpin50Button = this.createButton(
      "button_brown",
      "50",
      -168,
      -87,
      0.7,

      () => this.totalSpinsInput.updateValue(50)
    );
    this.setTotalSpin100Button = this.createButton(
      "button_brown",
      "100",
      -77.25,
      -87,
      0.7,

      () => this.totalSpinsInput.updateValue(100)
    );
    this.setTotalSpin200Button = this.createButton(
      "button_brown",
      "200",
      13.5,
      -87,
      0.7,

      () => this.totalSpinsInput.updateValue(200)
    );
    this.setTotalSpin500Button = this.createButton(
      "button_brown",
      "500",
      104.6,
      -87,
      0.7,

      () => this.totalSpinsInput.updateValue(500)
    );
    this.setTotalSpin999Button = this.createButton(
      "button_brown",
      "999",
      195,
      -87,
      0.7,

      () => this.totalSpinsInput.updateValue(999)
    );
  }

  private styleSpinButtons(): void {
    this.setButtonStyle(this.setTotalSpin50Button);
    this.setButtonStyle(this.setTotalSpin100Button);
    this.setButtonStyle(this.setTotalSpin200Button);
    this.setButtonStyle(this.setTotalSpin500Button);
    this.setButtonStyle(this.setTotalSpin999Button);
  }

  private setButtonStyle(button: Button): void {
    button.text.style.fontSize = 33;
    button.text.style.fill = 0x9f9c5b;
    button.sprite!.scale.set(0.45, 1.05);
  }

  private createInput(config: InputOptions, x: number, y: number): Input {
    const input = new Input(config);
    input.position.set(x, y);
    this.addChild(input);
    return input;
  }

  private createCheckbox(
    isChecked: boolean,
    text: string,
    x: number,
    y: number,
    textStyle?: Partial<TextStyle>
  ): Checkbox {
    const checkbox = new Checkbox(isChecked, text);
    checkbox.position.set(x, y);

    if (textStyle) {
      for (const styleKey of Object.getOwnPropertyNames(textStyle)) {
        //@ts-ignore
        checkbox.text.style[styleKey] = textStyle[styleKey];
      }
    }

    this.addChild(checkbox);
    return checkbox;
  }

  private createButton(
    sprite: string,
    text: string,
    x: number,
    y: number,
    scale: number,
    onClick?: () => void
  ): Button {
    const button = new Button(sprite, text);
    button.text.style.fontFamily = FontFamily.TIMES_NEW_ROMAN;
    button.position.set(x, y);
    button.scale.set(scale);
    button.setOriginalScale(scale);

    if (onClick) {
      button.on("click", onClick);
      button.on("tap", onClick);
    }
    this.addChild(button);
    return button;
  }

  private createSpineButton(
    spine: { skeleton: string; atlas: string; },
    animations: ISpineButtonAnimations,
    text: string,
    x: number,
    y: number,
    scale: number,
    onClick?: () => void
  ): SpineButton {
    const spineButton = new SpineButton(spine, animations, text);

    spineButton.text.style.fontFamily = FontFamily.TIMES_NEW_ROMAN;
    spineButton.position.set(x, y);
    spineButton.scale.set(scale);
    spineButton.setOriginalScale(scale);

    if (onClick) {
      spineButton.on('click', onClick);
      spineButton.on('tap', onClick);
    }

    this.addChild(spineButton);
    return spineButton;
  }
}
