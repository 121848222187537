import { Config } from "../../../config/Config";
import { BaseSymbol } from "./symbols/BaseSymbol";
import { CardSymbol } from "./symbols/CardSymbol";
import { GoldCardSymbol } from "./symbols/GoldCardSymbol";
import { ScatterSymbol } from "./symbols/ScatterSymbol";
import { BigWildSymbol } from "./symbols/BigWildSymbol";
import { WildSymbol } from "./symbols/WildSymbol";
import { ReelCascadeHandler } from "./ReelCascadeHandler";
import { ReelWildsHandler } from "./ReelWildHandler";
import { ReelStartScreenAnimationHandler } from "./ReelStartScreenAnimationHandler";
import { SymbolType } from "./symbols/SymbolType";
import { Container } from "pixi.js";
import { ReelSet } from "../ReelSet";

export enum ReelLayer {
  Base = 0,
  Scatter = 10,
  Win = 20,
  WildWin = 30,
}

export class Reel {
  public symbols: BaseSymbol[] = [];
  public symbolsToDestroy: BaseSymbol[] = [];
  public symbolsPerReel: number;
  public reelIndex: number;
  public bigWildSymbols: BaseSymbol[] = [];
  public bigWildPlaceholderSymbols: BaseSymbol[] = [];
  public reelLayers: Map<ReelLayer, Container> = new Map();
  public cascadeHandler: ReelCascadeHandler;
  public wildsHandler: ReelWildsHandler;
  public reelStartScreenAnimationHandler: ReelStartScreenAnimationHandler;

  constructor(symbolsPerReel: number, reelIndex: number) {
    this.symbolsPerReel = symbolsPerReel;
    this.reelIndex = reelIndex;

    this.initializeReelLayers();
    this.cascadeHandler = new ReelCascadeHandler(this);
    this.wildsHandler = new ReelWildsHandler(this);
    this.reelStartScreenAnimationHandler = new ReelStartScreenAnimationHandler(
      this
    );
  }

  public addNewSymbolToReelLayer(
    symbolIndex: number,
    symbol: BaseSymbol,
    layer: ReelLayer
  ): void {
    const yPos =
      (this.symbolsPerReel - 1 - symbolIndex) *
        (Config.reelSize.symbolHeight + Config.reelSize.gapY) +
      Config.reelSize.symbolHeight / 2;
    const xPos = Config.reelSize.symbolWidth / 2;

    symbol.container.position.set(xPos, yPos);
    this.reelLayers.get(layer)?.addChild(symbol.container);
  }

  public changeSymbolLayer(symbol: BaseSymbol, layer: ReelLayer): void {
    const containerLayer = this.reelLayers.get(layer);
    if (containerLayer) {
      containerLayer.addChild(symbol.container);
    }
  }

  private initializeReelLayers(): void {
    for (const layer of Object.values(ReelLayer)) {
      if (typeof layer === "number") {
        const container = new Container();
        container.label = `REEL_${this.reelIndex}_LAYER_${ReelLayer[layer]}`;
        container.zIndex = layer;
        this.reelLayers.set(layer, container);
        container.position.set(
          Config.reelSize.offsetX +
            this.reelIndex *
              (Config.reelSize.symbolWidth + Config.reelSize.gapX),
          Config.reelSize.offsetY
        );
        ReelSet.instance.reelsContainer.addChild(container);
      }
    }
  }

  public updateSymbol(symbolIndex: number, symbolType: SymbolType) {
    let newSymbolToAdd = this.createNewSymbolToAdd(symbolType, symbolIndex);

    // this.symbols[symbolIndex].destroy(); //destroy the old symbol
    this.symbolsToDestroy.push(this.symbols[symbolIndex]);
    // set sysmbol viasbility to non visibale
    this.symbols[symbolIndex].container.visible = false;
    this.addNewSymbol(symbolIndex, newSymbolToAdd);
  }

  public addNewSymbol(
    symbolIndex: number,
    newSymbolToAdd: CardSymbol | ScatterSymbol
  ) {
    this.symbols[symbolIndex] = newSymbolToAdd;
    // visualy adds it to the reel
    let reelLayer;
    if (newSymbolToAdd.symbolType === SymbolType.Scatter) {
      reelLayer = ReelLayer.Scatter;
    } else {
      reelLayer = ReelLayer.Base;
    }

    this.addNewSymbolToReelLayer(symbolIndex, newSymbolToAdd, reelLayer);
  }

  private createNewSymbolToAdd(symbolType: SymbolType, symbolIndex: number) {
    let newSymbolToAdd;

    if (BaseSymbol.isWhiteCard(symbolType))
      newSymbolToAdd = new CardSymbol(symbolIndex, this.reelIndex, symbolType);
    else if (symbolType === SymbolType.Scatter)
      newSymbolToAdd = new ScatterSymbol(symbolIndex, this.reelIndex);
    else if (symbolType === SymbolType.Wild)
      newSymbolToAdd = new WildSymbol(symbolIndex, this.reelIndex, symbolType);
    else if (symbolType === SymbolType.OriginalBigWild)
      newSymbolToAdd = new BigWildSymbol(
        symbolIndex,
        this.reelIndex,
        symbolType,
        true
      );
    else if (symbolType === SymbolType.BigWild)
      newSymbolToAdd = new BigWildSymbol(
        symbolIndex,
        this.reelIndex,
        symbolType // Big Wilds are expanding from original facing up
      );
    // Symbol is gold
    else
      newSymbolToAdd = new GoldCardSymbol(
        symbolIndex,
        this.reelIndex,
        symbolType
      );
    return newSymbolToAdd;
  }

  async performSymbolWinAnimation(winningSymbolsPositions: string[]) {
    this.symbols.forEach((symbol, id) => {
      if (!winningSymbolsPositions[id]) {
        symbol.blur();
      }
    });

    this.symbols.forEach((symbol, id) => {
      if (winningSymbolsPositions[id]) {
        symbol.performSymbolWinAnimation();
        this.changeSymbolLayer(
          symbol,
          BaseSymbol.isWildCard(symbol.symbolType)
            ? ReelLayer.WildWin
            : ReelLayer.Win
        );
      }
    });
  }

  async performSymbolAfterWinAnimation(winningSymbolsPositions: string[]) {
    this.symbols.forEach((symbol, id) => {
      // disapeer the win symbols
      if (winningSymbolsPositions[id]) {
        if (BaseSymbol.isGoldenCard(symbol.symbolType)) {
          (symbol as GoldCardSymbol).performFaceDownCardAfterWinAnimation();
        } else {
          symbol.performSymbolAfterWinAnimation();
        }
      }
    });
  }

  public blur() {
    this.symbols.forEach((symbol, id) => {
      if (symbol.symbolType !== SymbolType.Scatter) {
        symbol.blur();
      }
    });
  }

  public destroySymbols() {
    this.symbolsToDestroy.forEach((symbol, id) => {
      symbol.destroy();
    });

    this.symbolsToDestroy = [];
  }

  public unBlur() {
    this.symbols.forEach((symbol, id) => {
      symbol.unBlur();
    });
  }

  public async performSymbolExitAnimation() {
    this.symbols.forEach(async (symbol, id) => {
      symbol.performSymbolExitAnimation();
    });
  }

  public getSymbol(symbolID: number): BaseSymbol {
    return this.symbols[symbolID];
  }
}
