import { TranslationsDictionary } from "./TranslationsDictionary";
import { TranslationType } from "./TranslationType";

export class Translator {
  private static _instance: Translator | null = null;
  private translationsDictionary: TranslationType = {};
  private _language: string = "en";

  // Private constructor to prevent external instantiation
  private constructor() {
    this.initialize();
  }

  public static get instance(): Translator {
    if (!Translator._instance) {
      Translator._instance = new Translator();
    }
    return Translator._instance;
  }

  private initialize(): void {
    this.detectLanguageFromQueryString();
    this.translationsDictionary = TranslationsDictionary;
  }

  public getText(
    key: string,
    values: Record<string, string> | null = null
  ): string {
    const translations = this.translationsDictionary;
    const language = this._language;

    if (translations[key] && translations[key][language]) {
      let translatedText = translations[key][language];

      // Only process replacements if `values` is provided
      if (values) {
        translatedText = translatedText.replace(
          /\{(\w+)\}/g,
          (_, placeholder) => {
            // Return the value if it exists, otherwise leave the placeholder intact
            return values[placeholder] !== undefined
              ? values[placeholder]
              : `{${placeholder}}`;
          }
        );
      }

      return translatedText;
    }

    console.warn(
      `Translation not found for key: "${key}" in language: "${language}"`
    );
    return key;
  }

  public get language(): string {
    return this._language;
  }

  // Detect the current language from the URL query string
  private detectLanguageFromQueryString(): void {
    const query = new URLSearchParams(window.location.search);
    this._language = query.get("l") || "en";
  }
}
