import { Graphics, Text, Container } from "pixi.js";
import gsap from "gsap";
import { GameView } from "../../GameView";

import { Translator } from "../../common/src/utils/translation/Translator";
import { Delay } from "../../common/src/utils/Delay";
import { FontManager } from "../../common/src/utils/FontManager";

export class SpinStatusToast extends Container {
  private static _instance: SpinStatusToast;
  private text!: Text;
  private toastBackground!: Graphics;
  private readonly reelSetWidth = GameView.instance.width / 2;

  private constructor() {
    super();
    this.setupContainer();
    this.createToastBackground();
    this.createText();
  }

  public static get instance(): SpinStatusToast {
    if (!SpinStatusToast._instance) {
      SpinStatusToast._instance = new SpinStatusToast();
    }
    return SpinStatusToast._instance;
  }

  private setupContainer(): void {
    this.position.set(this.reelSetWidth, 350);
    this.zIndex = 1000;
    this.alpha = 0;
    this.visible = false;
  }

  private createToastBackground(): void {
    this.toastBackground = new Graphics()
      .rect(-this.reelSetWidth, -40, this.reelSetWidth * 2, 80)
      .fill("#00000080");
    this.addChild(this.toastBackground);
  }

  private createText(): void {
    this.text = new Text({
      text: Translator.instance.getText("turbo_spin_enabled"),
      style: {
        fontFamily: FontManager.instance.defaultFontFamily,
        fontSize: 30,
        fill: 0xffffff,
      },
    });
    this.text.anchor.set(0.5);
    this.addChild(this.text);
  }

  public showSpinStatus(message: string): void {
    this.text.text = message;
    this.showToast();
  }

  private async showToast(): Promise<void> {
    this.visible = true;
    await gsap.to(this, {
      pixi: { alpha: 1 },
      duration: 0.3,
      ease: "linear",
    });

    await Delay.delay(1000);
    await this.hide();
  }

  private async hide(): Promise<void> {
    await gsap.to(this, {
      pixi: { alpha: 0 },
      duration: 0.3,
      ease: "linear",
    });
    this.visible = false;
  }
}
