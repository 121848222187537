import { Config } from "../../config/Config";
import { Sprite, Container, BLEND_MODES } from "pixi.js";
import { GameView } from "../../GameView";

export class SpotLights extends Container {
  private static _instance: SpotLights;
  private lights: Sprite[] = [];
  private bottomShine!: Sprite;

  private constructor() {
    super();
    this.createBottomShine();
    this.createSpotlight();
    this.zIndex = 20;
    this.showHideLights(false);
  }

  public static get instance(): SpotLights {
    if (!SpotLights._instance) {
      SpotLights._instance = new SpotLights();
    }
    return SpotLights._instance;
  }

  private createBottomShine(): void {
    this.bottomShine = Sprite.from("reel_bottom_shine");
    this.bottomShine.anchor.set(0.5, 0.5);
    this.bottomShine.tint = 0xffff66;
    this.bottomShine.alpha = 0.8;
    this.bottomShine.zIndex = 5;
    this.bottomShine.position.set(
      GameView.instance.width / 2,
      GameView.instance.height / 2 + 200
    );
    this.bottomShine.scale.set(2.4, 2);
    this.addChild(this.bottomShine);
  }

  private createSpotlight(): void {
    for (let i = 0; i < Config.reelAmount; ++i) {
      const light = Sprite.from("reel_light_top");
      light.anchor.set(0.5, 0.5);
      light.scale.set(3, 2.5);
      light.blendMode = "add";
      light.tint = 0xffff55;
      light.alpha = 0.65;

      // Positioning the light
      light.position.set(
        Config.reelSize.offsetX +
          Config.reelSize.symbolWidth * (i + 0.5) +
          Config.reelSize.gapX * i,
        Config.reelSize.offsetY + 10
      );

      this.addChild(light);
      this.lights.push(light);
    }
  }

  public showHideLights(isShow: boolean): void {
    this.lights.forEach((light) => (light.visible = isShow));
    this.bottomShine.visible = isShow;
  }
}
