import { GameView } from "../../GameView";
import { Container, Graphics, Sprite, Texture, BlurFilter } from "pixi.js";

import { ControlPanel } from "../../game/control_panel/ControlPanel";
import { SoundEffectManager } from "../../sounds/SoundEffectManager";

// import { FeaturePopup } from "../popups/FeaturePopup";
import { SOUNDS } from "../../sounds/Sounds";
import { ReelSet } from "../../game/reelset/ReelSet";
import { Button } from "../../ui/components/Button";
import { IntroLights } from "../reelset/IntroLights";
import { BuyBonusButton } from "../header/buy_bonus/BuyBonusButton";
import gsap from "gsap";
import { Delay } from "../../common/src/utils/Delay";
import Game from "../../Game";
import { Spine } from "@pixi/spine-pixi";
import { SpineButton } from "../../ui/components/SpineButton";

export class PlayScreen extends Container {
  private static _instance: PlayScreen | null = null;
  private playButton!: Button;
  private buttonAnimation!: SpineButton;

  private constructor() {
    super();
    this.setupBackground();
    this.setupLogo();
    // this.initPlayButton();
    this.startButtonAnimation();
    this.zIndex = 1000;
  }

  public static get instance(): PlayScreen {
    if (this._instance === null) {
      this._instance = new PlayScreen();
    }
    return this._instance;
  }

  private setupBackground(): void {
    const bg = new Graphics()
      .rect(0, 0, GameView.instance.width, GameView.instance.height)
      .fill(0x000000);

    bg.alpha = 0.75;
    this.addChild(bg);
  }

  private setupLogo(): void {
    const logo = Sprite.from("gamelogo");
    logo.anchor.set(0.5);
    logo.position.set(
      GameView.instance.width / 2,
      GameView.instance.height / 2 + 30
    );
    logo.scale.set(0.8);
    this.addChild(logo);
  }

  private initPlayButton(): void {
    this.playButton = new Button();
    this.playButton.updateSprite("button_play", true);
    this.playButton.position.set(
      GameView.instance.width / 2,
      GameView.instance.height / 2 + 100
    );
    this.playButton.scale.set(0.9, 0.9);
    this.playButton.on("click", async () => this.onPlayButtonClick());
    this.playButton.on("tap", async () => this.onPlayButtonClick());
    this.addChild(this.playButton);
  }

  public startButtonAnimation(): void {
    this.buttonAnimation = new SpineButton(
      {
        skeleton: "play-button.json",
        atlas: "play-button.atlas",
      },
      {
        normal: "idle",
      }
    );
    this.buttonAnimation.position.set(
      GameView.instance.width / 2,
      GameView.instance.height / 2 + 100
    );
    this.buttonAnimation.scale.set(0.95);
    this.buttonAnimation.on("click", async () => this.onPlayButtonClick());
    this.buttonAnimation.on("tap", async () => this.onPlayButtonClick());
    this.addChild(this.buttonAnimation);
  }

  private async onPlayButtonClick(): Promise<void> {
    this.destroy();
    SoundEffectManager.instance.playReelStartScreenAnimation();
    IntroLights.instance.startIntroLights();
    await Delay.delay(28 * 33);
    ReelSet.instance.doStartScreenAnimation();
    await Delay.delay(53 * 33);

    SoundEffectManager.instance.playSound(SOUNDS.WIN, false);
    // FeaturePopup.instance.show();
    ControlPanel.instance.showAll();
    BuyBonusButton.instance.visible = true;
  }
}
