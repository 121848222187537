import { GameView } from "../../../GameView";
import { Button } from "../../../ui/components/Button";

export class SettingsButton extends Button {
  private static _instance: SettingsButton;
  protected originalScale = { x: 0.88, y: 0.88 };

  private constructor() {
    super(undefined); // No initial texture passed
    this.setup();
  }

  public static get instance(): SettingsButton {
    if (!SettingsButton._instance) {
      SettingsButton._instance = new SettingsButton();
    }
    return SettingsButton._instance;
  }

  private setup(): void {
    this.updateSprite("button_settings", true);
    this.position.set(GameView.instance.width / 2 - 202, 758);
    this.scale.set(this.originalScale.x, this.originalScale.y);
    this.interactive = true;
  }
}
