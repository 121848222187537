// IntroLights.ts

import { Container, Sprite } from "pixi.js";
import { Config } from "../../config/Config";
import { Delay } from "../../common/src/utils/Delay";
import { Tween } from "../../utils/Tween";
import { ReelSet } from "./ReelSet";

export class IntroLights extends Container {
  private static _instance: IntroLights;

  private constructor() {
    super();
  }

  public static get instance(): IntroLights {
    if (!IntroLights._instance) {
      IntroLights._instance = new IntroLights();
    }
    return IntroLights._instance;
  }

  public async startIntroLights(): Promise<void> {
    for (let i = 0; i < 5; ++i) {
      this.createIntroLight(i);
      await Delay.delay(33 * 9);
    }
  }

  private async createIntroLight(id: number): Promise<void> {
    const light = new Container();
    light.scale.set(2, 2);

    const spotlight = Sprite.from("light_spotlight");
    spotlight.tint = 0xffff66;
    spotlight.alpha = 0;
    spotlight.anchor.set(0.5, 0); // Set anchor to the center
    spotlight.position.set(27, -20);
    spotlight.blendMode = "add";
    light.addChild(spotlight);

    const reellight1 = Sprite.from("light_reel");
    reellight1.tint = 0xaaff66;
    reellight1.alpha = 0;
    reellight1.position.set(0, -10);
    light.addChild(reellight1);

    const reellight2 = Sprite.from("light_reel");
    reellight2.tint = 0xffff66;
    reellight2.scale.set(1, -1);
    reellight2.position.set(0, 230);
    reellight2.alpha = 0;
    light.addChild(reellight2);

    const reelshine = Sprite.from("light_reelintro");
    reelshine.tint = 0xaaff66;
    reelshine.scale.set(0.25, 0.25);
    reelshine.alpha = 0;
    reelshine.blendMode = "add";
    light.addChild(reelshine);

    light.position.set(
      id * (Config.reelSize.symbolWidth + Config.reelSize.gapX) - 5,
      Config.reelSize.offsetY
    );

    this.addChild(light);

    Tween.start(
      (n) => {
        reelshine.position.y = n * 100;
      },
      0,
      0.8,
      15 * 33
    );
    Tween.start(
      (n) => {
        reelshine.alpha = n * n;
      },
      0.25,
      1,
      15 * 33
    );

    await Tween.start(
      (n) => {
        spotlight.alpha = 5 * n;
        spotlight.scale.x = 1 + 0.5 * n;
        spotlight.scale.y = 2 + 0.75 * n;
      },
      0.1,
      1,
      15 * 33,
      Tween.EASE.EASE_IN
    );
    Tween.start(
      (n) => {
        spotlight.alpha = 3 * n;
      },
      1,
      0,
      7 * 33,
      Tween.EASE.EASE_OUT_3
    );
    Tween.start(
      (n) => {
        reelshine.position.y = 100 + n * 100;
      },
      0,
      1,
      7 * 33
    );
    Tween.start(
      (n) => {
        reelshine.alpha = n * n;
      },
      0.8,
      0,
      7 * 33
    );

    await Tween.start(
      (n) => {
        reellight1.alpha = n;
      },
      0,
      1,
      12 * 33
    );

    await Tween.start(
      (n) => {
        reellight1.alpha = 1 - n;
        reellight2.alpha = n;
      },
      0,
      1,
      12 * 33
    );
    await Tween.start(
      (n) => {
        reellight2.alpha = n;
      },
      1,
      0,
      12 * 33
    );
    // light.destroy();
  }
}
