import { ILayer } from "../utils/layers/LayerManager";

export class Config {
  static reelSize = {
    symbolWidth: 90,
    symbolHeight: 110,
    offsetX: 10,
    offsetY: 200,
    gapX: 4,
    gapY: 6,
  };

  static reelAmount = 5;
  static symbolsPerReel = 4;
  static bets = [0.5, 1, 2, 3, 5, 10, 20, 30, 40, 50, 80, 100, 200, 500, 1000];
  static version = "v_137_0011";
  static assetRoot = "assets/";
  static soundRoot = "audio/";
  static bitmapMaxWidth = 450;
}
