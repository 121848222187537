import { GameView } from "./GameView";
import { JwtInitializer } from "./common/src/utils/jwt/JwtInitializer";
import { ControlPanel } from "./game/control_panel/ControlPanel";
import { BetAmountSelector } from "./game/control_panel/bet_amount_selector_button/BetAmountSelector";
import { MultiplierPanel } from "./game/header/MultiplierPanel";
import { GameLogo } from "./game/header/GameLogo";
import { FreeSpinPanel } from "./game/header/FreeSpinPanel";
import { ComboPanel } from "./game/reelset/ComboPanel";
import { WinAmountDisplay } from "./game/control_panel/WinAmountDisplay";
import { MessageLoop } from "./game/header/MessageLoop";
import { TransactionDisplay } from "./game/control_panel/TransactionDisplay";
import { IntroLights } from "./game/reelset/IntroLights";
import { SpotLights } from "./game/header/SpotLights";
import { SuspenseLights } from "./game/reelset/reel/SuspenseLights";
import { SpinStatusToast } from "./game/reelset/SpinStatusToast";
import { BalanceDisplay } from "./game/control_panel/BalanceDisplay";
import { AutoplaySettingsWindow } from "./game/control_panel/settings_button/autoplay_settings_button/AutoplaySettingsWindow";
import { BuyBonusModal } from "./game/header/buy_bonus/BuyBonusModal";

import gsap from "gsap";
import PixiPlugin from "gsap/PixiPlugin";
import * as PIXI from "pixi.js";
import { CustomEase } from "gsap/all";
import { ReelSet } from "./game/reelset/ReelSet";
import { JiliLogoScreen } from "./game/screens/JiliLogoScreen";
import { Splash } from "./game/screens/splash_screen/Splash";
import { WinAmountPopup } from "./game/popups/WinAmountPopup";
import { FreeSpinRetriggerPopup } from "./game/popups/FreeSpinRetriggerPopup";
import { FreeSpinIntroPopup } from "./game/popups/FreeSpinIntroPopup";
import { FreeSpinSummaryPopup } from "./game/popups/FreeSpinSummaryPopup";
import { BigWinPopup } from "./game/popups/BigWinPopup";
import { ErrorMessageModal } from "./game/popups/ErrorMessageModal";
// import { FeaturePopup } from "./game/popups/FeaturePopup";

import { LayersConfig } from "./utils/layers/LayersConfig";
import { Background } from "./game/Background";
import { BuyBonusButton } from "./game/header/buy_bonus/BuyBonusButton";
import { AssetManager } from "./common/src/utils/assets/AssetManager";
import { LayerManager } from "./utils/layers/LayerManager";
import { BitmapTextAssetManager } from "./common/src/utils/assets/BitmapTextAssetManager";
import { FontManager } from "./common/src/utils/FontManager";
import { CurrencyManager } from "./common/src/utils/currency/CurrencyManager";
import { SoundsPreloader } from "./sounds/SoundsPreloader";

export default class Game {
  private buildEnv: string;

  constructor(buildEnv: string) {
    this.buildEnv = buildEnv;
    this.init();
  }

  public async init() {
    PixiPlugin.registerPIXI(PIXI);
    gsap.registerPlugin(CustomEase);
    gsap.registerPlugin(PixiPlugin);
    await GameView.instance.setupPixiApp();

    CurrencyManager.instance.detectCurrencyFromQueryString();
    BalanceDisplay.instance.getInitialBalanceFromQueryString(this.buildEnv);
    JwtInitializer.init();
    ReelSet.instance.initReelSet();

    // Game resize handler
    this.setupResizeListener();

    // Load the game assets
    this.loadGame();
  }

  public async loadGame(): Promise<void> {
    await this.showJiliLogoScreen();

    await this.showSplashScreen();
    await Splash.instance.finishLoading();
    this.addUIComponents();
  }

  private async showJiliLogoScreen(): Promise<void> {
    await AssetManager.instance.loadManifest();
    await AssetManager.instance.loadJiliScreenAssets();

    // Add loading screen to the canvas
    LayerManager.instance
      .getLayer(LayersConfig.BASE_LAYERS.SCREENS)
      .addChild(JiliLogoScreen.instance);

    // Positioning logic
    GameView.instance.moveToCenter();
    JiliLogoScreen.instance.moveToCenter();

    const listener = function () {
      JiliLogoScreen.instance.moveToCenter();
    };
    const listenerWithContext = listener.bind(this);
    window.addEventListener("resize", listenerWithContext);

    // Start loading the splash screen assets
    FontManager.instance.loadFonts();
    await BitmapTextAssetManager.instance.loadBitmapFonts();
    await AssetManager.instance.loadSplashScreenAssets();
    SoundsPreloader.instance.setInteractionEvent();
    await SoundsPreloader.instance.load();
    SoundsPreloader.instance.ready = true;

    // cleanup
    JiliLogoScreen.instance.destroy();
    window.removeEventListener("resize", listenerWithContext);
  }

  private async showSplashScreen(): Promise<void> {
    // Add splash screen to the canvas
    LayerManager.instance
      .getLayer(LayersConfig.BASE_LAYERS.SCREENS)
      .addChild(Splash.instance);

    // Load the rest of the assets
    await AssetManager.instance.loadAssets();
  }

  private setupResizeListener(): void {
    window.addEventListener("resize", () => {
      GameView.instance.moveToCenter();
    });
  }

  private addUIComponents(): void {
    const componentMapping = {
      [LayersConfig.BASE_LAYERS.CONTROL_PANEL]: [
        ControlPanel.instance,
        WinAmountDisplay.instance,
        BetAmountSelector.instance,
        BalanceDisplay.instance,
      ],
      [LayersConfig.BASE_LAYERS.OVERLAY]: [
        // FeaturePopup.instance,
        SpinStatusToast.instance,
        AutoplaySettingsWindow.instance,
        WinAmountPopup.instance,
        FreeSpinRetriggerPopup.instance,
        ErrorMessageModal.instance,
        BuyBonusModal.instance,
        BigWinPopup.instance,
      ],
      [LayersConfig.BASE_LAYERS.SCREENS]: [
        FreeSpinPanel.instance,
        FreeSpinIntroPopup.instance,
        FreeSpinSummaryPopup.instance,
      ],
      [LayersConfig.HEADER_LAYERS.MULTIPLIERS_PANEL]: [
        MultiplierPanel.instance,
      ],
      [LayersConfig.BASE_LAYERS.BACKGROUND]: [Background.instance],
      [LayersConfig.HEADER_LAYERS.GAME_LOGO]: [GameLogo.instance],
      [LayersConfig.HEADER_LAYERS.MESSAGE_LOOP]: [MessageLoop.instance],
      [LayersConfig.HEADER_LAYERS.SPOT_LIGHTS]: [SpotLights.instance],
      [LayersConfig.REELS_SET_LAYERS.SUSPENCE]: [SuspenseLights.instance],
      [LayersConfig.REELS_SET_LAYERS.COMBO]: [ComboPanel.instance],
      [LayersConfig.REELS_SET_LAYERS.INTRO_LIGHTS]: [IntroLights.instance],
      [LayersConfig.BASE_LAYERS.FOOTER]: [TransactionDisplay.instance],
      [LayersConfig.HEADER_LAYERS.BUY_BONUS_BUTTON]: [BuyBonusButton.instance],
    };

    for (const [layer, comps] of Object.entries(componentMapping)) {
      comps.forEach((comp) =>
        LayerManager.instance.getLayer(layer).addChild(comp)
      );
    }
  }
}
