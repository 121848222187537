import { Text } from "pixi.js";
import { GameView } from "../../GameView";
import { Button } from "../../ui/components/Button";
import { SpinStatusToast } from "../reelset/SpinStatusToast";

import gsap from "gsap";
import { Translator } from "../../common/src/utils/translation/Translator";
import { FontManager } from "../../common/src/utils/FontManager";

export class TurboSpinButton extends Button {
  private static _instance: TurboSpinButton;
  private _isTurboSpinEnabled: boolean = false;
  protected originalScale = { x: 0.88, y: 0.88 };
  private _labelText!: Text;

  private constructor() {
    super(undefined); // Assuming no texture is passed initially
    this.setup();
  }

  public static get instance(): TurboSpinButton {
    if (!TurboSpinButton._instance) {
      TurboSpinButton._instance = new TurboSpinButton();
    }
    return TurboSpinButton._instance;
  }

  public get isTurboSpinEnabled(): boolean {
    return this._isTurboSpinEnabled;
  }

  public get labelText(): Text {
    return this._labelText;
  }

  private setup(): void {
    this.updateSprite("button_fastplay", true);
    this.position.set(GameView.instance.width / 2 + 145, 758);
    this.scale.set(this.originalScale.x, this.originalScale.y);
    this.setupLabel();

    this.on("click", this.toggleFastPlay.bind(this));
    this.on("tap", this.toggleFastPlay.bind(this));
  }

  private toggleFastPlay(): void {
    this._isTurboSpinEnabled = !this._isTurboSpinEnabled;

    // Displaying toast message
    SpinStatusToast.instance.showSpinStatus(
      Translator.instance.getText(
        this._isTurboSpinEnabled ? "turbo_spin_enabled" : "turbo_spin_disabled"
      )
    );

    // Update the button sprite based on turbo spin state
    this.updateSprite(
      this._isTurboSpinEnabled ? "button_fastplay_active" : "button_fastplay",
      true
    );
  }

  private setupLabel(): void {
    this._labelText = new Text({
      text: Translator.instance.getText("press_quick_stop"),
      style: {
        fontSize: 15,
        fill: 0xc0c0c0, // Changed to 0xC0C0C0 (silver)
        fontFamily:
          Translator.instance.language === "en"
            ? "Times New Roman"
            : FontManager.instance.defaultFontFamily,
      },
    });
    this.labelText.resolution = 2;
    this.labelText.anchor.set(0.5, 0.5);
    this.labelText.position.y = 40;
    this.labelText.alpha = 0;
    this.addChild(this.labelText);
  }

  async showLabelText(): Promise<void> {
    await gsap
      .timeline()
      .to(this.labelText, { alpha: 1, duration: 0.3 })
      .to(this.labelText, { alpha: 0, duration: 0.3 }, 2);
  }
}
