import { RoundResponse } from "../round_handler/round_data/RoundResponse";

export class JsonFileFetcher {
  public static async fetchJsonFile(
    filePath: string
  ): Promise<RoundResponse | null> {
    try {
      const response = await fetch(filePath);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseJson = await response.json();
      //   SymbolTranslator.translateSymbols(responseJson);
      const roundResponse = new RoundResponse(responseJson);
      return roundResponse;
    } catch (error) {
      console.error(`Error fetching file: ${error}`);
      throw error;
    }
  }
}
