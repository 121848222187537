import { Container, Graphics, Sprite } from "pixi.js";
import { GameView } from "../../GameView";
import { AssetManager } from "../../common/src/utils/assets/AssetManager";
import { SoundsPreloader } from "../../sounds/SoundsPreloader";

export class JiliLogoScreen extends Container {
  private static _instance: JiliLogoScreen;
  private progressBarBackground!: Graphics;
  public progressBarFill!: Graphics;
  private barWidth: number = 200;
  private assetManagerProgress: number = 0;
  private soundsPreloaderProgress: number = 0;

  public static get instance(): JiliLogoScreen {
    if (!JiliLogoScreen._instance) {
      JiliLogoScreen._instance = new JiliLogoScreen();
    }
    return JiliLogoScreen._instance;
  }

  constructor() {
    super();
    this.label = "JILI_LOGO_SCREEN";
    this.moveToCenter();
    this.initializeLogo();
    this.initializeProgressBar();
    // Add event listeners for progress
    this.listenToAssetManagerProgress();
    this.listenToSoundsPreloaderProgress();
  }

  public updateProgress(): void {
    const totalProgress =
      this.assetManagerProgress * 0.5 + this.soundsPreloaderProgress * 0.5;
    const width = this.barWidth * totalProgress;
    this.progressBarFill.clear().rect(0, 0, width, 5).fill(0xdbb14c);
  }

  private initializeLogo(): void {
    const logo = Sprite.from("logo_jili");
    logo.label = "JILI_LOGO";
    logo.anchor.set(0.5);

    this.addChild(logo);
  }

  private initializeProgressBar(): void {
    const progressBarGroup = new Container();

    progressBarGroup.label = "PROGRESS_BAR_GROUP";
    progressBarGroup.pivot.set(100, 0);
    progressBarGroup.position.set(0, 80);
    this.addChild(progressBarGroup);

    const fillColor = 0xdbb14c;
    const lightGrayColor = 0x2d261c;

    this.progressBarBackground = new Graphics()
      .rect(0, 0, this.barWidth, 5)
      .fill(lightGrayColor);
    this.progressBarBackground.label = "PROGRESS_BAR";
    const progressBarContainer = new Container();
    progressBarContainer.addChild(this.progressBarBackground);
    progressBarGroup.addChild(progressBarContainer);

    this.progressBarFill = new Graphics();
    this.progressBarFill.label = "PROGRESS_BAR_MASK";
    progressBarContainer.addChild(this.progressBarFill);
  }

  private listenToAssetManagerProgress(): void {
    AssetManager.instance.on("jiliLogoProgress", (progress: number) => {
      this.assetManagerProgress = progress;
      this.updateProgress();
    });
  }

  private listenToSoundsPreloaderProgress(): void {
    SoundsPreloader.instance.on("soundsProgress", (progress: number) => {
      this.soundsPreloaderProgress = progress;
      this.updateProgress();
    });
  }

  public destroy(): void {
    // Remove the event listeners when the JiliLogoScreen is destroyed
    AssetManager.instance.off("jiliLogoProgress", this.updateProgress);
    SoundsPreloader.instance.off("soundsProgress", this.updateProgress);
    super.destroy();
  }

  moveToCenter() {
    const { width, height } = GameView.instance;
    this.position.set(width / 2, height / 2);
  }
}
