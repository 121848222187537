import { Cascade } from "./Cascade";

export class Spin {
  totalWin: number;
  isTriggerFreeGame: boolean;
  cascades: Cascade[];

  constructor(data: any) {
    this.totalWin = data.totalWin;
    this.isTriggerFreeGame = data.isTriggerFreeGame;
    this.cascades = data.cascades.map((cascade: any) => new Cascade(cascade));
  }
}
