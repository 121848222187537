import {
  BackgroundLoader,
  Container,
  DestroyOptions,
  Graphics,
  Sprite,
  Text,
} from "pixi.js";
import { gsap } from "gsap";
import { DemoPlayButton } from "./DemoPlayButton";
import { SplashCheckbox } from "./SplashCheckbox";
import { ControlPanel } from "../../control_panel/ControlPanel";
import { PlayScreen } from "../PlayScreen";
import { BackgroundMusicManager } from "../../../sounds/BackgroundMusicManager";
import { SplashWildsPage } from "./SplashWildsPage";
import { SplashMultiplierPage } from "./SplashMultiplierPage";
import { GameView } from "../../../GameView";
import { ReelSet } from "../../reelset/ReelSet";
import { Button } from "../../../ui/components/Button";
import { LayerManager } from "../../../utils/layers/LayerManager";
import { LayersConfig } from "../../../utils/layers/LayersConfig";
import { Translator } from "../../../common/src/utils/translation/Translator";
import { VolatilityBar } from "./VolatilityBar";
import { DemoRoundHandler } from "../../../round_handler/round_handler/DemoRoundHandler";
import { SoundEffectManager } from "../../../sounds/SoundEffectManager";
import { SOUNDS } from "../../../sounds/Sounds";
import { FontFamily, FontManager } from "../../../common/src/utils/FontManager";

// components
import { ProgressBar } from "../../../ui/components/ProgressBar";
import { AssetManager } from "../../../common/src/utils/assets/AssetManager";
import { SplashGallery } from "./SplashGallery";
import { SoundsPreloader } from "../../../sounds/SoundsPreloader";

export class Splash extends Container {
  private static _instance: Splash;
  public continueButton!: Button;
  private xPosition = 0;
  private pages!: Container[];
  private content!: Container;
  private indicators: Sprite[] = [];
  private slideButtons: Sprite[] = [];
  private isMovement = false;
  public demoPlayButton!: DemoPlayButton;

  private progressBar!: ProgressBar;

  private constructor() {
    super();

    this.initializeBackground();
    this.initializeProgressBar();
    this.initializeLogo();
    this.initializePages();
    this.initializeButton();
    // this.initializeLoadingText();
    this.initializeCheckbox();
    // this.initializeControls();
    // this.initializeIndicators();
    this.initializeVolatility();
    this.initDemoPlayButton();
    // this.setupMovement();

    this.updateProgress = this.updateProgress.bind(this);

    this.setEvents();
  }

  public static get instance(): Splash {
    if (!Splash._instance) {
      Splash._instance = new Splash();
    }
    return Splash._instance;
  }

  private initializeBackground(): void {
    const background = Sprite.from("bg");
    this.zIndex = 10000;
    background.anchor.set(0.5);
    background.position.set(
      GameView.instance.width / 2,
      GameView.instance.height / 2
    );
    background.scale.set(1.5);
    this.addChild(background);
  }

  private initializeProgressBar(): void {
    this.progressBar = new ProgressBar();

    this.progressBar.position.set(148, 762);
    this.progressBar.scale.x = 0.9;

    this.addChild(this.progressBar);
  }

  private initializeLogo(): void {
    const logo = Sprite.from("gamelogo");
    logo.anchor.set(0.5);
    logo.position.set(243, 94);
    logo.scale.set(0.68);
    this.addChild(logo);
  }

  private initializePages(): void {
    //   this.content = new Container();
    //   this.content.width = 840;
    //   this.content.height = 300;
    //   this.addChild(this.content);
    //   this.pages = [new SplashWildsPage(), new SplashMultiplierPage()];
    //   this.content.addChild(this.pages[0]);
    //   this.content.addChild(this.pages[1]);
    //   this.pages[1].position.x = 500;

    const gallery = new SplashGallery();
    this.addChild(gallery);
  }

  private initializeButton(): void {
    this.continueButton = new Button(
      "button_green",
      Translator.instance.getText("Continue"),
      FontFamily.CORMORANT_GARAMOND
    );

    this.continueButton.text.style = {
      fill: 0xfff3cd,
      fontSize: 31,
      fontFamily:
        Translator.instance.language === "en"
          ? "Times New Roman"
          : FontFamily.CORMORANT_GARAMOND,
      fontWeight: "normal",
      dropShadow: {
        alpha: 0.5,
        blur: 2,
        distance: 0,
        angle: 0,
      },
    };

    this.continueButton.text.resolution = 2;

    this.continueButton.position.set(243, 763);
    this.continueButton.on("click", () => this.hideSplash());
    this.continueButton.on("tap", () => this.hideSplash());
    this.addChild(this.continueButton);
    this.continueButton.visible = false;
    this.createBlackBars();
    this.continueButton.sprite.scale.y = 0.7;
    this.continueButton.sprite.scale.x = 0.9;
    this.continueButton.scale.y = 0.95;
  }

  // private initializeLoadingText(): void {
  //   this.loadingText = new Text({
  //     style: {
  //       fontFamily: FontManager.instance.defaultFontFamily,
  //       fontSize: 18,
  //       fill: 0xffffff,
  //       stroke: 0x000000,
  //     },
  //   });
  //
  //   this.loadingText.visible = false;
  //
  //   this.loadingText.resolution = 2;
  //
  //   this.loadingText.anchor.set(0.5);
  //   this.loadingText.position.set(
  //     GameView.instance.width / 2,
  //     GameView.instance.height / 2 + 340
  //   );
  //   this.addChild(this.loadingText);
  // }

  public updateProgress(progress: number): void {
    // this.loadingText.text = Translator.instance.getText("Loading {value}%", {
    //   value: Math.floor(progress).toString(),
    // });

    this.progressBar.progress = progress;
  }

  private createBlackBars(): void {
    for (let i = 0; i < 2; ++i) {
      const rect = new Graphics()
        .rect(
          i === 0 ? -1000 : GameView.instance.width,
          0,
          1000,
          GameView.instance.height
        )
        .fill(0x000000);

      LayerManager.instance
        .getLayer(LayersConfig.BASE_LAYERS.BLACK_BARS)
        .addChild(rect);
    }
  }

  private setupMovement(): void {
    this.on("pointerdown", () => (this.isMovement = true));
    this.on("pointerup", () => this.onMovementEnd());
    this.on("pointerupoutside", () => this.onMovementEnd());
    this.on("pointermove", (e) => this.onMovement(e));
  }

  private onMovement(e: any): void {
    if (!this.isMovement) return;

    this.xPosition += e.movement.x;
    this.content.position.x = this.xPosition;
  }

  private onMovementEnd(): void {
    this.isMovement = false;
    gsap.to(this, {
      xPosition: this.xPosition < -250 ? -500 : 0,
      duration: 0.4,
      ease: "power3.out",
      onUpdate: () => {
        this.content.position.x = this.xPosition;
      },
    });
    this.snapToPage(this.xPosition < -250 ? 1 : 0);
  }

  private snapToPage(pageID: number): void {
    gsap.to(this, {
      xPosition: pageID === 1 ? -500 : 0,
      duration: 0.4,
      ease: "power3.out",
      onUpdate: () => {
        this.content.position.x = this.xPosition;
      },
    });

    this.indicators[pageID].tint = 0xffffff;
    this.indicators[pageID === 1 ? 0 : 1].tint = 0x888888;

    this.slideButtons[pageID].visible = false;
    this.slideButtons[pageID === 1 ? 0 : 1].visible = true;
  }

  private initializeControls(): void {
    for (let i = 0; i < 2; ++i) {
      const slideButton = Sprite.from("button_slide");
      slideButton.anchor.set(0.5);
      slideButton.position.set(
        GameView.instance.width / 2 + (i === 0 ? -222 : 222),
        GameView.instance.height / 2 - 48
      );
      slideButton.scale.set(0.5);
      if (i === 0) {
        slideButton.rotation = Math.PI;
      }
      slideButton.interactive = true;
      slideButton.on("click", () => this.snapToPage(i));
      slideButton.on("tap", () => this.snapToPage(i));
      this.addChild(slideButton);

      this.slideButtons.push(slideButton);
    }

    this.slideButtons[0].visible = false;
  }

  private initializeIndicators(): void {
    for (let i = 0; i < 2; ++i) {
      const indicator = Sprite.from("indicator");
      indicator.anchor.set(0.5);
      indicator.scale.set(0.75);
      indicator.position.set(
        GameView.instance.width / 2 + (i * 15 - 10),
        GameView.instance.height / 2 + 135
      );
      this.addChild(indicator);

      this.indicators.push(indicator);
    }

    this.indicators[1].tint = 0x888888;
  }

  private initializeCheckbox(): void {
    const checkbox = new SplashCheckbox(
      false,
      Translator.instance.getText("Don't show next time")
    );
    checkbox.scale.set(0.7);
    this.addChild(checkbox);
    checkbox.position.set(148, 827);

    // Increase the font size of the checkbox text
    if (checkbox.text) {
      checkbox.text.style.fontSize = 28; // Adjust this value as needed
      checkbox.text.style.fontFamily = FontFamily.MILFORD;
      checkbox.text.resolution = 2;
    }
  }

  private initializeVolatility(): void {
    const volatilityText = new Text({
      text: Translator.instance.getText("volatility"),
      style: {
        fontSize: 16,
        fill: 0xd0d0d0,
        fontFamily: FontManager.instance.defaultFontFamily,
        //make bold
        // fontWeight: "bold",
      },
    });
    volatilityText.resolution = 1;
    volatilityText.anchor.set(1, 0.5);
    const xPosOffset = Translator.instance.language === "en" ? 0 : 16;
    volatilityText.position.set(201 + xPosOffset, 162);
    this.addChild(volatilityText);

    const volatilityBar = new VolatilityBar(2, 0.25);
    volatilityBar.position.set(229, 161);
    volatilityBar.scale.set(0.57);
    this.addChild(volatilityBar);
  }

  private initDemoPlayButton(): void {
    this.demoPlayButton = new DemoPlayButton();
    this.addChild(this.demoPlayButton);
    this.demoPlayButton.position.set(245, 208);
    this.demoPlayButton.setOriginalScale(0.85);
    this.demoPlayButton.scale.set(0.77);
    this.demoPlayButton.on("click", () => this.onDemoPlayButtonClick());
    this.demoPlayButton.on("tap", () => this.onDemoPlayButtonClick());
    this.demoPlayButton.visible = false;
  }

  private async hideSplash(): Promise<void> {
    SoundEffectManager.instance.preloadSounds();
    // await SoundsPreloader.instance.waitForSounds();

    // LayerManager.instance
    //   .getLayer(LayersConfig.BASE_LAYERS.SCREENS)
    //   .addChild(PlayScreen.instance);

    this.pivot.set(this.width / 4, this.height / 4);
    this.position.set(this.width / 4, this.height / 4);

    await Promise.allSettled([
      gsap.to(this.scale, {
        x: 1.5,
        y: 1.5,
        duration: 0.4,
        ease: "power1.out",
      }),
      gsap.to(this, {
        alpha: 0,
        duration: 0.4,
        ease: "power1.out",
      }),
    ]);

    this.destroy();
  }

  private async onDemoPlayButtonClick(): Promise<void> {
    await this.hideSplash();
    PlayScreen.instance.visible = false;
    await ReelSet.instance.doStartScreenAnimation();
    ControlPanel.instance.showAll();
    DemoRoundHandler.instance.handleRound();
  }

  // lifecycle
  public async finishLoading(): Promise<void> {
    this.progressBar.progress = 100;
    await this.progressBar.revealButton(this.continueButton.height);
    this.progressBar.hideButton();
    this.continueButton.visible = true;
    this.demoPlayButton.visible = true;

    LayerManager.instance
      .getLayer(LayersConfig.BASE_LAYERS.SCREENS)
      .addChild(PlayScreen.instance);
  }

  // decoration
  public destroy(options?: DestroyOptions) {
    this.removeEvents();

    return super.destroy(options);
  }

  // events
  private setEvents(): void {
    AssetManager.instance.on("splashProgress", this.updateProgress);
  }

  private removeEvents(): void {
    AssetManager.instance.on("splashProgress", this.updateProgress);
  }
}
