export class JwtQueryStringHandler {
  static getJwtFromURL(): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("j");
  }

  static removeJwtFromURL(): void {
    const url = new URL(window.location.href);
    url.searchParams.delete("j");
    window.history.replaceState({}, document.title, url.toString());
  }
}
