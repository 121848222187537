import { Assets, BitmapFont } from "pixi.js";

export class BitmapTextAssetManager {
  private static _instance: BitmapTextAssetManager;
  private static readonly FONTS_FOLDER = "bitmapfonts";
  private loadedFonts: Set<string> = new Set();

  private constructor() {}

  public static get instance(): BitmapTextAssetManager {
    if (!BitmapTextAssetManager._instance) {
      BitmapTextAssetManager._instance = new BitmapTextAssetManager();
    }
    return BitmapTextAssetManager._instance;
  }

  public async loadBitmapFonts(): Promise<void> {
    try {
      const fontNames = [
        "blue_bm",
        "big_win_bm",
        "combo_bm",
        "fs_bm",
        "orange_bm",
        "fsc_bm",
        "win_bm",
        "big_counter",
      ]; // Add all your font names here

      for (const fontName of fontNames) {
        const fontPath = `${BitmapTextAssetManager.FONTS_FOLDER}/${fontName}.xml`;

        // Load the font using Pixi's Assets loader
        await Assets.load(fontPath);

        this.loadedFonts.add(fontName);
      }
    } catch (error) {
      console.error("Error loading bitmap fonts:", error);
    }
  }

  public isFontLoaded(fontName: string): boolean {
    return this.loadedFonts.has(fontName);
  }

  public getLoadedFonts(): string[] {
    return Array.from(this.loadedFonts);
  }
}
