import { TextStyle } from "pixi.js";
import { Button } from "../../../ui/components/Button";
import { Translator } from "../../../common/src/utils/translation/Translator";
import { FontFamily, FontManager } from "../../../common/src/utils/FontManager";

export class DemoPlayButton extends Button {
  constructor() {
    super(
      "button_feature",
      Translator.instance.getText("feature_demo_playing")
    );

    this.text.resolution = 1;
    this.text.x = this.text.x - this.sprite.width / 2;
    this.text.alpha = 0.9;

    const optionalDropShadowConfig =
      Translator.instance.language === "en"
        ? ({
            dropShadow: {
              alpha: 0.5,
              angle: 45,
              distance: 1.5,
            },
          } as TextStyle)
        : {};

    this.text.style = {
      fontFamily: FontFamily.HELVETICA,
      fontWeight: "bold",
      fill: 0xf09246,
      fontSize: Translator.instance.language === "th" ? 26 : 21,
      ...optionalDropShadowConfig,
    };

    this.sprite.x =
      this.text.x + this.text.width / 2 + this.sprite.width / 2 + 5;
  }
}
