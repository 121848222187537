import { gsap } from "gsap";

import { AutoplayManager } from "./AutoplayManager";
import { AutoplaySettingsWindow } from "../settings_button/autoplay_settings_button/AutoplaySettingsWindow";

import { RoundHandler } from "../../../round_handler/round_handler/RoundHandler";
import { ControlPanel } from "../ControlPanel";
import { Sprite, Text } from "pixi.js";
import { GameView } from "../../../GameView";
import { Button } from "../../../ui/components/Button";
import { SpinStatusToast } from "../../reelset/SpinStatusToast";
import { Translator } from "../../../common/src/utils/translation/Translator";

import { Delay } from "../../../common/src/utils/Delay";
import { TurboSpinButton } from "../TurboSpinButton";
import { FontManager } from "../../../common/src/utils/FontManager";

export class AutoPlayButton extends Button {
  private static _instance: AutoPlayButton;
  private light: Sprite;
  private longPressTimeout: number | null = null;
  private isEnabled = true;
  private labelText: Text;
  protected originalScale = { x: 0.88, y: 0.88 };
  private _holdForSettingsText: string;
  private _tapForAutoplayText: string;

  private constructor() {
    super("button_autoplay", "");
    this.light = Sprite.from("light_ring");
    this._holdForSettingsText = Translator.instance.getText("hold_for_setting");
    this._tapForAutoplayText = Translator.instance.getText("tap_to_autoplay");
    this.labelText = new Text({
      text: Translator.instance.getText("hold_for_setting"),
      style: {
        fontSize: 16,
        fill: 0xc0c0c0, // Changed to 0xC0C0C0 (silver)
        fontFamily:
          Translator.instance.language === "en"
            ? "Times New Roman"
            : FontManager.instance.defaultFontFamily,
      },
    });
    this.labelText.resolution = 2;

    this.setup();
  }

  static get instance(): AutoPlayButton {
    if (!AutoPlayButton._instance) {
      AutoPlayButton._instance = new AutoPlayButton();
    }
    return AutoPlayButton._instance;
  }

  public stopAutoplay(isShowToast: boolean = true): void {
    this.updateTexture(
      this.isEnabled ? "button_autoplay" : "button_autoplay_disabled"
    );
    this.text.text = "";
    if (isShowToast) {
      SpinStatusToast.instance.showSpinStatus(
        Translator.instance.getText("auto_spin_disabled")
      );
    }
  }

  public disable(): void {
    this.isEnabled = false;
    this.updateTexture(
      AutoplayManager.instance.isAutoPlaying
        ? "button_autoplay_stop_disabled"
        : "button_autoplay_disabled"
    );
  }

  public enable(): void {
    this.isEnabled = true;
    this.updateTexture(
      AutoplayManager.instance.isAutoPlaying
        ? "button_autoplay_stop"
        : "button_autoplay"
    );
  }

  public startAutoplay(): void {
    SpinStatusToast.instance.showSpinStatus(
      Translator.instance.getText("auto_spin_enabled")
    );
    this.updateTexture(
      this.isEnabled ? "button_autoplay_stop" : "button_autoplay_stop_disabled"
    );
  }

  private setup(): void {
    const x = GameView.instance.width / 2 + 90;
    const y = 758;
    this.position.set(x, y);
    this.scale.set(this.originalScale.x, this.originalScale.y);
    this.setupLight();
    this.setupLabel();
    this.setupText();
    this.setupIdleAnimation();
  }

  private setupLabel(): void {
    this.labelText.anchor.set(0.5, 0.5);
    this.labelText.position.y = 37;
    this.labelText.x = 1;
    this.labelText.alpha = 0;
    this.addChild(this.labelText);
  }

  private setupText() {
    this.text.style.fontSize = 16;
    this.text.style.fill = 0x000000;
  }

  protected override onPointerDown(): void {
    if (
      AutoplayManager.instance.isAutoPlaying ||
      !RoundHandler.instance.isSpinInProgress
    ) {
      super.onPointerDown();
      this.longPressTimeout = window.setTimeout(() => {
        this.handleLongPress();
      }, 1000);
    }
  }

  protected override onPointerUp(): void {
    super.onPointerUp();
    if (this.longPressTimeout) {
      clearTimeout(this.longPressTimeout);
      this.longPressTimeout = null;
      this.toggleAutoplay();
    }
    ControlPanel.instance.hideSetttings();
  }

  private handleLongPress(): void {
    AutoplaySettingsWindow.instance.show();
    ControlPanel.instance.hideSetttings();
    this.longPressTimeout = null;
  }

  private toggleAutoplay(): void {
    if (AutoplayManager.instance.isAutoPlaying) {
      AutoplayManager.instance.stopAutoplay();
    } else {
      AutoplayManager.instance.startAutoplay();
    }
  }

  private setupLight(): void {
    this.light.anchor.set(0.5);
    this.light.tint = 0xffff77;
    this.light.scale.set(0.6);
    this.addChild(this.light);
  }

  private async setupIdleAnimation(): Promise<void> {
    while (true) {
      const isAutoplayEnabled = AutoplayManager.instance.isAutoPlaying;

      if (!isAutoplayEnabled && this.isEnabled) {
        this.replaceLabelText();
        await gsap
          .timeline()
          .set(this.light, { rotation: 0 })
          .to([this.light, this.labelText], { alpha: 1, duration: 0.3 })
          .to(this.light, { rotation: -41, duration: 2.5, ease: "none" })
          .to([this.labelText, this.light], { alpha: 0, duration: 0.3 });
        this.replaceLabelText();
        await gsap
          .timeline()
          .set(this.light, { rotation: 0 })
          .to([this.light, this.labelText], { alpha: 1, duration: 0.3 })
          .to(this.light, { rotation: -41, duration: 2.5, ease: "none" })
          .to([this.labelText, this.light], { alpha: 0, duration: 0.3 });
        await Delay.delay(1000);
        await gsap
          .timeline()
          .to(TurboSpinButton.instance.labelText, { alpha: 1, duration: 0.3 })
          .to(
            TurboSpinButton.instance.labelText,
            { alpha: 0, duration: 0.3 },
            "+=2.5"
          );
      }
      await Delay.delay(1000);
    }
  }

  private replaceLabelText(): void {
    if (this.labelText.text === this._holdForSettingsText) {
      this.labelText.text = this._tapForAutoplayText;
    } else {
      this.labelText.text = this._holdForSettingsText;
    }
  }

  private updateTexture(textureName: string): void {
    this.updateSprite(textureName, true);
  }
}
