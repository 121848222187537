export class WinningCombination {
  reelPositions: { [key: number]: number };
  symbol: string;
  winAmount: number;

  constructor(data: any) {
    this.reelPositions = data.reelPositions;
    this.symbol = data.symbol;
    this.winAmount = data.winAmount;
  }
}
