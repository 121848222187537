import { Howl } from "howler";
import { SOUNDS } from "./Sounds";
import { Config } from "../config/Config";
import { Delay } from "../common/src/utils/Delay";
import { SoundManager } from "./SoundManager";

export class SoundEffectManager {
  private static _instance: SoundEffectManager | null = null;
  private sounds: Map<string, Howl> = new Map();

  private constructor() {}

  public static get instance(): SoundEffectManager {
    if (!SoundEffectManager._instance) {
      SoundEffectManager._instance = new SoundEffectManager();
    }
    return SoundEffectManager._instance;
  }

  public async preLoadButtonClickSound(): Promise<void> {
    await this.loadSound(SOUNDS.BUTTON_CLICK);
  }

  public async preloadSounds(): Promise<void> {
    const soundNames = Object.keys(SOUNDS);
    await Promise.all(soundNames.map((sound) => this.loadSound(SOUNDS[sound])));
  }

  public async playWinDetails(
    winningSymbols: string[],
    multiplierValue: number
  ): Promise<void> {
    try {
      const symbolSounds =
        winningSymbols.length <= 2
          ? winningSymbols.map(
              (symbol) => SOUNDS[`WIN_${symbol.toUpperCase()}`]
            )
          : [SOUNDS.WIN_LUCKY];

      let isFirstSymbolSound = true;
      for (const symbolSound of symbolSounds) {
        this.playSound(symbolSound, false);
        if (symbolSounds.length == 2 && isFirstSymbolSound) {
          await Delay.delay(400);
          isFirstSymbolSound = false;
        }
      }
      await Delay.delay(600);
      if (multiplierValue > 1) {
        this.playSound(SOUNDS[`WIN_${multiplierValue}`], false);
      }
    } catch (error) {
      console.error("Error playing win details sound:", error);
    }
  }

  public playMultiplierAnimationSound(id: number): void {
    this.playSound(SOUNDS[`COMBO_SHINE_${id}`]);
  }

  private async loadSound(url: string): Promise<void> {
    return new Promise((resolve) => {
      const sound = new Howl({
        src: [Config.assetRoot + Config.soundRoot + url],
        html5: true,
        preload: true,
        onload: () => {
          this.sounds.set(url, sound);
          resolve();
        },
      });
    });
  }

  public playSound(sound: string, loop: boolean = false): void {
    if (!SoundManager.instance.isPlaySounds) return;

    SoundManager.instance.initAudio();

    const howl = this.sounds.get(sound);
    if (!howl) {
      console.error("Sound not loaded:", sound);
      return;
    }

    if (howl.state() !== "loaded") {
      howl.once("load", () => {
        this.playSoundInternal(howl, loop, 1);
      });
    } else {
      this.playSoundInternal(howl, loop, 1);
    }
  }

  private playSoundInternal(howl: Howl, loop: boolean, volume: number): void {
    howl.loop(loop);
    howl.volume(volume);
    howl.play();
  }

  public async playReelStartScreenAnimation(): Promise<void> {
    for (let i = 0; i < 4; ++i) {
      await Delay.delay(33 * 9);
      this.playSound(SOUNDS[`COMBO_SHINE_${i}`]);
    }
  }
}
