// RoundHandler.ts

import { GameApiCaller } from "../../backend/GameApiCaller";
import { JwtStorageManager } from "../../common/src/utils/jwt/JwtStorageManager";
import { BetAmountSelector } from "../../game/control_panel/bet_amount_selector_button/BetAmountSelector";
import { RoundResponse } from "../round_data/RoundResponse";
import {
  MultiplierPanel,
  MultiplierType,
} from "../../game/header/MultiplierPanel";
import { WinAmountDisplay } from "../../game/control_panel/WinAmountDisplay";
import { BetAmountSelectorButton } from "../../game/control_panel/bet_amount_selector_button/BetAmountSelectorButton";
import { RegularSpinHandler } from "./RegularSpinHandler";
import { FreeSpinHandler } from "./FreeSpinHandler";
import { BalanceDisplay } from "../../game/control_panel/BalanceDisplay";
import { SpinButton } from "../../game/control_panel/SpinButton";
import { Config } from "../../config/Config";
import { TransactionDisplay } from "../../game/control_panel/TransactionDisplay";
import { JsonFileFetcher } from "../../backend/JsonFileFetcher";
import { AutoplayManager } from "../../game/control_panel/auto_play_button/AutoplayManager";
import { ErrorMessageModal } from "../../game/popups/ErrorMessageModal";
import { AutoPlayButton } from "../../game/control_panel/auto_play_button/AutoPlayButton";
import { BuyBonusManager } from "../../game/header/buy_bonus/BuyBonusManager";
import { BuyBonusButton } from "../../game/header/buy_bonus/BuyBonusButton";
import Decimal from "decimal.js";
import { Translator } from "../../common/src/utils/translation/Translator";
import { ResponseFilesDropdown } from "../../common/src/utils/ResponseFilesDropdown";
import { ReelSet } from "../../game/reelset/ReelSet";

export class RoundHandler {
  private static _instance: RoundHandler;
  public isSpinInProgress = false;
  public isTriggerFreeGame = false;
  public regularSpinHandler: RegularSpinHandler;
  public freeSpinHandler: FreeSpinHandler;
  private constructor() {
    this.regularSpinHandler = RegularSpinHandler.instance;
    this.freeSpinHandler = FreeSpinHandler.instance;
  }

  public static get instance(): RoundHandler {
    if (!RoundHandler._instance) {
      RoundHandler._instance = new RoundHandler();
    }
    return RoundHandler._instance;
  }

  public handleRoundStart(): void {
    this.isSpinInProgress = true;
    this.disableControlPanelButtons();
  }

  private checkIfSufficientBalance(): boolean {
    if (
      Number(BetAmountSelector.instance.betAmount) >
      BalanceDisplay.instance.balance
    ) {
      ErrorMessageModal.instance.showModal(
        Translator.instance.getText("insufficient_balance")
      );
      AutoplayManager.instance.stopAutoplay();
      return false;
    }

    return true;
  }

  public async handleRound(): Promise<void> {
    try {
      if (this.isSpinInProgress) return;
      if (!this.checkIfSufficientBalance()) return;

      this.handleRoundStart();

      let roundResponse: RoundResponse | null = await this.fetchRoundData();

      if (roundResponse) {
        await this.handleRoundResponse(roundResponse);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Error ${error.message}:`, error.stack);
      } else {
        console.error("Unexpected error:", error);
      }
    }

    this.handleRoundEnd();
  }

  public handleRoundEnd() {
    this.isSpinInProgress = false;
    this.isTriggerFreeGame = false;
    this.enableControlPanelButtons();
  }

  private async fetchRoundData(): Promise<RoundResponse | null> {
    if (ResponseFilesDropdown.instance.getSelectedValue() != "ApiCall") {
      return await JsonFileFetcher.fetchJsonFile(
        Config.assetRoot +
          "round_response/" +
          ResponseFilesDropdown.instance.getSelectedValue()
      );
    } else {
      let betAmount = BetAmountSelector.instance.betAmount;
      let isBuyBonusRound = 0;

      if (BuyBonusManager.instance.isBuyBonusInProgress) {
        betAmount = new Decimal(BuyBonusManager.instance.betAmount);
        isBuyBonusRound = 1;
        BuyBonusManager.instance.quantity -= 1;
        BuyBonusButton.instance.updateQuantityText(
          BuyBonusManager.instance.quantity
        );
      }

      BalanceDisplay.instance.updateBalance(
        Number(BalanceDisplay.instance.balance) - Number(betAmount)
      );

      const data = `${JwtStorageManager.getJwt()}&${betAmount}&${
        Number(BalanceDisplay.instance.balance) + Number(betAmount)
      }&${isBuyBonusRound}`;

      return await GameApiCaller.callApiFunction("round", data);
    }
  }

  private async handleRoundResponse(
    roundResponse: RoundResponse
  ): Promise<void> {
    TransactionDisplay.instance.updateRoundIdText(roundResponse.roundId);
    const firstSpin = roundResponse.round.spins[0];
    WinAmountDisplay.instance.resetWinAmount();
    MultiplierPanel.instance.changeMultiplierType(MultiplierType.Base);

    if (firstSpin.isTriggerFreeGame) {
      this.isTriggerFreeGame = true;
      await this.freeSpinHandler.handleFreeSpin(roundResponse.round);
    } else {
      await this.regularSpinHandler.handleRegularSpin(firstSpin);
    }

    BalanceDisplay.instance.updateBalance(roundResponse.balance);
    ReelSet.instance.destroySymbols();
  }

  private disableControlPanelButtons(): void {
    AutoPlayButton.instance.disable();
    BetAmountSelectorButton.instance.disable();
    SpinButton.instance.disable();
  }

  private enableControlPanelButtons(): void {
    AutoPlayButton.instance.enable();
    BetAmountSelectorButton.instance.enable();
    SpinButton.instance.enable();
  }
}
