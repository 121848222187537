// RoundHandler.ts
import { BetAmountSelector } from "../../game/control_panel/bet_amount_selector_button/BetAmountSelector";
import { RoundResponse } from "../round_data/RoundResponse";
import {
  MultiplierPanel,
  MultiplierType,
} from "../../game/header/MultiplierPanel";
import { WinAmountDisplay } from "../../game/control_panel/WinAmountDisplay";
import { BetAmountSelectorButton } from "../../game/control_panel/bet_amount_selector_button/BetAmountSelectorButton";
import { RegularSpinHandler } from "./RegularSpinHandler";
import { FreeSpinHandler } from "./FreeSpinHandler";
import { BalanceDisplay } from "../../game/control_panel/BalanceDisplay";
import { SpinButton } from "../../game/control_panel/SpinButton";
import { SpinStatusToast } from "../../game/reelset/SpinStatusToast";
import { Config } from "../../config/Config";
import { TransactionDisplay } from "../../game/control_panel/TransactionDisplay";
import { JsonFileFetcher } from "../../backend/JsonFileFetcher";
import { AutoPlayButton } from "../../game/control_panel/auto_play_button/AutoPlayButton";
import { Translator } from "../../common/src/utils/translation/Translator";
import { BuyBonusButton } from "../../game/header/buy_bonus/BuyBonusButton";
import { RoundHandler } from "./RoundHandler";
import { SettingsButton } from "../../game/control_panel/settings_button/SettingsButton";

export class DemoRoundHandler {
  private static _instance: DemoRoundHandler;

  public freeSpinHandler: FreeSpinHandler = FreeSpinHandler.instance;

  private constructor() {}

  public static get instance(): DemoRoundHandler {
    if (!DemoRoundHandler._instance) {
      DemoRoundHandler._instance = new DemoRoundHandler();
    }
    return DemoRoundHandler._instance;
  }

  public handleRoundStart(): void {
    RoundHandler.instance.isSpinInProgress = true;
    this.handleDemoRoundStart();
    RoundHandler.instance.handleRoundStart();
    SettingsButton.instance.visible = false;
  }

  public async handleRound(isDemo = false): Promise<void> {
    try {
      this.handleRoundStart();
      let roundResponse: RoundResponse | null = await this.fetchRoundData();
      await this.handleRoundResponse(roundResponse!);
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Error ${error.message}:`, error.stack);
      } else {
        console.error("Unexpected error:", error);
      }
    }

    this.handleRoundEnd(isDemo);
  }

  private handleRoundEnd(isDemo: boolean) {
    this.handleDemoRoundEnd();
    RoundHandler.instance.isSpinInProgress = false;
    RoundHandler.instance.handleRoundEnd();
    SettingsButton.instance.visible = true;
  }

  private async fetchRoundData(): Promise<RoundResponse | null> {
    return await JsonFileFetcher.fetchJsonFile(
      Config.assetRoot + "round_response/demo.json"
    );
  }

  private async handleRoundResponse(
    roundResponse: RoundResponse
  ): Promise<void> {
    TransactionDisplay.instance.updateRoundIdText(roundResponse.roundId);
    WinAmountDisplay.instance.resetWinAmount();
    MultiplierPanel.instance.changeMultiplierType(MultiplierType.Base);
    RoundHandler.instance.isTriggerFreeGame = true;
    await this.freeSpinHandler.handleFreeSpin(roundResponse.round);
  }

  private handleDemoRoundStart() {
    BalanceDisplay.instance.startDemoMode();
    BetAmountSelector.instance.startDemoMode();
    WinAmountDisplay.instance.startDemoMode();
    SpinStatusToast.instance.showSpinStatus(
      Translator.instance.getText("feature_demo_playing")
    );
    BuyBonusButton.instance.visible = false;
  }

  private handleDemoRoundEnd() {
    BalanceDisplay.instance.endDemoMode();
    BetAmountSelector.instance.endDemoMode();
    WinAmountDisplay.instance.endDemoMode();
    SpinButton.instance.enable();
    BuyBonusButton.instance.visible = true;
  }
}
