import { BaseSymbol } from "./BaseSymbol";
import { Sprite, Texture } from "pixi.js";
import gsap from "gsap";
import { SymbolType } from "./SymbolType";

export class CardSymbol extends BaseSymbol {
  cardBackgroundSprite!: Sprite;
  cardSymbolSprite!: Sprite;
  cardFrameSprite!: Sprite;
  cardShineSprite!: Sprite;
  cardFrameShineSprite!: Sprite;
  cardBackShineLargeSprite!: Sprite;
  protected _cardSymbolScale = { x: 0.75, y: 0.75 };

  constructor(
    symbolIndex: number,
    reelIndex: number,
    symbolValue: SymbolType = SymbolType.Spade,
    isFaceDown: boolean = false
  ) {
    super(symbolIndex, reelIndex, symbolValue, isFaceDown);
    this.createCardSymbol();
  }

  public get cardSymbolScale() {
    return this._cardSymbolScale;
  }

  private createCardSymbol(): void {
    this.cardBackgroundSprite = Sprite.from("card_bg");
    this.cardBackgroundSprite.zIndex = 10;
    this.cardBackgroundSprite.anchor.set(0.5, 0.5);
    this.cardBackgroundSprite.label = `CARD_BG`;

    this.cardSymbolSprite = Sprite.from(
      "card_fill_" + this.symbolType.replace("g", "").replace("O", "")
    );
    this.cardSymbolSprite.zIndex = 30;
    this.cardSymbolSprite.anchor.set(0.5, 0.5);
    this.cardSymbolSprite.scale.set(1, 1);
    this.cardSymbolSprite.label = `CARD_SYMBOL`;

    this.cardFrameSprite = new Sprite();
    this.cardFrameSprite.zIndex = 20;
    this.cardFrameSprite.anchor.set(0.5, 0.5);
    this.cardFrameSprite.label = `CARD_FRAME`;

    this.cardShineSprite = Sprite.from("card_shine");
    this.cardShineSprite.anchor.set(0.5, 0.5);
    this.cardShineSprite.scale.set(2.3, 2.3);
    this.cardShineSprite.alpha = 0;
    this.cardShineSprite.tint = 0xffff77; // Gold tint
    this.cardShineSprite.zIndex = 5;
    this.cardShineSprite.label = `CARD_SHINE`;

    this.cardFrameShineSprite = Sprite.from("card_frame_shine");
    this.cardFrameShineSprite.anchor.set(0.5, 0.5);
    this.cardFrameShineSprite.scale.set(1.9, 1.9);
    this.cardFrameShineSprite.tint = 0xffff77; // Gold tint
    this.cardFrameShineSprite.alpha = 0;
    this.cardFrameShineSprite.blendMode = "add";
    this.cardFrameShineSprite.zIndex = 60;
    this.cardFrameShineSprite.label = `CARD_FRAME_SHINE`;

    this.cardBackShineLargeSprite = Sprite.from("shine_large");
    this.cardBackShineLargeSprite.anchor.set(0.5);
    this.cardBackShineLargeSprite.zIndex = 5;
    this.cardBackShineLargeSprite.alpha = 0;
    this.cardBackShineLargeSprite.label = `CARD_BACK_SHINE_LARGE`;

    this.container.addChild(
      this.cardBackgroundSprite,
      this.cardSymbolSprite,
      this.cardFrameSprite,
      this.cardShineSprite,
      this.cardFrameShineSprite,
      this.cardBackShineLargeSprite
    );

    this.container.scale.set(this._cardSymbolScale.x, this._cardSymbolScale.y);
  }

  // Update pulse animation to be quicker
  public performPulseAnimation(): gsap.core.Tween {
    const scaleFactor = 1.1; // 10% increase
    const duration = 0.125; // Duration for each scale animation

    return gsap.to(this.container.scale, {
      duration: duration,
      x: this._cardSymbolScale.x * scaleFactor,
      y: this._cardSymbolScale.y * scaleFactor,
      yoyo: true,
      repeat: 1,
      ease: "power2.inOut",
    });
  }

  // Update backlight animation to be quicker
  public performCardBackLightAnimation(): gsap.core.Tween {
    this.cardBackShineLargeSprite.alpha = 1;
    return gsap.to(this.cardBackShineLargeSprite, { alpha: 0, duration: 0.5 });
  }

  // Update shine animation to be quicker
  public performCardShineAnimation(): gsap.core.Timeline {
    this.cardShineSprite.alpha = 1;

    return gsap
      .timeline()
      .set(this.cardShineSprite, { alpha: 1 })
      .to(this.cardShineSprite, { alpha: 1, duration: 0.4 })
      .to(this.cardShineSprite, {
        alpha: 0,
        duration: 0.1,
        ease: "power1.out",
      });
  }

  public async performSymbolWinAnimation(): Promise<void> {
    const timeline = gsap.timeline();

    // Start backlight and shine animations
    timeline.add(this.performCardBackLightAnimation());
    timeline.add(this.performCardShineAnimation(), 0);

    // Perform 4 pulse animations in sequence
    for (let i = 0; i < 4; i++) {
      timeline.add(this.performPulseAnimation(), i * 0.25);
    }

    // If it's a face card, add rotation animation
    if (BaseSymbol.isFaceCard(this.symbolType)) {
      timeline.to(
        this.container,
        {
          duration: 0.25,
          rotation: Math.PI / 20,
          yoyo: true,
          repeat: 3,
          ease: "sine.inOut",
        },
        0
      );
    }

    // Ensure the entire animation lasts exactly 1 second
    await timeline.play();
  }

  public async performSymbolAfterWinAnimation(): Promise<void> {
    this.hideCard();
    this.cardShineSprite.visible = false;
    const sparks = new Sprite(Texture.from("sparks"));
    sparks.anchor = 0.5;
    sparks.tint = 0xffff66;
    sparks.scale.set(1, 1);
    sparks.label = `SPARKS`;
    this.container.addChild(sparks);

    const circleLight = new Sprite(Texture.from("circle_light"));
    circleLight.anchor = 0.5;
    this.container.addChild(circleLight);
    circleLight.label = `CIRCLE_LIGHT`;

    await gsap.to([sparks, circleLight], {
      duration: 1.2,
      alpha: 0,
      ease: "power3.out",
      onUpdate: () => {
        // Check if light still exists and is in the container
        if (circleLight && circleLight.parent) {
          const scale = 1 + (1 - sparks.alpha) * 2;
          circleLight.scale.set(scale, scale);
        }
      },
    });

    // Remove sprites only if they still exist and are in the container
    if (sparks && sparks.parent) {
      sparks.destroy();
    }
    if (circleLight && circleLight.parent) {
      circleLight.destroy();
    }
    this.hide();
  }

  private hideCard(): void {
    this.cardBackgroundSprite.visible = false;
    this.cardSymbolSprite.visible = false;
    this.cardFrameSprite.visible = false;
  }

  protected showCard(): void {
    this.cardBackgroundSprite.visible = true;
    this.cardSymbolSprite.visible = true;
    this.cardFrameSprite.visible = true;
  }

  public show(): void {
    this.container.visible = true;
    this.cardBackgroundSprite.visible = true;
    if (!this.isFaceDown) {
      this.cardSymbolSprite.visible = true;
      this.cardFrameSprite.visible = true;
    }
  }

  public override destroy(): void {
    // Destroy all specific sprites
    this.destroySprite(this.cardBackgroundSprite);
    this.destroySprite(this.cardSymbolSprite);
    this.destroySprite(this.cardFrameSprite);
    this.destroySprite(this.cardShineSprite);
    this.destroySprite(this.cardFrameShineSprite);
    this.destroySprite(this.cardBackShineLargeSprite);

    // Call the parent class destroy method
    super.destroy();
  }
}
