import { Container, Sprite, Text, TextStyle } from "pixi.js";
import { Button } from "../../../ui/components/Button";
import { CurrencyManager } from "../../../common/src/utils/currency/CurrencyManager";

import { Translator } from "../../../common/src/utils/translation/Translator";
import Decimal from "decimal.js";
import { FontFamily, FontManager } from "../../../common/src/utils/FontManager";
import { BetAmountText } from "./BetAmountText";

export class BetAmountSelector extends Container {
  public betAmount: Decimal = CurrencyManager.instance.getBetAmounts()[0];
  private static _instance: BetAmountSelector;
  private buttons: Button[] = [];
  private _activeButtonIndex: number = 0;
  private _betPanel!: Container;
  private betAmountText: BetAmountText;
  private currencyManager: CurrencyManager;

  private constructor() {
    super(); // Call the constructor of Container
    this.currencyManager = CurrencyManager.instance;
    this.betPanel = new Container();
    this.betPanel.label = "betPanel";
    this.betAmountText = new BetAmountText();
    this.zIndex = 200;
    this.setupBetView();
  }

  public get activeButtonIndex(): number {
    return this._activeButtonIndex;
  }
  public set activeButtonIndex(value: number) {
    this._activeButtonIndex = value;
  }

  public get betPanel(): Container {
    return this._betPanel;
  }
  public set betPanel(value: Container) {
    this._betPanel = value;
  }

  public static get instance(): BetAmountSelector {
    if (!this._instance) {
      this._instance = new BetAmountSelector();
    }
    return this._instance;
  }

  public hide(): void {
    this.betPanel.visible = false;
  }

  private setupBetView(): void {
    this.createBetPanel();
    this.setupBetAmountText();
    this.createBetButtons();
  }

  private createBetPanel(): void {
    const panelSprite = Sprite.from("betpanel");
    panelSprite.label = "panelSprite";
    this.betPanel.addChild(panelSprite);
    this.betPanel.position.set(22, 452);
    this.betPanel.scale.set(1.05, 0.95);
    this.betPanel.visible = false;
    this.addChild(this.betPanel); // Add betPanel to the BetAmountSelector (Container)
  }

  private setupBetAmountText(): void {
    this.betAmountText.position.set(150, 790);
    this.betAmountText.visible = false;
    this.addChild(this.betAmountText);
    this.centerBetAmountContainer();
  }

  private centerBetAmountContainer(): void {
    this.betAmountText.pivot.x = this.betAmountText.width / 2;
    this.betAmountText.x = 150;
  }

  private createBetButtons(): void {
    const betAmounts = this.currencyManager.getBetAmounts();
    betAmounts.forEach((betAmount, index) => {
      const button = this.createBetButton(Number(betAmount), index);
      this.buttons.push(button);
    });
  }

  private createBetButton(betAmount: number, index: number): Button {
    const button = new Button("");
    button.label = `betButton_${index}`;
    const x = index % 5;
    const y = Math.floor(index / 5);

    this.betPanel.addChild(button);
    button.updateSprite("bet_background", true);

    button.sprite!.alpha = index === this._activeButtonIndex ? 1 : 0.001;
    button.sprite!.label = `betButtonSprite_${index}`;

    button.text = new Text({
      text: CurrencyManager.instance.formatAmountDecimalWithCommas(betAmount),
      style: {
        fontFamily: FontFamily.TIMES_NEW_ROMAN,
        fontSize: 22,
        fill: 0xffffff,
        stroke: { color: 0x000000, width: 1 },
        lineJoin: "round",
      } as any,
    });
    button.text.resolution = 1.3;
    button.text.anchor.set(0.5, 0.5);
    button.text.label = `betButtonText_${index}`;
    button.addChild(button.text);
    button.position.set(200 - y * 77, 300 - 41 - x * 56);
    button.value = betAmount;

    button.on("click", () => this.changeBet(index));
    button.on("tap", () => this.changeBet(index));

    return button;
  }

  public startDemoMode() {
    this.betAmountText.setDemoMode(true);
    this.betAmountText.updateBetAmount(10);
  }

  public endDemoMode() {
    this.betAmountText.setDemoMode(false);
    this.changeBet(this._activeButtonIndex);
  }

  public showText(): void {
    this.betAmountText.visible = true;
    this.changeBet(this._activeButtonIndex);
  }

  public changeBet(newButtonIndex: number): void {
    this.buttons[this._activeButtonIndex].sprite!.alpha = 0.001;
    this._activeButtonIndex = newButtonIndex;
    this.buttons[this._activeButtonIndex].sprite!.alpha = 1;

    const betAmount = this.buttons[this._activeButtonIndex].value;
    this.betAmountText.updateBetAmount(betAmount);
    this.centerBetAmountContainer();

    this.betPanel.visible = false;
    this.betAmount = new Decimal(betAmount);
  }
}
