import { Graphics, Text, Container } from "pixi.js";
import { GameView } from "../../GameView";
import { Config } from "../../config/Config";
import { Translator } from "../../common/src/utils/translation/Translator";
import { FontFamily } from "../../common/src/utils/FontManager";

export class TransactionDisplay extends Container {
  private static _instance: TransactionDisplay;
  private bg: Graphics;
  private version: Text;
  private roundId: Text;

  private constructor() {
    super();

    // Create background
    this.bg = new Graphics()
      .rect(0, 0, GameView.instance.width, 200)
      .fill(0x000000);
    this.addChild(this.bg);

    // Create version text
    this.version = new Text({
      text: Config.version,
      style: {
        fontFamily: FontFamily.SYSTEM_UI,
        fontSize: 14,
        fill: 0xffffff,
      },
    });
    this.version.resolution = 2;
    this.version.position.set(6, 2);
    this.addChild(this.version);

    // Create round ID text
    this.roundId = new Text({
      text: "",
      style: {
        fontFamily: FontFamily.SYSTEM_UI,
        fontSize: 14,
        align: "right",
        fill: 0xffffff,
      },
    });
    this.roundId.resolution = 2;
    this.roundId.position.set(GameView.instance.width - 6, 2);
    this.roundId.anchor.set(1, 0); // Anchor the round ID to the right side
    this.addChild(this.roundId);

    // Set initial position and hide the display
    this.position.set(0, GameView.instance.height - 20);
    this.visible = false;
  }

  public static get instance(): TransactionDisplay {
    if (!TransactionDisplay._instance) {
      TransactionDisplay._instance = new TransactionDisplay();
    }
    return TransactionDisplay._instance;
  }

  public updateRoundIdText(id: string): void {
    this.roundId.text = `${Translator.instance.getText("transaction")} ${id}`;
  }

  public show(): void {
    this.visible = true;
  }

  public hide(): void {
    this.visible = false;
  }
}
