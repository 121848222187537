import gsap from "gsap";
import { AutoplayManager } from "../../control_panel/auto_play_button/AutoplayManager";
import { RoundHandler } from "../../../round_handler/round_handler/RoundHandler";
import { GameView } from "../../../GameView";
import { Button } from "../../../ui/components/Button";
import { BuyBonusModal } from "./BuyBonusModal";
import { Container, Rectangle, Sprite } from "pixi.js";

// import { FeaturePopup } from "../../popups/FeaturePopup";
import { Translator } from "../../../common/src/utils/translation/Translator";
import { Text } from "pixi.js";
import { BuyBonusManager } from "./BuyBonusManager";
import { BetAmountSelector } from "../../control_panel/bet_amount_selector_button/BetAmountSelector";
import { AutoplaySettingsWindow } from "../../control_panel/settings_button/autoplay_settings_button/AutoplaySettingsWindow";
const TEXT_SCALE = 1.4;

export class BuyBonusButton extends Button {
  private static _instance: BuyBonusButton;
  protected originalScale = { x: 0.75, y: 0.75 };
  private textContainer!: Container;
  private spinGlow!: Sprite;
  private animationInterval!: NodeJS.Timeout;
  private isAnimating: boolean = false;
  private shineSprite!: Sprite;
  private textFreeGames!: Sprite;
  private textCounter!: Text;
  private textCounterBg!: Sprite;
  private constructor() {
    super(undefined);
    this.setup();
  }

  static get instance(): BuyBonusButton {
    if (!BuyBonusButton._instance) {
      BuyBonusButton._instance = new BuyBonusButton();
    }
    return BuyBonusButton._instance;
  }

  private async setup(): Promise<void> {
    this.updateSprite("red_ruby", true);
    this.interactiveChildren = false;
    this.cursor = "pointer";

    this.hitArea = new Rectangle(
      -this.sprite.width / 2,
      -this.sprite.height / 2,
      this.sprite.width,
      this.sprite.height
    );

    this.position.set(GameView.instance.width / 2 + 190, 44);
    this.scale.set(this.originalScale.x, this.originalScale.y);

    this.spinGlow = Sprite.from("round_light");
    this.spinGlow.anchor.set(0.5, 0.5);
    this.spinGlow.position.set(0, 0);
    this.spinGlow.visible = false;
    this.addChild(this.spinGlow);

    this.textContainer = new Container();
    this.textContainer.position.set(
      0,
      Translator.instance.language === "th" ? -8 : -3
    );
    this.textContainer.rotation = -0.03;
    this.textContainer.scale = TEXT_SCALE;
    this.addChild(this.textContainer);

    const buyBonusSprite = Sprite.from(
      `buy_bonus_${Translator.instance.language}`
    );
    buyBonusSprite.anchor.set(0.5, 0.5);
    buyBonusSprite.scale.set(0.7);
    this.textContainer.addChild(buyBonusSprite);

    this.shineSprite = Sprite.from("shine_symbolwin");
    this.shineSprite.anchor.set(0.5, 0.5);
    this.shineSprite.position.set(33, -21);
    this.shineSprite.visible = false;
    this.addChild(this.shineSprite);

    // instantiate an interval that will trigger the animation every 10 seconds
    this.animationInterval = setInterval(() => {
      if (this.visible) {
        this.triggerAnimation();
      }
    }, 7000);

    this.triggerAnimation();
    this.visible = false;
  }

  public async triggerAnimation(): Promise<void> {
    if (this.isAnimating) return;

    this.isAnimating = true;
    await this.bounceBtnAnimation();
    await this.animateSpinGlow();
    await this.animateShine();
    this.isAnimating = false;
  }
  //updateButton sprite to free game sqare
  public updateSpriteToFreeGame(): void {
    this.updateSprite("buy_bonus_image", true);
    if (!this.textFreeGames) {
      this.textFreeGames = Sprite.from("free_game");
      this.textFreeGames.anchor.set(0.5, 0.5);
      this.textFreeGames.position.set(0, 45);
      this.textCounterBg = Sprite.from("freegame_counter_bg");
      this.textCounterBg.position.set(-81, -81);
      this.textCounter = new Text({
        text: BuyBonusManager.instance.quantity.toString(),
        style: { fill: 0x000000, fontSize: 32, fontWeight: "bold" },
      });
      this.textCounter.position.set(-73, -80);
    } else {
      this.textFreeGames.visible = true;
      this.textCounterBg.visible = true;
      this.textCounter.visible = true;
      this.textCounter.text = "";
    }
    this.addChild(this.textFreeGames);
    this.addChild(this.textCounterBg);
    this.addChild(this.textCounter);
    this.textContainer.visible = false;
    this.scale.set(0.4, 0.4);
  }

  public updateToOriginalSprite(): void {
    this.updateSprite("red_ruby", true);
    this.textFreeGames.visible = false;
    this.textCounterBg.visible = false;
    this.textCounter.visible = false;
    this.textCounter.text = "";
    this.textContainer.visible = true;
    this.scale.set(1, 1);
  }

  public updateQuantityText(quantity: number): void {
    this.textCounter.text = quantity.toString();
  }

  //return to original sprite
  //updateQuantity
  private async animateShine(): Promise<void> {
    this.shineSprite.visible = true;
    this.shineSprite.alpha = 0;
    this.shineSprite.scale = 0.2;
    this.shineSprite.rotation = 0;
    this.shineSprite.blendMode = "add";

    gsap.to(this.shineSprite, {
      rotation: -Math.PI * 2,
      duration: 2,
    });

    gsap.to(this.shineSprite.scale, {
      x: 0.5,
      y: 0.5,
      duration: 0.5,
      yoyo: true,
    });

    await gsap.to(this.shineSprite, {
      alpha: 1,
      duration: 0.5,
    });

    await gsap.to(this.shineSprite, {
      alpha: 0,
      duration: 0.5,
    });

    this.shineSprite.visible = false;
  }

  private async bounceBtnAnimation(): Promise<void> {
    if (this.textFreeGames?.visible) return;
    this.sprite.scale = 1;
    this.textContainer.scale = TEXT_SCALE;

    gsap
      .timeline()
      .to(this.sprite.scale, {
        duration: 0.5,
        x: 0.9,
        y: 0.9,
        delay: 0.1,
      })
      .to(this.sprite.scale, {
        duration: 0.8,
        x: 1,
        y: 1,
        ease: "elastic.out(1.2,0.4)",
      });

    await gsap
      .timeline()
      .to(this.textContainer.scale, {
        duration: 0.4,
        x: 1.6,
        y: 1.6,
      })
      .to(this.textContainer.scale, {
        duration: 0.4,
        x: TEXT_SCALE,
        y: TEXT_SCALE,
        ease: "elastic.out(1,0.5)",
      });
  }

  private async animateSpinGlow(): Promise<void> {
    this.spinGlow.visible = true;
    this.spinGlow.alpha = 0;
    this.spinGlow.rotation = -0.5;

    const duration = 0.8;

    // fade in and out the glow
    gsap
      .timeline()
      .to(this.spinGlow, {
        alpha: 1,
        duration: duration / 4,
      })
      .to(this.spinGlow, {
        alpha: 0,
        ease: "power3.in",
        duration: (duration / 4) * 3,
      });

    // make the glow spin with gsap animation for 1 circle in 2 seconds
    await gsap.to(this.spinGlow, {
      rotation: -Math.PI * 2,
      duration: duration,
      ease: "none",
    });
  }

  protected override async onClick(): Promise<void> {
    if (
      !RoundHandler.instance.isSpinInProgress &&
      !AutoplayManager.instance.isAutoPlaying
      //   FeaturePopup.instance.isClosed
    ) {
      super.onClick();
      BetAmountSelector.instance.hide();
      BuyBonusModal.instance.show();
    }
  }

  protected override onPointerDown(): void {
    if (
      !RoundHandler.instance.isSpinInProgress &&
      !AutoplayManager.instance.isAutoPlaying
    ) {
      super.onPointerDown();
    }
  }
}
