import { Container, Text } from "pixi.js";
import { GameView } from "../../GameView";
import { CurrencyManager } from "../../common/src/utils/currency/CurrencyManager";

import { Translator } from "../../common/src/utils/translation/Translator";
import { FontFamily, FontManager } from "../../common/src/utils/FontManager";

export class WinAmountDisplay extends Container {
  private static _instance: WinAmountDisplay;
  public currentWinAmount: number = 0;
  private winAmountText!: Text;
  private winWordText!: Text;
  private currencySymbolText!: Text;

  private constructor() {
    super();
    this.initializeLabels();
    this.setPosition();
    this.visible = false;
  }

  public static get instance(): WinAmountDisplay {
    if (!WinAmountDisplay._instance) {
      WinAmountDisplay._instance = new WinAmountDisplay();
    }
    return WinAmountDisplay._instance;
  }

  public startDemoMode(): void {
    this.winWordText.text = Translator.instance.getText("demo_mode");
  }

  public endDemoMode(): void {
    this.winWordText.text = Translator.instance.getText("win");
    this.winAmountText.text = CurrencyManager.instance.formatAmountDecimal(0);
  }

  private initializeLabels(): void {
    this.winWordText = new Text({
      text: Translator.instance.getText("win"),
      style: {
        fontFamily:
          Translator.instance.language === "en"
            ? FontFamily.TIMES_NEW_ROMAN
            : FontManager.instance.defaultFontFamily,
        fontSize: 21,
        fill: 0xffd545,
      },
    });
    this.winWordText.resolution = 1.3;
    this.addChild(this.winWordText);

    this.currencySymbolText = new Text({
      text: CurrencyManager.instance.getCurrencySymbol(),
      style: {
        fontFamily: FontManager.instance.defaultFontFamily,
        fontSize: 20,
        fill: 0xffffff,
        fontWeight: "bold",
      },
    });
    this.currencySymbolText.resolution = 1.3;
    this.addChild(this.currencySymbolText);

    this.winAmountText = new Text({
      text: CurrencyManager.instance.formatAmountDecimal(0.0),
      style: {
        fontFamily: FontFamily.HELVETICA,
        fontSize: 16,
        fill: 0xffffff,
        fontWeight: "bold",
      },
    });
    this.winAmountText.resolution = 1.5;
    this.addChild(this.winAmountText);

    // Position elements
    this.winAmountText.x = -4;
    this.winAmountText.y = 2;
    this.winAmountText.anchor.set(0, 0.2);

    this.currencySymbolText.anchor.set(1, 0.2);
    this.currencySymbolText.x = -7;
    this.currencySymbolText.y = 0;

    this.winWordText.anchor.set(1, 0);
    this.winWordText.x = -50;
    this.winWordText.y = -1;
  }

  private setPosition(): void {
    this.x = GameView.instance.width / 2;
    this.y = 683;
  }

  public updateWinAmount(value: number): void {
    this.currentWinAmount += value;
    this.winAmountText.text = CurrencyManager.instance.formatAmountDecimal(
      this.currentWinAmount
    );
  }

  public resetWinAmount(): void {
    // this.maxWinReachedLabel.visible = false;
    this.currentWinAmount = 0;
    this.winAmountText.text = CurrencyManager.instance.formatAmountDecimal(0);
    this.y = 683;
  }

  public show(): void {
    this.visible = true;
  }

  public hide(): void {
    this.visible = false;
  }
}
