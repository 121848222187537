import * as PIXI from "pixi.js";
import { BuyBonusModal } from "../game/header/buy_bonus/BuyBonusModal";

// expose wildCard to the global scope
declare global {
  interface Window {
    modal: BuyBonusModal;
  }
}

export default class BuyBonusModalSandbox extends PIXI.Container {
  private static _instance: BuyBonusModalSandbox;

  constructor() {
    super();
    // Trigger test animation
    this.testAnimation();
  }

  public testAnimation = async () => {
    // Initialize Test Object
    const modal = BuyBonusModal.instance;
    modal.visible = true;
    modal.scale.x = 1.5;
    modal.scale.y = 1.6;
    modal.position.set(window.innerWidth / 2, window.innerHeight / 2);
    window.modal = modal;

    this.addChild(modal);
  };

  public static get instance(): BuyBonusModalSandbox {
    if (!this._instance) {
      this._instance = new BuyBonusModalSandbox();
    }
    return this._instance;
  }
}
