import { Container, FederatedPointerEvent, Graphics, Sprite } from "pixi.js";
import { gsap } from "gsap";
import { SplashWildsPage } from "./SplashWildsPage";
import { SplashMultiplierPage } from "./SplashMultiplierPage";
import { GameView } from "../../../GameView";

export class SplashGallery extends Container {
  private xPosition = 0;
  private pages!: Array<SplashWildsPage | SplashMultiplierPage>;
  private content!: Container;
  private indicators: Sprite[] = [];
  private slideButtons: Sprite[] = [];
  private isMovement = false;
  private pageWidth = 700;

  private moveUUD: NodeJS.Timeout | null = null;

  private index = 0;

  private currentActiveIndicator = 0;

  constructor() {
    super();
    this.initializePages();
    this.initializeControls();
    this.initializeIndicators();
    this.setupMovement();
    this.createMask();
  }

  createMask() {
    const mask = new Graphics();

    const width = 670;
    const height = 470;

    mask.rect(-width / 2, -height * 0.4, width, height);
    mask.fill("#ffffff");
    // mask.stroke({
    //   color: '#ff0000',
    //   width: 2,
    // });
    // this.addChild(mask);
    // this.mask = mask;
  }

  public hide() {
    this.pages.forEach((page) => page.hide());
  }

  repositionPages() {
    this.pages.forEach((p, i) => (p.x = this.getPageX(i)));
  }

  private initializePages(): void {
    this.content = new Container();
    this.content.width = 840;
    this.content.height = 300;
    this.addChild(this.content);
    this.pages = [new SplashWildsPage(), new SplashMultiplierPage()];
    this.content.addChild(this.pages[0]);
    this.content.addChild(this.pages[1]);

    this.repositionPages();

    this.scheduleAutomaticMove();
  }

  private setupMovement(): void {
    let startX = 0;

    const onMoveStart = (e: FederatedPointerEvent) => {
      startX = this.xPosition;

      this.content.on("pointermove", onMove);
      this.content.on("pointerup", onMoveEnd);
      this.content.on("pointerupoutside", onMoveEnd);
    };

    const onMove = (e: FederatedPointerEvent) => {
      this.xPosition += e.movement.x;

      this.content.position.x = this.xPosition;

      const delta = startX - this.xPosition;

      if (Math.abs(delta) > 100) {
        onMoveEnd();
      }
    };

    const onMoveEnd = (e?: FederatedPointerEvent) => {
      this.content.off("pointermove", onMove);
      this.content.off("pointerup", onMoveEnd);
      this.content.off("pointerupoutside", onMoveEnd);

      const delta = startX - this.xPosition;

      if (delta === 0) return;

      // if (Math.abs(delta) > this.pageWidth / 2) {
      delta > 0 ? this.slideRight() : this.slideLeft();
      // } else {
      //   gsap.to(this, {
      //     xPosition: startX,
      //     duration: Math.abs(delta) / 1 / 1000,
      //     onUpdate: () => this.moveContent(),
      //   });
      // }
    };

    this.content.interactive = true;
    this.content.on("pointerdown", onMoveStart);
  }

  moveContent() {
    this.content.position.x = this.xPosition;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onMovement(e: any): void {
    if (!this.isMovement) return;

    this.xPosition += e.movement.x;
    this.moveContent();
  }

  private onMovementEnd(): void {
    this.isMovement = false;
    // gsap.to(this, {
    //   xPosition: this.xPosition < -350 ? -700 : 0,
    //   duration: 0.4,
    //   ease: "power3.out",
    //   onUpdate: () => {
    //     this.content.position.x = this.xPosition;
    //   },
    // });
    // this.snapToPage(this.xPosition < -350 ? 1 : 0);
  }

  private getPageX(index: number) {
    return index * this.pageWidth;
  }

  // reposition edge pages in case of loop
  prepareLoopPage(delta: number, xOffset = 0) {
    if (this.index === this.pages.length - 1 && delta > 0) {
      this.pages.push(this.pages.shift()!);

      this.index--;

      this.xPosition = this.getPageX(this.index) + xOffset;
      this.repositionPages();
    } else if (this.index === 0 && delta < 0) {
      this.pages.unshift(this.pages.pop()!);

      this.index++;
      this.xPosition = this.getPageX(this.index) * -1 + xOffset;
      this.repositionPages();
    }
  }

  private snapToPage(delta: number): void {
    if (this.moveUUD) clearTimeout(this.moveUUD);

    this.prepareLoopPage(delta);

    this.index += delta;

    const xPosition = this.getPageX(this.index) * -1;

    this.disableAll();
    gsap.to(this, {
      xPosition,
      duration: 0.4,
      ease: "power3.out",
      onUpdate: () => {
        this.content.position.x = this.xPosition;
      },
      onComplete: () => {
        this.scheduleAutomaticMove();

        this.enableAll();
      },
    });

    this.currentActiveIndicator += delta;

    this.indicators.forEach((indicator, i) => {
      indicator.tint =
        i == this.currentActiveIndicator % 2 ? 0xffffff : 0x888888;
    });
  }

  private slideLeft() {
    this.snapToPage(-1);
  }

  private slideRight() {
    this.snapToPage(1);
  }

  private initializeControls(): void {
    for (let i = 0; i < 2; ++i) {
      const slideButton = Sprite.from("button_slide");
      slideButton.anchor.set(0.5);
      slideButton.scale.set(0.75);

      slideButton.position.set(
        GameView.instance.width / 2 + (i === 0 ? -222 : 222),
        GameView.instance.height / 2 - 48
      );

      if (i === 0) {
        slideButton.rotation = Math.PI;
      }
      slideButton.interactive = true;
      slideButton.on("pointerdown", () =>
        i === 0 ? this.slideLeft() : this.slideRight()
      );
      this.addChild(slideButton);

      this.slideButtons.push(slideButton);
    }
  }

  private initializeIndicators(): void {
    for (let i = 0; i < 2; ++i) {
      const indicator = Sprite.from("indicator");
      indicator.anchor.set(0.5);
      indicator.scale.set(0.75);
      indicator.position.set(
        GameView.instance.width / 2 + (i * 15 - 10),
        GameView.instance.height / 2 + 135
      );
      this.addChild(indicator);

      this.indicators.push(indicator);
    }

    this.indicators[1].tint = 0x888888;
  }

  scheduleAutomaticMove() {
    this.moveUUD = setTimeout(() => {
      this.slideRight();
    }, 2000);
  }

  disableAll() {
    this.slideButtons.forEach((s) => (s.interactive = false));
    this.content.interactive = false;
  }

  enableAll() {
    this.slideButtons.forEach((s) => (s.interactive = true));
    this.content.interactive = true;
  }
}
