import { AutoplayManager } from "./auto_play_button/AutoplayManager";
import { GameView } from "../../GameView";
import { RoundHandler } from "../../round_handler/round_handler/RoundHandler";
import { SoundEffectManager } from "../../sounds/SoundEffectManager";
import { SOUNDS } from "../../sounds/Sounds";
import { Button } from "../../ui/components/Button";
import { BetAmountSelector } from "./bet_amount_selector_button/BetAmountSelector";
import { FreeSpinHandler } from "../../round_handler/round_handler/FreeSpinHandler";
import { Sprite } from "pixi.js";

export class SpinButton extends Button {
  private static _instance: SpinButton;
  protected originalScale = { x: 0.75, y: 0.75 };
  private isSpinButtonEnabled = true;
  private jiliLogoSprite!: Sprite;
  private constructor() {
    super(undefined);
    this.setup();
  }

  static get instance(): SpinButton {
    if (!SpinButton._instance) {
      SpinButton._instance = new SpinButton();
    }
    return SpinButton._instance;
  }

  private setup(): void {
    this.updateSprite("spinbutton", true);
    this.position.set(GameView.instance.width / 2, 762);
    this.scale.set(this.originalScale.x, this.originalScale.y);

    window.addEventListener("keypress", (e: KeyboardEvent) => {
      if (e.code === "Space") this.onSpaceKeyPress();
    });
    this.jiliLogoSprite = Sprite.from("logo_jili");
    this.jiliLogoSprite.anchor.set(0.5);
    this.jiliLogoSprite.position.set(0, 0);
    this.jiliLogoSprite.scale.set(0.25);
    this.addChild(this.jiliLogoSprite);
    this.enable();
  }

  private onSpaceKeyPress() {
    this.onClick();
  }

  public disable() {
    this.isSpinButtonEnabled = false;
    this.tint = 0x444444;
  }

  public enable() {
    this.isSpinButtonEnabled = true;
    this.tint = 0xffffff;
  }

  protected override async onClick(): Promise<void> {
    if (!this.isSpinButtonEnabled) return;

    if (
      RoundHandler.instance.isTriggerFreeGame &&
      !RoundHandler.instance.freeSpinHandler.isFreeSpinStarted
    ) {
      SoundEffectManager.instance.playSound(SOUNDS.SPIN_BUTTON_CLICK, false);
      RoundHandler.instance.freeSpinHandler.isFreeSpinStarted = true;
    } else if (
      !RoundHandler.instance.isSpinInProgress &&
      !AutoplayManager.instance.isAutoPlaying
    ) {
      SoundEffectManager.instance.playSound(SOUNDS.SPIN_BUTTON_CLICK, false);
      await RoundHandler.instance.handleRound();
    }
  }

  //The spin button doesnt change size on pointer down
  protected override onPointerDown(): void {}
  protected override onPointerUp(): void {}
}
