import { Round } from "./Round";

export class RoundResponse {
  roundId: string;
  balance: number;
  round: Round;

  constructor(data: any) {
    // No need to parse data, it's already an object
    this.roundId = data.roundId;
    this.balance = data.balance;
    this.round = new Round(data.round);
  }
}
