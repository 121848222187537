export class JwtStorageManager {
  static storeJwt(jwt: string): void {
    localStorage.setItem("jwtToken", jwt);
  }

  static getJwt(): string | null {
    const jwtToken = localStorage.getItem("jwtToken");
    if (!jwtToken) {
      const errorMessage = "Jwt token is not available";
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
    return jwtToken;
  }

  static removeJwt(): void {
    localStorage.removeItem("jwtToken");
  }
}
