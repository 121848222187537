import { Container, Text } from "pixi.js";
import { CurrencyManager } from "../../../common/src/utils/currency/CurrencyManager";
import { Translator } from "../../../common/src/utils/translation/Translator";
import { FontFamily, FontManager } from "../../../common/src/utils/FontManager";

export class BetAmountText extends Container {
  private betWordText: Text;
  private currencySymbolText: Text;
  private betAmountText: Text;

  constructor() {
    super();
    this.label = "betAmountContainer";
    this.betWordText = this.createBetWordText();
    this.currencySymbolText = this.createCurrencySymbolText();
    this.betAmountText = this.createBetAmountText();

    this.addChild(this.betWordText);
    this.addChild(this.currencySymbolText);
    this.addChild(this.betAmountText);

    this.updatePositions();
  }

  private createBetWordText(): Text {
    const text = new Text({
      text: Translator.instance.getText("bet"),
      style: {
        fontFamily: FontFamily.HELVETICA,
        fontSize: 15,
        fill: 0xffffff,
      },
    });
    text.resolution = 2;
    text.label = "betWordText";
    text.anchor.set(0, 0.45);
    return text;
  }

  private createCurrencySymbolText(): Text {
    const text = new Text({
      text: CurrencyManager.instance.getCurrencySymbol(),
      style: {
        fontFamily: FontManager.instance.defaultFontFamily,
        fontSize: 17,
        fill: 0xffffff,
        fontWeight: "bold",
      },
    });
    text.resolution = 1.3;
    text.label = "currencySymbolText";
    text.anchor.set(0, 0.51);
    return text;
  }

  private createBetAmountText(): Text {
    const text = new Text({
      text: "",
      style: {
        fontFamily:
          Translator.instance.language === "th"
            ? FontFamily.SYSTEM_UI
            : FontFamily.MILFORD,
        fontSize: 16,
        fill: 0xffffff,
        stroke: 0x000000,
      },
    });
    text.resolution = 1.5;
    text.label = "betAmountText";
    text.anchor.set(0, 0.5);
    text.y = -1;
    return text;
  }

  private updatePositions(): void {
    this.currencySymbolText.x = this.betWordText.width + 3;
    this.betAmountText.x =
      this.currencySymbolText.x + this.currencySymbolText.width + 6;
  }

  public updateBetAmount(amount: number): void {
    this.betAmountText.text =
      CurrencyManager.instance.formatAmountDecimalWithCommas(amount);
    this.updatePositions();
  }

  public setDemoMode(isDemoMode: boolean): void {
    this.betWordText.text = Translator.instance.getText(
      isDemoMode ? "demo_bet" : "bet"
    );
    this.updatePositions();
  }
}
