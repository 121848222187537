import { Config } from "../../config/Config";
import { Delay } from "../../common/src/utils/Delay";
import { Reel } from "./reel/Reel";
import { BaseSymbol } from "./reel/symbols/BaseSymbol";
import { ScatterSymbol } from "./reel/symbols/ScatterSymbol";
import { ReelSetCascadeHandler } from "./ReelSetCascadeHandler";
import { ReelSetWildsHandler } from "./ReelSetWildsHandler";
import { SoundEffectManager } from "../../sounds/SoundEffectManager";
import { SOUNDS } from "../../sounds/Sounds";
import { SymbolType } from "./reel/symbols/SymbolType";
import { GameView } from "../../GameView";
import { Container, Graphics } from "pixi.js";
import { LayerManager } from "../../utils/layers/LayerManager";
import { LayersConfig } from "../../utils/layers/LayersConfig";
import { RoundHandler } from "../../round_handler/round_handler/RoundHandler";

export class ReelSet {
  public static _instance: ReelSet;
  public reels: Reel[] = [];
  public cascadeHandler!: ReelSetCascadeHandler;
  public wildsHandler!: ReelSetWildsHandler;
  public upAndDownMask!: Graphics;
  public upOnlyMask!: Graphics;
  public reelsContainer!: Container;

  public static get instance(): ReelSet {
    if (!ReelSet._instance) {
      ReelSet._instance = new ReelSet();
    }
    return ReelSet._instance;
  }

  private constructor() {}

  public initReelSet() {
    this.initReelsContainer();

    for (let i = 0; i < Config.reelAmount; ++i) {
      const reel = new Reel(Config.symbolsPerReel, i);
      this.reels.push(reel);
    }

    this.cascadeHandler = new ReelSetCascadeHandler();
    this.wildsHandler = new ReelSetWildsHandler();
  }

  private initReelsContainer() {
    this.reelsContainer = new Container();
    this.reelsContainer.y = -6;

    this.reelsContainer.label = "REELS_CONTAINER";
    this.upAndDownMask = new Graphics()
      .rect(
        -4,
        -4,
        GameView.instance.width,
        GameView.instance.height - Config.reelSize.offsetY * 2 + 6
      ) //Only cover symbols with the mask, not the area around it
      .fill({ color: 0xffffff, alpha: 0 });

    this.upOnlyMask = new Graphics()
      .rect(-4, -4, GameView.instance.width, GameView.instance.height)
      .fill({ color: 0xff2222, alpha: 0 });

    // Set mask position
    this.upAndDownMask.position.set(0, Config.reelSize.offsetY);
    this.upOnlyMask.position.set(0, Config.reelSize.offsetY);

    // Add mask and game area to the view
    this.reelsContainer.addChild(this.upAndDownMask);
    this.reelsContainer.addChild(this.upOnlyMask);

    LayerManager.instance
      .getLayer(LayersConfig.REELS_SET_LAYERS.REELS)
      .addChild(this.reelsContainer);

    this.reelsContainer.on("pointerdown", () => {
      RoundHandler.instance.handleRound();
    });
  }

  public getSymbol(reelID: number, symbolID: number): BaseSymbol {
    return this.reels[reelID].getSymbol(symbolID);
  }

  public forEachSymbol(
    callback: (symbol: BaseSymbol, reelID: number, symbolID: number) => void
  ): void {
    for (let i = 0; i < Config.reelAmount; ++i) {
      for (let j = 0; j < Config.symbolsPerReel; ++j) {
        callback(this.getSymbol(i, j), i, j);
      }
    }
  }

  public async forEachSymbolAsync(
    callback: (
      symbol: BaseSymbol,
      reelID: number,
      symbolID: number
    ) => Promise<void>
  ): Promise<void> {
    for (let i = 0; i < Config.reelAmount; ++i) {
      for (let j = 0; j < Config.symbolsPerReel; ++j) {
        await callback(this.getSymbol(i, j), i, j);
      }
    }
  }

  public async performSymbolExitAnimation(): Promise<void> {
    this.reelsContainer.mask = this.upAndDownMask;
    for (let i = 0; i < Config.reelAmount; ++i) {
      this.reels[i].performSymbolExitAnimation();
    }

    await Delay.delay(6 * 33);
    this.reelsContainer.mask = null;
  }

  public async checkForScatterCollect(): Promise<void> {
    const scatters: ScatterSymbol[] = []; // check if 3 scaters for free spin

    this.forEachSymbol((symbol: BaseSymbol): void => {
      if (symbol.symbolType === SymbolType.Scatter) {
        scatters.push(symbol as ScatterSymbol);
      }
    });

    if (scatters.length >= 3) {
      SoundEffectManager.instance.playSound(SOUNDS.SCATTER_COLLECT, false);
      scatters.forEach(async (scatterSymbol: ScatterSymbol, i) => {
        scatterSymbol.performWinFreeSpinAnimation();
      }); //scatter win animation - Sound
      await Delay.delay(2200);
    }
  }

  public async performSymbolWinAnimation(winningSymbolsPositions: {
    [key: number]: string[];
  }): Promise<void> {
    for (let reelIndex = 0; reelIndex <= 4; reelIndex++) {
      this.reels[reelIndex].performSymbolWinAnimation(
        winningSymbolsPositions[reelIndex + 1]
      );
    }
  }

  public async performSymbolAfterWinAnimation(winningSymbolsPositions: {
    [key: number]: string[];
  }): Promise<void> {
    for (let reelIndex = 0; reelIndex <= 4; reelIndex++) {
      this.reels[reelIndex].performSymbolAfterWinAnimation(
        winningSymbolsPositions[reelIndex + 1]
      );
    }
  }

  public unBlur() {
    ReelSet.instance.reels.forEach((reel: Reel) => {
      reel.unBlur();
    });
  }

  public destroySymbols() {
    ReelSet.instance.reels.forEach((reel: Reel) => {
      reel.destroySymbols();
    });
  }

  public async doStartScreenAnimation(): Promise<void> {
    for (let reel of ReelSet.instance.reels) {
      await reel.reelStartScreenAnimationHandler.doStartScreenAenimation();
      await Delay.delay(33 * 4);
    }
  }
}
