export class Delay {
  public static async delay(ms: number): Promise<void> {
    return new Promise((resolve) => {
      let time = Date.now();
      const tick = () => {
        const delta = Date.now() - time;
        time = Date.now();
        ms -= delta;
        if (ms <= 0) {
          resolve();
        } else {
          requestAnimationFrame(() => tick());
        }
      };

      tick();
    });
  }
}
