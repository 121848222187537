import { Config } from "../../../config/Config";
import { Delay } from "../../../common/src/utils/Delay";
import { BaseSymbol } from "./symbols/BaseSymbol";
import { ScatterSymbol } from "./symbols/ScatterSymbol";
import { ReelSet } from "../ReelSet";
import { Reel } from "./Reel";
import { BigWildSymbol } from "./symbols/BigWildSymbol";
import { CardSymbol } from "./symbols/CardSymbol";
import { SOUNDS } from "../../../sounds/Sounds";
import { TurboSpinButton } from "../../control_panel/TurboSpinButton";
import { RoundHandler } from "../../../round_handler/round_handler/RoundHandler";
import { WildSymbol } from "./symbols/WildSymbol";
import { SymbolType } from "./symbols/SymbolType";
import { SoundEffectManager } from "../../../sounds/SoundEffectManager";

import { ReelSetSuspenseHandler } from "../ReelSetSuspenseHandler";
import { ElapsedTimeLogger } from "../../../common/src/utils/ElapsedTimeLogger";

export class ReelCascadeHandler {
  private readonly reel: Reel;

  constructor(reel: Reel) {
    this.reel = reel;
  }

  public async handleReelFill(newSymbols: SymbolType[]): Promise<void> {
    this.playReelFillSound();

    for (
      let symbolIndex = 0;
      symbolIndex < this.reel.symbolsPerReel;
      symbolIndex++
    ) {
      // wait for the suspense animation to finish for previouse reels
      const t = ReelSetSuspenseHandler.instance;
      while (
        ReelSetSuspenseHandler.instance.isSuspenseAnimationActive &&
        this.reel.reelIndex >
          ReelSetSuspenseHandler.instance.currentAnimatingSuspenseReelIndex
      ) {
        await Delay.delay(33);
      }
      const t2 = ReelSetSuspenseHandler.instance;

      const newSymbolValue = newSymbols[symbolIndex];
      this.reel.updateSymbol(symbolIndex, newSymbolValue);
      const addedSymbol = this.reel.symbols[symbolIndex];
      await this.handleSymbolReelFill(addedSymbol, symbolIndex);
      if (this.reel.reelIndex == 4 && symbolIndex == 3) {
        ReelSet.instance.cascadeHandler.isReelSetCascadeAnimationInProgress =
          false;
      }
    }
  }

  public async handleCascade(
    newSymbols: SymbolType[],
    winningSymbolsPositions: SymbolType[]
  ): Promise<void> {
    for (
      let symbolIndex = 0;
      symbolIndex < this.reel.symbolsPerReel;
      symbolIndex++
    ) {
      const newSymbolValue = newSymbols[symbolIndex];
      const isSymbolUpdateRequired = winningSymbolsPositions[symbolIndex];

      if (isSymbolUpdateRequired) {
        this.reel.updateSymbol(symbolIndex, newSymbolValue);
        const addedSymbol = this.reel.symbols[symbolIndex];

        if (!addedSymbol.isFaceDown) {
          await this.handleSymbolCascade(addedSymbol);
        }
      }

      this.addWildToReelSetWildHandler(newSymbolValue, symbolIndex);
    }
  }

  private playReelFillSound(): void {
    if (this.reel.reelIndex === 0 || this.reel.reelIndex === 3) {
      SoundEffectManager.instance.playSound(SOUNDS.REEL_FILL, false);
    }
  }

  private async handleSymbolReelFill(
    addedSymbol: BaseSymbol,
    symbolIndex: number
  ): Promise<void> {
    if (TurboSpinButton.instance.isTurboSpinEnabled) {
      addedSymbol.performSymbolFastSpinDropAnimation();
    } else {
      addedSymbol.performSymbolDropAnimation();

      await ReelSetSuspenseHandler.instance.handleSuspenseAnimation(
        addedSymbol
      );
      await this.delayBasedOnSuspenseAndSymbolIndex(addedSymbol);
    }
  }

  private async delayBasedOnSuspenseAndSymbolIndex(
    addedSymbol: BaseSymbol
  ): Promise<void> {
    let delay;
    if (
      ReelSetSuspenseHandler.instance.isSuspenseAnimationActive &&
      addedSymbol.reelIndex >=
        ReelSetSuspenseHandler.instance.currentAnimatingSuspenseReelIndex
    ) {
      if (addedSymbol instanceof ScatterSymbol) {
        delay = 33 * 20;
      } else {
        delay = 33 * 10;
      }
    } else if (addedSymbol.symbolIndex === 2) {
      delay = 33 * 5;
    } else {
      delay = 33 * 3;
    }
    await Delay.delay(delay);
  }

  private async handleSymbolCascade(addedSymbol: BaseSymbol): Promise<void> {
    addedSymbol.performSymbolDropAnimation();
    if (!TurboSpinButton.instance.isTurboSpinEnabled) {
      await Delay.delay(2 * 33);
      SoundEffectManager.instance.playSound(SOUNDS.CARD_LAND, false);
    }
  }

  private addWildToReelSetWildHandler(
    newSymbolValue: string,
    symbolIndex: number
  ): void {
    const addedSymbol = this.reel.symbols[symbolIndex];
    const wildsHandler = ReelSet.instance.wildsHandler;

    switch (newSymbolValue) {
      case SymbolType.OriginalBigWild:
        wildsHandler.originalBigWild = addedSymbol as BigWildSymbol;
        break;
      case SymbolType.BigWild:
        wildsHandler.originalBigWildExpandTargetSymbols.push(
          addedSymbol as CardSymbol
        );
        break;
      case SymbolType.Wild:
        wildsHandler.regularWilds.push(addedSymbol as WildSymbol);
        break;
    }
  }
}
