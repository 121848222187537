import { Button } from "../../../ui/components/Button";
import { Checkbox } from "../../../ui/components/Checkbox";

export class SplashCheckbox extends Checkbox {
  protected createCheckButton(): Button {
    const checkButton = new Button(this.isChecked ? "box_on" : "box");
    checkButton.scale.set(1.05);
    checkButton.position.set(-4, 0);
    return checkButton;
  }

  protected onClick() {
    if (this.isLocked) return;
    this.toggleCheckState();
    this.checkButton.updateSprite(this.isChecked ? "box_on" : "box", true);
  }
}
