import { gsap } from "gsap";
import { SoundEffectManager } from "../../../sounds/SoundEffectManager";
import { SOUNDS } from "../../../sounds/Sounds";
import { Reel } from "./Reel";
import { CardSymbol } from "./symbols/CardSymbol";
import { GoldCardSymbol } from "./symbols/GoldCardSymbol";
import { SymbolType } from "./symbols/SymbolType";
import { Delay } from "../../../common/src/utils/Delay";
import { Tween } from "../../../utils/Tween";

export class ReelStartScreenAnimationHandler {
  constructor(private reel: Reel) {}

  public async doStartScreenAenimation(): Promise<void> {
    SoundEffectManager.instance.playSound(SOUNDS.CARD_FLIP);
    const previewSymbols: SymbolType[] = [
      SymbolType.Ace,
      SymbolType.King,
      SymbolType.Queen,
      SymbolType.Jack,
      SymbolType.Spade,
    ];
    for (let symbolIndex = 3; symbolIndex >= 0; symbolIndex--) {
      let newSymbolToAdd: CardSymbol | GoldCardSymbol;
      if (this.reel.reelIndex === 0 && symbolIndex === 3) {
        newSymbolToAdd = new GoldCardSymbol(
          symbolIndex,
          this.reel.reelIndex,
          SymbolType.AceGolden
        );
      } else {
        newSymbolToAdd = new CardSymbol(
          symbolIndex,
          this.reel.reelIndex,
          previewSymbols[this.reel.reelIndex]
        );
      }

      this.reveal(newSymbolToAdd);
      this.reel.addNewSymbol(symbolIndex, newSymbolToAdd);
      await Delay.delay(33 * 2);
    }
  }

  private reveal(cardSymbol: CardSymbol): void {
    Tween.start(
      (n) => {
        cardSymbol.container.scale.set(
          ((1 - n) * 0.1 + 1) * cardSymbol.cardSymbolScale.x,
          ((1 - n) * 0.1 + 1) * cardSymbol.cardSymbolScale.y
        );
        cardSymbol.container.alpha = n;
      },
      0,
      1,
      33 * 5
    );
  }
}
