import Game from "../../../../Game";
import { Translator } from "../../../../common/src/utils/translation/Translator";

export class InfoWindow {
  private static _instance: InfoWindow;
  private domElement!: HTMLIFrameElement;
  private messageHandler!: (message: any) => void;

  public static get instance(): InfoWindow {
    if (!InfoWindow._instance) {
      InfoWindow._instance = new InfoWindow();
    }
    return InfoWindow._instance;
  }

  constructor() {
    this.setup();
  }

  private setup() {
    this.domElement = document.createElement("iframe");
    this.domElement.src = `assets/help_page/help_${Translator.instance.language}.html`;
    this.domElement.style.position = "absolute";
    this.domElement.style.top = "0";
    this.domElement.style.left = "0";
    this.domElement.style.width = "100%";
    this.domElement.style.height = "100vh";
    this.domElement.style.zIndex = "999";
    this.domElement.style.border = "none";

    this.messageHandler = (message: any) => {
      if (message.data === "info-close") this.closeWindow();
    };
  }

  public openWindow() {
    document.body.appendChild(this.domElement);
    window.addEventListener("message", this.messageHandler);
  }

  public closeWindow() {
    if (this.domElement.parentNode) {
      this.domElement.remove();
    }
    window.removeEventListener("message", this.messageHandler); // Remove the event listener
  }
}
