import { SoundManager } from "./SoundManager"; // Adjust the path as necessary
import { Config } from "../config/Config";
import { Delay } from "../common/src/utils/Delay";

export class BackgroundMusicManager {
  private static _instance: BackgroundMusicManager | null = null;
  private regularMusicBuffer: AudioBuffer | null = null;
  private freeSpinMusicBuffer: AudioBuffer | null = null;
  private currentSource: AudioBufferSourceNode | null = null;
  private regularMusicArrayBuffer: ArrayBuffer | null = null;
  private freeSpinMusicArrayBuffer: ArrayBuffer | null = null;

  private constructor() {
    // Constructor remains empty for singleton setup
  }

  public static get instance(): BackgroundMusicManager {
    if (!BackgroundMusicManager._instance) {
      BackgroundMusicManager._instance = new BackgroundMusicManager();
    }
    return BackgroundMusicManager._instance;
  }

  public async loadRegularMusic() {
    this.regularMusicArrayBuffer = await this.fetchMusic(
      Config.assetRoot + Config.soundRoot + "bgm_base.mp3"
    );
  }

  public async loadFreeSpinMusic() {
    this.freeSpinMusicArrayBuffer = await this.fetchMusic(
      Config.assetRoot + Config.soundRoot + "bgm_fs.mp3"
    );
  }

  private async fetchMusic(url: string): Promise<ArrayBuffer> {
    const response = await fetch(url);
    return response.arrayBuffer();
  }

  public async decodeRegularMusic() {
    if (!this.regularMusicArrayBuffer) {
      console.error("Regular music data is not loaded yet.");
      return;
    }
    SoundManager.instance.initAudio();
    this.regularMusicBuffer =
      await SoundManager.instance.audioContext.decodeAudioData(
        this.regularMusicArrayBuffer
      );
  }

  public async decodeFreeSpinMusic() {
    if (!this.freeSpinMusicArrayBuffer) {
      console.error("Free spin music data is not loaded yet.");
      return;
    }
    SoundManager.instance.initAudio();
    this.freeSpinMusicBuffer =
      await SoundManager.instance.audioContext.decodeAudioData(
        this.freeSpinMusicArrayBuffer
      );
  }

  private playMusic(musicBuffer: AudioBuffer) {
    if (this.currentSource) {
      this.currentSource.stop(); // Stop the current music if playing
    }

    // Ensure AudioContext is initialized and resumed
    SoundManager.instance.initAudio();

    const source = SoundManager.instance.audioContext.createBufferSource();
    source.buffer = musicBuffer;
    source.connect(SoundManager.instance.audioContext.destination);
    source.loop = true;
    source.start();
    this.currentSource = source;
  }

  public playRegularBackgroundMusic() {
    if (this.regularMusicBuffer) {
      this.playMusic(this.regularMusicBuffer);
    }
  }

  public playFreeSpinBackgroundMusic() {
    if (this.freeSpinMusicBuffer) {
      this.playMusic(this.freeSpinMusicBuffer);
    }
  }
}
