import { Container, Sprite, Texture } from "pixi.js";
import { SymbolType } from "./SymbolType";
import { Config } from "../../../../config/Config";
import { SoundEffectManager } from "../../../../sounds/SoundEffectManager";
import { SOUNDS } from "../../../../sounds/Sounds";
import { gsap } from "gsap";

export class BaseSymbol {
  symbolType: SymbolType;
  isHidden: boolean = false;
  isBlurred: boolean = false;
  symbolIndex: number;
  reelIndex: number;
  isFaceDown: boolean = false;
  container: Container;
  protected _cardSymbolScale = { x: 1, y: 1 };

  constructor(
    symbolIndex: number,
    reelIndex: number,
    symbolType: SymbolType,
    isFaceDown: boolean = false
  ) {
    this.symbolType = symbolType;
    this.symbolIndex = symbolIndex;
    this.reelIndex = reelIndex;
    this.isFaceDown = isFaceDown;
    this.container = new Container();
    this.container.label = `REEL_${this.reelIndex}_SYMBOL_${this.symbolIndex}`;
  }

  public destroy(): void {
    // Stop any ongoing animations
    gsap.killTweensOf(this.container);

    // Recursively destroy all children
    this.destroyChildren(this.container);

    // Destroy the container itself
    this.container.destroy({ children: true });
  }

  private destroyChildren(container: Container): void {
    for (const child of container.children) {
      if (child instanceof Container) {
        this.destroyChildren(child);
      }
      if ("destroy" in child && typeof child.destroy === "function") {
        child.destroy();
      }
    }
    container.removeChildren();
  }

  public hide(): void {
    this.isHidden = true;
    this.container.visible = false;
  }

  public show(): void {
    this.isHidden = false;
    this.container.visible = true;
  }

  public async performSymbolWinAnimation(): Promise<void> {
    // Implement win animation logic here
  }

  public performSymbolFlipAnimation(
    showFace: boolean,
    skipAnimation = false
  ): void {
    // Implement flip animation logic here
  }

  public blur(): void {
    this.isBlurred = true;
    this.container.tint = 0x404040;
  }

  public unBlur(): void {
    this.isBlurred = false;
    this.container.tint = 0xffffff;
  }

  public async performSymbolExitAnimation(): Promise<void> {
    const startY = this.container.position.y;
    const targetY =
      startY + Config.reelSize.symbolHeight * (Config.symbolsPerReel + 1);

    return new Promise((resolve) => {
      gsap.fromTo(
        this.container,
        { y: startY },
        {
          y: targetY,
          duration: 6 * 0.033,
          ease: "none", // Equivalent to LINEAR easing
          onComplete: () => {
            this.hide(); // Hide the symbol before resolving
            resolve();
          },
        }
      );
    });
  }

  public async performSymbolFastSpinDropAnimation(): Promise<void> {
    if (this.symbolType === SymbolType.Scatter) {
      SoundEffectManager.instance.playSound(SOUNDS.SCATTER_LAND, false);
    }
    this.show();
    const targetY = this.container.position.y;
    const startY = -1 * this.symbolIndex * (Config.reelSize.symbolHeight - 10);
    const duration = 5.5 * 0.033;

    return new Promise((resolve) => {
      gsap.fromTo(
        this.container,
        { y: startY },
        {
          y: targetY,
          duration: duration, // Convert milliseconds to seconds for GSAP
          ease: "none", // Equivalent to LINEAR easing
          onComplete: () => {
            this.performSymbolLandAnimation();
            resolve();
          },
        }
      );
    });
  }

  public async performSymbolDropAnimation(): Promise<void> {
    if (this.symbolType === SymbolType.Scatter) {
      SoundEffectManager.instance.playSound(SOUNDS.SCATTER_LAND, false);
    }
    this.show();
    const targetY = this.container.position.y;
    const startY = 0;
    const duration =
      this.symbolIndex == 0 ? 0.2 : 0.166 - this.symbolIndex * 0.033;

    return new Promise((resolve) => {
      gsap.fromTo(
        this.container,
        { y: startY },
        {
          y: targetY,
          duration: duration, // Convert milliseconds to seconds for GSAP
          ease: "none", // Equivalent to LINEAR easing
          onComplete: () => {
            this.performSymbolLandAnimation();
            resolve();
          },
        }
      );
    });
  }

  public async performSymbolLandAnimation() {
    // Implement land animation logic here
  }

  public performSymbolAfterWinAnimation() {
    // Implement after-win animation logic here
  }

  public static isGoldenCard(symbolType: SymbolType): boolean {
    return symbolType.includes("g");
  }

  public static isFaceCard(symbolType: SymbolType): boolean {
    return (
      symbolType.includes("J") ||
      symbolType.includes("Q") ||
      symbolType.includes("K")
    );
  }

  public static isWhiteCard(symbolType: SymbolType): boolean {
    return (
      !symbolType.includes("g") &&
      !symbolType.includes("W") &&
      !symbolType.includes("$")
    );
  }

  public static isWildCard(symbolType: SymbolType): boolean {
    return symbolType.includes("W");
  }

  protected destroySprite(sprite: Sprite | undefined): void {
    if (sprite) {
      sprite.destroy({ children: true, texture: true });
    }
  }
}
