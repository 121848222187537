export const SymbolType = {
  Club: "C",
  Diamond: "D",
  Heart: "H",
  Spade: "S",
  Jack: "J",
  Queen: "Q",
  King: "K",
  Ace: "A",
  Wild: "W",
  BigWild: "BW",
  OriginalBigWild: "OBW",
  Scatter: "$",
  ClubGolden: "Cg",
  DiamondGolden: "Dg",
  HeartGolden: "Hg",
  SpadeGolden: "Sg",
  JackGolden: "Jg",
  QueenGolden: "Qg",
  KingGolden: "Kg",
  AceGolden: "Ag",
  Empty: "",
} as const;

export type SymbolType = (typeof SymbolType)[keyof typeof SymbolType];
