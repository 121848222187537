import { CardSymbol } from "./CardSymbol";
import { Container, Sprite, Graphics } from "pixi.js";
import { gsap } from "gsap";
import { SymbolType } from "./SymbolType";

export class GoldCardSymbol extends CardSymbol {
  private shine?: Container;
  private light?: Sprite;

  constructor(symbolIndex: number, reelIndex: number, symbolType: SymbolType) {
    super(symbolIndex, reelIndex, symbolType);
    this.createGoldCardSymbol();
  }

  private createGoldCardSymbol() {
    this.cardFrameSprite.texture = Sprite.from("card_frame_0").texture;
    this.cardBackgroundSprite.texture = Sprite.from("card_bg_gold").texture;
  }

  public get isGold(): boolean {
    return true;
  }

  public override async performSymbolLandAnimation() {
    const shine = new Container();
    const mask = new Graphics();
    mask.rect(
      0,
      0,
      this.cardBackgroundSprite.width,
      this.cardBackgroundSprite.height
    );
    mask.fill(0xffffff);
    mask.x = -this.cardBackgroundSprite.width / 2;
    mask.y = -this.cardBackgroundSprite.height / 2;

    shine.mask = mask;
    shine.addChild(mask);

    const light = Sprite.from("symbol_land_shine");
    light.anchor.set(0.5);
    light.rotation = -0.8;
    light.x = -this.cardBackgroundSprite.width / 2;
    light.y = -this.cardBackgroundSprite.height / 2;
    shine.addChild(light);

    this.container.addChild(shine);
    shine.zIndex = 15;

    this.cardShineSprite.alpha = 0;
    this.cardShineSprite.visible = true;
    this.cardFrameShineSprite.alpha = 0;
    this.cardFrameShineSprite.visible = true;

    const timeline = gsap.timeline();

    // Animate the light sprite diagonally across the symbol
    timeline
      .to(light, {
        x: 150,
        y: 160,
        duration: 0.5,
        ease: "none",
      })
      .to(
        [this.cardShineSprite, this.cardFrameShineSprite],
        {
          alpha: 1,
          duration: 0.2,
          ease: "none",
        },
        "<"
      )
      .to(
        [this.cardShineSprite, this.cardFrameShineSprite],
        {
          alpha: 0,
          duration: 0.1,
          ease: "none",
        },
        ">"
      );

    // Play the entire animation sequence
    await timeline.play();

    this.cardShineSprite.visible = false;
    this.cardFrameShineSprite.visible = false;
    shine.destroy();
  }

  public async performFaceDownCardAfterWinAnimation(): Promise<void> {
    this.isFaceDown = true;

    this.cardShineSprite.zIndex = 60;

    const timeline = gsap.timeline();

    timeline
      // Step 1: Fade out the original card
      .to(
        [this.cardSymbolSprite, this.cardBackgroundSprite],
        {
          alpha: 0,
          duration: 0.13,
          ease: "none",
        },
        0
      )

      // Step 2: Change texture, fade in card shine and frame shine, expand card, and fade in new background simultaneously
      .call(() => {
        this.cardBackgroundSprite.texture = Sprite.from("card_back").texture;
        this.cardFrameShineSprite.visible = true;
      })
      .to(this.cardShineSprite, {
        alpha: 0.6,
        duration: 0.27,
        ease: "none",
      })
      .to(
        this.cardFrameShineSprite,
        {
          alpha: 1,
          duration: 0.27,
          ease: "none",
        },
        "<"
      )
      .to(
        this.cardFrameShineSprite.scale,
        {
          x: this.cardFrameShineSprite.scale.x * 1.1,
          y: this.cardFrameShineSprite.scale.y * 1.1,
          duration: 0.27,
          ease: "none",
        },
        "<"
      )
      .to(
        this.container.scale,
        {
          x: this._cardSymbolScale.x * 1.1,
          y: this._cardSymbolScale.y * 1.1,
          duration: 0.27,
          ease: "none",
        },
        "<"
      )
      .to(
        this.cardBackgroundSprite,
        {
          alpha: 1,
          duration: 0.27,
          ease: "none",
        },
        "<"
      )

      // Step 3: Fade out both shines and shrink the card simultaneously
      .to([this.cardShineSprite, this.cardFrameShineSprite], {
        alpha: 0,
        duration: 0.1,
        ease: "none",
      })
      .to(
        this.container.scale,
        {
          x: this._cardSymbolScale.x,
          y: this._cardSymbolScale.y,
          duration: 0.1,
          ease: "none",
        },
        "<"
      );

    await timeline.play();

    this.cardFrameShineSprite.visible = false;
    this.cardFrameSprite.visible = false;
    this.cardSymbolSprite.visible = false;
    this.cardFrameSprite.alpha = 1;
    this.cardSymbolSprite.alpha = 1;
  }

  public override destroy(): void {
    // Destroy specific resources for GoldCardSymbol
    if (this.shine) {
      this.shine.destroy({ children: true });
      this.shine = undefined;
    }
    this.destroySprite(this.light);

    // Call the parent class destroy method
    super.destroy();
  }
}
