import { Reel, ReelLayer } from "./reel/Reel";
import { BigWildSymbol } from "./reel/symbols/BigWildSymbol";
import { Delay } from "../../common/src/utils/Delay";
import { ReelSet } from "./ReelSet";
import { CardSymbol } from "./reel/symbols/CardSymbol";
import { WildSymbol } from "./reel/symbols/WildSymbol";
import { SymbolType } from "./reel/symbols/SymbolType";

export class ReelSetWildsHandler {
  public originalBigWild: BigWildSymbol | null = null;
  public originalBigWildExpandTargetSymbols: CardSymbol[] = [];
  public regularWilds: WildSymbol[] = [];

  public async handleWilds(): Promise<void> {
    // If we have any wilds
    if (this.regularWilds.length > 0 || this.originalBigWild) {
      // Perform show card face animation for all reels
      ReelSet.instance.reels.forEach((reel: Reel) => {
        reel.wildsHandler.performShowCardFaceAnimation();
      });

      await Delay.delay(433); // Show card face animation time

      // Handle big wild expansion
      if (this.originalBigWild) {
        ReelSet.instance.reels[
          this.originalBigWild.reelIndex
        ].changeSymbolLayer(this.originalBigWild, ReelLayer.WildWin);

        await this.originalBigWild.performBigWildExpandAnimation(); // 200 ms each

        ReelSet.instance.reels[
          this.originalBigWild.reelIndex
        ].changeSymbolLayer(this.originalBigWild, ReelLayer.Base);

        // Update symbols with BigWild in the targeted positions
        this.originalBigWildExpandTargetSymbols.forEach((symbol) => {
          ReelSet.instance.reels[symbol.reelIndex].updateSymbol(
            symbol.symbolIndex,
            SymbolType.BigWild
          );
        });
      }

      // Clear wild state
      this.originalBigWild = null;
      this.originalBigWildExpandTargetSymbols = [];
      this.regularWilds = [];
    }
  }
}
