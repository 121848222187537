import { CardSymbol } from "./CardSymbol";
import { SymbolType } from "./SymbolType";
import { Sprite, Texture } from "pixi.js";
import gsap from "gsap";
import { Delay } from "../../../../common/src/utils/Delay";

export class WildSymbol extends CardSymbol {
  protected wildSymbolScale = { x: 0.76, y: 0.76 };

  constructor(
    symbolIndex: number,
    reelIndex: number,
    symbolType: SymbolType = SymbolType.Wild
  ) {
    super(symbolIndex, reelIndex, symbolType);
    this.createWildSymbol();
  }

  private createWildSymbol(): void {
    // Update textures
    this.cardBackgroundSprite.texture = Texture.from("card_bg_gold");
    this.cardFrameSprite.texture = Texture.from("card_frame_1");

    // Update anchor points
    this.cardFrameSprite.anchor.set(0.5);

    // Make an exception for big wild symbol , so it starts face up
    if (this.symbolType !== SymbolType.BigWild) {
      this.putCardFaceDown();
    }

    this.container.scale.set(this.wildSymbolScale.x, this.wildSymbolScale.y);
  }

  public override async performSymbolWinAnimation() {
    this.cardShineSprite.visible = false;
    this.cardBackShineLargeSprite.visible = true;
    // shine in and out animation
    gsap.to(this.cardBackShineLargeSprite, { alpha: 1, duration: 0.1 });
    gsap.to(this.cardBackShineLargeSprite, {
      alpha: 0,
      duration: 0.2,
      delay: 0.35,
    });

    // Tilting animation
    const shakeDur = 0.05;
    const t1 = gsap.timeline();
    t1.to(this.cardSymbolSprite, {
      rotation: -0.04,
      duration: shakeDur,
    })
      .to(this.cardSymbolSprite, {
        rotation: 0.08,
        duration: shakeDur,
      })
      .to(this.cardSymbolSprite, {
        rotation: -0.03,
        duration: shakeDur,
      })
      .to(this.cardSymbolSprite, {
        rotation: 0.02,
        duration: shakeDur,
        ease: "power1.inOut",
      })
      .to(this.cardSymbolSprite, {
        rotation: 0,
        duration: shakeDur / 2,
      })
      .delay(0.2);

    const targetScales = [
      this.cardBackgroundSprite.scale,
      this.cardFrameSprite.scale,
      this.cardSymbolSprite.scale,
    ];
    // Scale down then up
    await gsap.to(targetScales, {
      x: 0.8,
      y: 0.8,
      duration: 0.15,
    });
    await gsap.to(targetScales, {
      x: 1.16,
      y: 1.16,
      duration: 0.15,
      ease: "back.out(6)",
    });

    // after being idle for some time scale back the card
    await gsap.to(targetScales, {
      x: 1,
      y: 1,
      duration: 0.15,
      delay: 0.5,
    });

    // TODO figure out why this delay is needed
    await new Promise((resolve) => setTimeout(resolve, 630));
  }

  public putCardFaceDown(): void {
    this.isFaceDown = true;
    this.cardShineSprite.alpha = 0;
    this.cardShineSprite.zIndex = 60;

    this.cardBackgroundSprite.texture = Texture.from("card_back");
    this.cardFrameShineSprite.visible = true;
    this.cardFrameShineSprite.alpha = 0;

    this.cardFrameShineSprite.visible = false;
    this.cardFrameSprite.visible = false;
    this.cardSymbolSprite.visible = false;
    this.cardFrameSprite.alpha = 1;
    this.cardSymbolSprite.alpha = 1;
  }

  public async performShowCardFaceAnimation(): Promise<void> {
    this.isFaceDown = false;
    this.cardSymbolSprite.visible = false;
    this.cardFrameSprite.visible = false;
    const scaleWhileFlipping = {
      x: this.wildSymbolScale.x * 0.8,
      y: this.wildSymbolScale.y * 1.03,
    };
    this.container.scale.set(scaleWhileFlipping.x, scaleWhileFlipping.y);
    await Delay.delay(33);

    for (let i = 1; i <= 5; ++i) {
      this.cardBackgroundSprite.texture = Texture.from(`card_back_${i}`);
      await Delay.delay(33);
    }

    this.cardBackgroundSprite.texture = Texture.from("card_flip_gold");
    await Delay.delay(33);

    this.cardBackgroundSprite.texture = Texture.from("card_bg_gold");
    this.container.scale.set(
      0.16 * scaleWhileFlipping.x,
      1.15 * scaleWhileFlipping.y
    );
    this.showCard();
    await Delay.delay(33);

    this.container.scale.set(
      0.33 * scaleWhileFlipping.x,
      1.1 * scaleWhileFlipping.y
    );
    await Delay.delay(33);

    this.container.scale.set(
      0.66 * scaleWhileFlipping.x,
      1.05 * scaleWhileFlipping.y
    );
    await Delay.delay(33);

    this.container.scale.set(scaleWhileFlipping.x, scaleWhileFlipping.y);
    await Delay.delay(33);

    this.container.scale.set(this.wildSymbolScale.x, this.wildSymbolScale.y);
  }

  public override destroy(): void {
    // Stop any ongoing animations specific to WildSymbol
    gsap.killTweensOf(this.cardSymbolSprite);
    gsap.killTweensOf(this.container.scale);

    // Call the parent class destroy method
    super.destroy();
  }
}
