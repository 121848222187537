import { Button } from "../../../../ui/components/Button";
import { AutoplayManager } from "../../auto_play_button/AutoplayManager";
import { AutoplaySettingsWindow } from "./AutoplaySettingsWindow";
import { ControlPanel } from "../../ControlPanel";

export class AutoPlaySettingsButton extends Button {
  private static _instance: AutoPlaySettingsButton;
  protected originalScale = { x: 0.9, y: 0.9 };

  private constructor() {
    super(undefined);
    this.setup();
  }

  static get instance(): AutoPlaySettingsButton {
    if (!AutoPlaySettingsButton._instance) {
      AutoPlaySettingsButton._instance = new AutoPlaySettingsButton();
    }
    return AutoPlaySettingsButton._instance;
  }

  private setup(): void {
    this.updateSprite("button_ap", true);
    this.position.set(36, 35);
    this.scale.set(this.originalScale.x, this.originalScale.y);
    function onClick() {
      AutoplaySettingsWindow.instance.show();
      ControlPanel.instance.hideSetttings();
      AutoplayManager.instance.stopAutoplay(false);
    }
    this.on("click", () => onClick());
    this.on("tap", () => onClick());
  }
}
