export class MathUtils {
  static clamp(value: number, min: number, max: number): number {
    return Math.min(max, Math.max(min, value));
  }

  static randomRangeRound(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  static roundToTwoDecimals(value: number): number {
    return Math.round(value * 100) / 100;
  }
}
