import { BuyBonusButton } from "./BuyBonusButton";

export class BuyBonusManager {
  private static _instance: BuyBonusManager;
  private _quantity: number = 0;
  private _betAmount: number = 0;
  private _isBuyBonusInProgress: boolean = false;

  private constructor() {}

  public static get instance(): BuyBonusManager {
    if (!BuyBonusManager._instance) {
      BuyBonusManager._instance = new BuyBonusManager();
    }
    return BuyBonusManager._instance;
  }

  public get betAmount(): number {
    return this._betAmount;
  }
  public set betAmount(value: number) {
    this._betAmount = value;
  }
  public get quantity(): number {
    return this._quantity;
  }
  public set quantity(value: number) {
    this._quantity = value;
  }
  public get isBuyBonusInProgress(): boolean {
    return this._isBuyBonusInProgress;
  }
  public set isBuyBonusInProgress(value: boolean) {
    this._isBuyBonusInProgress = value;
  }
}
