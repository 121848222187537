import { AutoplayManager } from "../auto_play_button/AutoplayManager";
import { RoundHandler } from "../../../round_handler/round_handler/RoundHandler";
import { GameView } from "../../../GameView";
import { Button } from "../../../ui/components/Button";
import { BetAmountSelector } from "./BetAmountSelector";

export class BetAmountSelectorButton extends Button {
  private static _instance: BetAmountSelectorButton;
  protected originalScale = { x: 0.88, y: 0.88 };

  private constructor() {
    super();
    this.setup();
  }

  public static get instance(): BetAmountSelectorButton {
    if (!BetAmountSelectorButton._instance) {
      BetAmountSelectorButton._instance = new BetAmountSelectorButton();
    }
    return BetAmountSelectorButton._instance;
  }

  public enable(): void {
    this.updateSprite("button_bet", true);
  }

  public disable(): void {
    this.updateSprite("button_bet_disabled", true);
  }

  private setup(): void {
    this.updateSprite("button_bet", true);
    this.position.set(GameView.instance.width / 2 - 94, 758);
    this.scale.set(this.originalScale.x, this.originalScale.y);

    this.interactive = true;
  }

  protected override onClick(): void {
    // Prevent button action if spin is in progress or autoplay is active
    super.onClick();
    if (this.isSpinNotInProgress()) {
      BetAmountSelector.instance.betPanel.visible =
        !BetAmountSelector.instance.betPanel.visible;
    }
  }

  protected override onPointerDown(): void {
    super.onPointerDown();
    if (this.isSpinNotInProgress()) {
      this.scale.set(0.85);
    }
  }

  protected override onPointerUp(): void {
    super.onPointerUp();
    if (this.isSpinNotInProgress()) {
      this.scale.set(0.88);
    }
  }

  private isSpinNotInProgress() {
    return (
      !RoundHandler.instance.isSpinInProgress &&
      !AutoplayManager.instance.isAutoPlaying
    );
  }
}
