export class SoundManager {
  private static _instance: SoundManager | null = null;
  private _audioContext: AudioContext = new AudioContext();
  private _isPlaySounds: boolean = true;

  private constructor() {
    this.setupAudioContext();
    // Listen to visibility change events to manage audio context
    document.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange.bind(this)
    );
  }

  public toggleSound(): void {
    this.isPlaySounds = !this.isPlaySounds;
    this.setAudioContextState(this.isPlaySounds);
  }

  public static get instance(): SoundManager {
    if (!SoundManager._instance) {
      SoundManager._instance = new SoundManager();
    }
    return SoundManager._instance;
  }

  public get isPlaySounds(): boolean {
    return this._isPlaySounds;
  }
  public set isPlaySounds(value: boolean) {
    this._isPlaySounds = value;
  }

  public get audioContext(): AudioContext {
    return this._audioContext;
  }

  public initAudio(): void {
    if (this._audioContext) {
      if (this._audioContext.state === "suspended" && this.isPlaySounds) {
        this._audioContext
          .resume()
          .then(() => console.log("AudioContext resumed."))
          .catch((err) => console.error("Failed to resume AudioContext:", err));
      }
      return;
    }

    this._audioContext = new ((window as any).AudioContext ||
      (window as any).webkitAudioContext)();

    if (this._audioContext.state === "suspended") {
      this._audioContext
        .resume()
        .then(() => console.log("New AudioContext resumed."))
        .catch((err) =>
          console.error("Failed to resume new AudioContext:", err)
        );
    }
  }

  // Add event listeners to initialize audio on user interaction
  private setupAudioContext(): void {
    window.addEventListener("load", () => this.initAudio());
    document.addEventListener("touchstart", () => this.initAudio(), {
      once: true,
    });
    document.addEventListener("click", () => this.initAudio(), { once: true });
  }

  private handleVisibilityChange(): void {
    // Only resume audio if the document is visible and sound is enabled
    if (document.visibilityState === "visible" && this.isPlaySounds) {
      this.setAudioContextState(true);
    } else {
      this.setAudioContextState(false);
    }
  }

  private setAudioContextState(resume: boolean): void {
    if (resume) {
      this._audioContext
        .resume()
        .then(() => console.log("AudioContext resumed."))
        .catch((err) => console.error("Failed to resume AudioContext:", err));
    } else {
      this._audioContext
        .suspend()
        .then(() => console.log("AudioContext suspended."))
        .catch((err) => console.error("Failed to suspend AudioContext:", err));
    }
  }
}
