import { JwtQueryStringHandler } from "./JwtQueryStringHandler";
import { JwtStorageManager } from "./JwtStorageManager";

export class JwtInitializer {
  static init(): void {
    const jwt = JwtQueryStringHandler.getJwtFromURL();
    if (jwt) {
      JwtStorageManager.storeJwt(jwt);
      JwtQueryStringHandler.removeJwtFromURL();
    }
  }
}
