import { Config } from "../config/Config";
import { ErrorMessageModal } from "../game/popups/ErrorMessageModal";
import { RoundResponse } from "../round_handler/round_data/RoundResponse";
import { Translator } from "../common/src/utils/translation/Translator";

export class GameApiCaller {
  public static async callApiFunction(
    functionName: string,
    body: string,
    additionalHeaders: Record<string, string> = {}
  ): Promise<RoundResponse | null> {
    const responseData = await this.sendRequest(
      functionName,
      body,
      additionalHeaders
    );

    if (!responseData) {
      this.showErrorMessage(
        Translator.instance.getText("unknown_server_error"),
        "201"
      );
      return null;
    }

    if (responseData.ErrorMessage) {
      this.showErrorMessage(
        responseData.ErrorMessage,
        responseData.ErrorMessageId
      );
      return null;
    }

    if (responseData.round) {
      return new RoundResponse(responseData);
    }

    this.showErrorMessage(
      Translator.instance.getText("invalid_responce_from_server"),
      "202"
    );
    return null;
  }

  private static async sendRequest(
    endpoint: string,
    body: string,
    additionalHeaders: Record<string, string> = {}
  ): Promise<any> {
    const url = `${process.env.API_URL}/${endpoint}`;
    const headers = {
      "Content-Type": "text/plain",
      ...additionalHeaders,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("API request failed:", error);
      return null;
    }
  }

  private static showErrorMessage(message: string, code: string): void {
    ErrorMessageModal.instance.showModal({ message, code });
  }
}
