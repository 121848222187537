import { Assets, AssetsManifest } from "pixi.js";
import { Translator } from "../translation/Translator";
import { EventEmitter } from "eventemitter3";

export class AssetManager extends EventEmitter {
  private static _instance: AssetManager;
  private assetsManifest: AssetsManifest = { bundles: [] };
  private loadedBundles: string[] = [];

  // Private constructor to ensure singleton pattern
  private constructor() {
    super();
  }

  // Singleton instance getter
  public static get instance(): AssetManager {
    if (!AssetManager._instance) {
      AssetManager._instance = new AssetManager();
    }
    return AssetManager._instance;
  }

  public async loadJiliScreenAssets(): Promise<void> {
    // Load the English bundle
    await this.loadBundle("preload");
  }

  public async loadManifest(): Promise<void> {
    this.assetsManifest = await this.fetchAssetsManifest(
      "assets/manifest6.json"
    );

    await Assets.init({ manifest: this.assetsManifest, basePath: "assets" });
  }

  public async loadSplashScreenAssets(): Promise<void> {
    // Load the splash screen bundle
    await this.loadBundle("splash", (progress) => {
      const totalProgress = progress;
      this.emit("jiliLogoProgress", totalProgress);
    });
  }

  // Initialize and load assets
  public async loadAssets(): Promise<void> {
    // Load the current language bundle
    await this.loadBundle(Translator.instance.language, (progress) => {
      const totalProgress = (progress / 2) * 100;
      this.emit("splashProgress", totalProgress);
    });

    // Load common assets
    await this.loadBundle("common", (progress) => {
      const totalProgress = 50 + (progress / 2) * 100;
      this.emit("splashProgress", totalProgress);
    });
  }

  // Load a specific bundle
  private async loadBundle(
    bundleName: string,
    progressCb?: (progress: number) => void
  ): Promise<void> {
    if (this.loadedBundles.includes(bundleName)) {
      return;
    }

    // Load the bundle using Pixi Assets with normalized progress
    const bundle = await Assets.loadBundle(bundleName, (progress) => {
      // Normalize the progress to 0-1 range
      const normalizedProgress = progress * 4; // Multiply by 4 to expand 0-0.25 to 0-1
      progressCb?.(Math.min(normalizedProgress, 1)); // Ensure it doesn't exceed 1
    });

    // Mark the bundle as loaded
    this.loadedBundles.push(bundleName);

    return bundle;
  }

  // Fetch the assets manifest from the specified URL
  private async fetchAssetsManifest(url: string): Promise<AssetsManifest> {
    const response = await fetch(url);
    const manifest = await response.json();
    if (!manifest.bundles) {
      throw new Error("[Assets] Invalid assets manifest");
    }
    return manifest;
  }
}
