import { Container, Text, Sprite, Texture } from "pixi.js";
import { SoundEffectManager } from "../../sounds/SoundEffectManager";
import { SOUNDS } from "../../sounds/Sounds";

export class Button extends Container {
  public text: Text;
  public value: number = 0;
  public sprite!: Sprite;
  protected originalScale = { x: 1, y: 1 };

  constructor(texture?: string, text: string = "", fontFamily?: string) {
    super();
    this.cursor = "pointer";
    this.zIndex = 100;

    if (texture) {
      this.sprite = Sprite.from(texture);
      this.sprite.anchor.set(0.5, 0.5);
      this.addChild(this.sprite);
    }

    const style: any = {
      fontSize: 25,
      fill: 0xffffff,
    };

    if (fontFamily) {
      style.fontFamily = fontFamily;
    }

    this.text = new Text({
      text,
      style,
    });
    this.text.resolution = 1.4;
    this.text.anchor.set(0.5, 0.5);
    this.text.zIndex = 500;
    this.addChild(this.text);

    this.setupListeners();
  }

  protected setupListeners(): void {
    this.on("click", () => this.onClick());
    this.on("tap", () => this.onClick());
    this.on("pointerdown", () => this.onPointerDown());
    this.on("pointerupoutside", () => this.onPointerUp());
    this.on("pointerup", () => this.onPointerUp());
  }

  protected onClick(): void {
    this.scale.set(this.originalScale.x, this.originalScale.y);
    SoundEffectManager.instance.playSound(SOUNDS.BUTTON_CLICK, false);
  }

  protected onPointerDown(): void {
    this.scale.set(this.originalScale.x * 0.9, this.originalScale.y * 0.9); // 90% of original size
  }

  protected onPointerUp(): void {
    this.scale.set(this.originalScale.x, this.originalScale.y);
  }

  public updateSprite(textureID: string, isCentered: boolean = false): void {
    if (this.sprite) {
      this.sprite.texture = Texture.from(textureID);
    } else {
      this.sprite = Sprite.from(textureID);
      this.addChild(this.sprite);
    }

    if (isCentered) {
      this.sprite.anchor.set(0.5, 0.5);
    }
  }

  setOriginalScale(scale: { x: number; y: number } | number): void {
    if (typeof scale === "number") {
      this.originalScale = { x: scale, y: scale };
    } else {
      this.originalScale = scale;
    }
  }
}
