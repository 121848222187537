import { Translator } from "./translation/Translator";

export enum FontFamily {
  KUI_CHAI = "MN_Kui_Chai",
  MILFORD = "Milford",
  HELVETICA = "HelveticaLTStd-BoldCond",
  CORMORANT_GARAMOND = "CormorantGaramond-Regular",
  SYSTEM_UI = "system-ui",
  TIMES_NEW_ROMAN = "Times New Roman",
  TTF_BasicFont_Bold = "TTF_BasicFont_Bold",
  FONT_AWESOME = "fontawesome-webfont",
  ARIAL = "Arial",
}

export class FontManager {
  private static _instance: FontManager | null = null;
  private _loadedFonts: Set<string> = new Set();

  private constructor() {}

  public static get instance(): FontManager {
    if (!FontManager._instance) {
      FontManager._instance = new FontManager();
    }
    return FontManager._instance;
  }

  public get defaultFontFamily(): FontFamily {
    return Translator.instance.language === "en"
      ? FontFamily.MILFORD
      : FontFamily.KUI_CHAI;
  }

  public async loadFonts(): Promise<void> {
    const fontsToLoad = [
      { name: FontFamily.KUI_CHAI, path: "assets/fonts/MN_Kui_Chai.woff2" },
      { name: FontFamily.MILFORD, path: "assets/fonts/Milford.woff2" },
      {
        name: FontFamily.CORMORANT_GARAMOND,
        path: "assets/fonts/CormorantGaramond-Regular.woff2",
      },
      {
        name: FontFamily.HELVETICA,
        path: "assets/fonts/HelveticaLTStd-BoldCond.woff2",
      },
      {
        name: FontFamily.TTF_BasicFont_Bold,
        path: "assets/fonts/TTF_BasicFont_Bold.woff2",
      },
      {
        name: FontFamily.FONT_AWESOME,
        path: "assets/fonts/fontawesome-webfont.woff2",
      },
    ];

    const fontLoadPromises = fontsToLoad.map((font) =>
      this.loadFont(font.name, font.path)
    );
    await Promise.all(fontLoadPromises);
  }

  private async loadFont(fontFamily: string, fontPath: string): Promise<void> {
    if (this._loadedFonts.has(fontFamily)) {
      return;
    }

    const font = new FontFace(fontFamily, `url(${fontPath})`);
    try {
      await font.load();
      document.fonts.add(font);
      this._loadedFonts.add(fontFamily);
    } catch (error) {
      console.error(`Failed to load font ${fontFamily}:`, error);
    }
  }
}
