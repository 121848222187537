import { AnimatedSprite, Container, Sprite } from "pixi.js";
import gsap from "gsap";
import { CoinSpinAnimation } from "../animations/CoinSpinAnimation";
import { Delay } from "../../common/src/utils/Delay";

export class BasePopup extends Container {
  protected text: Container;
  protected coinContainer: Container;

  constructor() {
    super();
    this.text = new Container();
    this.text.zIndex = 100;
    this.addChild(this.text);
    this.x = 640;
    this.y = 360;

    this.coinContainer = new Container();
    this.addChild(this.coinContainer);
    this.coinContainer.zIndex = 50;
  }

  protected createChildSprite(
    textureName: string,
    x: number | null,
    y: number | null,
    scaleX: number | null,
    scaleY: number | null,
    zIndex: number | null,
    isCentered: boolean | null,
    parent: Container = this
  ): Sprite {
    const sprite = Sprite.from(textureName);
    if (isCentered) sprite.anchor.set(0.5);
    if (x !== null) sprite.x = x;
    if (y !== null) sprite.y = y;
    sprite.scale.set(scaleX ?? 1, scaleY ?? scaleX ?? 1);
    sprite.zIndex = zIndex ?? 0;
    parent.addChild(sprite);
    return sprite;
  }

  public show(): void {
    this.visible = true;
    gsap.to(this, { alpha: 1, duration: 0.2 });

    this.showExploadingCoins();
  }

  public async hide(): Promise<void> {
    await gsap.to(this, { alpha: 0, duration: 0.2 });
    gsap.killTweensOf("*");
    this.visible = false;
  }

  protected async showExploadingCoins(
    numberOfCoins: number = 14
  ): Promise<void> {
    for (let i = 0; i < numberOfCoins; i++) {
      this.createAnimatedCoin();
      await Delay.delay(30 + Math.random() * 50);
    }
  }

  private createAnimatedCoin(): void {
    const coin = new CoinSpinAnimation();
    this.coinContainer.addChild(coin);

    // Randomize initial configuration of each coin
    coin.gotoAndPlay(Math.floor(Math.random() * coin.totalFrames)); // starting frame of the coin
    coin.animationSpeed = 0.2 + Math.random() * 0.1;
    coin.rotation = Math.random() * Math.PI * 2;
    coin.scale = 0.5 + Math.random() * 0.25;

    const angle = Math.random() * Math.PI * 2;
    const distance = 800 + Math.random() * 400;
    const endX = Math.cos(angle) * distance;
    const endY = Math.sin(angle) * distance;
    const targetScale = 1.5 + Math.random() * 0.5;
    const duration = 5 + Math.random() * 10;

    gsap.to(coin.scale, {
      x: targetScale,
      y: targetScale,
      duration: duration,
    });

    gsap.to(coin, {
      x: endX,
      y: endY,
      duration: duration,
      ease: "power1.out",
      onComplete: () => {
        this.coinContainer.removeChild(coin);
        coin.destroy();
      },
    });
  }
}
