import { ReelSet } from "./ReelSet";
import { Reel } from "./reel/Reel";
import { SuspenseLights } from "./reel/SuspenseLights";
import { SoundEffectManager } from "../../sounds/SoundEffectManager";
import { ScatterSymbol } from "./reel/symbols/ScatterSymbol";
import { Delay } from "../../common/src/utils/Delay";
import { BaseSymbol } from "./reel/symbols/BaseSymbol";
import { SOUNDS } from "../../sounds/Sounds";

export class ReelSetSuspenseHandler {
  private static _instance: ReelSetSuspenseHandler;
  private _isSuspenseAnimationActive: boolean = false;
  private _currentAnimatingSuspenseReelIndex: number = 99;
  private scatterSymbols: ScatterSymbol[] = [];

  private constructor() {}

  public static get instance(): ReelSetSuspenseHandler {
    if (!ReelSetSuspenseHandler._instance) {
      ReelSetSuspenseHandler._instance = new ReelSetSuspenseHandler();
    }
    return ReelSetSuspenseHandler._instance;
  }

  public get currentAnimatingSuspenseReelIndex(): number {
    return this._currentAnimatingSuspenseReelIndex;
  }

  public get isSuspenseAnimationActive(): boolean {
    return this._isSuspenseAnimationActive;
  }

  public async handleSuspenseAnimation(addedSymbol: BaseSymbol): Promise<void> {
    if (addedSymbol instanceof ScatterSymbol) {
      await this.handleScatterSymbol(addedSymbol);
    } else {
      await this.checkAndStartSuspenseAnimationForReel(addedSymbol);
    }
  }

  public async stopReelSetSuspenseAnimation(): Promise<void> {
    ReelSet.instance.unBlur();
    SuspenseLights.instance.showSuspenseLights(-1);
    this.performAllScattersIdleAnimation();
    this.resetProperties();
  }

  private async resetProperties(): Promise<void> {
    this._isSuspenseAnimationActive = false;
    this._currentAnimatingSuspenseReelIndex = 99;
    this.scatterSymbols = [];
  }

  private async startSuspenseAnimationForCurrentReel(): Promise<void> {
    // blur all reel with index lowwer then _currentAnimatingSuspenseReel.index
    for (
      let reelIndex = 0;
      reelIndex < this._currentAnimatingSuspenseReelIndex;
      reelIndex++
    ) {
      ReelSet.instance.reels[reelIndex].blur();
    }

    SuspenseLights.instance.showSuspenseLights(
      this._currentAnimatingSuspenseReelIndex
    );
    SoundEffectManager.instance.playSound(SOUNDS.ANTICIPATION);
  }

  private async checkAndStartSuspenseAnimationForReel(
    addedSymbol: BaseSymbol
  ): Promise<void> {
    // if last symbol on current reel - move suspense to next reel
    if (
      this._isSuspenseAnimationActive &&
      addedSymbol.reelIndex === this._currentAnimatingSuspenseReelIndex &&
      addedSymbol.symbolIndex === 3
    ) {
      if (addedSymbol.reelIndex != 4) {
        this._currentAnimatingSuspenseReelIndex++;
        this.startSuspenseAnimationForCurrentReel();
      }
    }
  }

  private async handleScatterSymbol(scatter: ScatterSymbol): Promise<void> {
    this.scatterSymbols.push(scatter);

    if (this.scatterSymbols.length === 2) {
      await this.startReelSetSuspenseAnimation();
    } else if (this.scatterSymbols.length === 3) {
      await this.handle3rdScatterAnimationAndStopSuspense(scatter);
    }
  }

  private async startReelSetSuspenseAnimation(): Promise<void> {
    // get the scatter symbol with the max reel index
    this._isSuspenseAnimationActive = true;

    let scatterInHighestReelIndex =
      this.scatterSymbols[0].reelIndex > this.scatterSymbols[1].reelIndex
        ? this.scatterSymbols[0]
        : this.scatterSymbols[1];

    // if the scatter is in the last reel and on the last symbol - dont start the suspense animation
    if (
      scatterInHighestReelIndex.symbolIndex == 3 &&
      scatterInHighestReelIndex.reelIndex == 4
    ) {
      return;
    }

    if (scatterInHighestReelIndex.symbolIndex == 3) {
      this._currentAnimatingSuspenseReelIndex =
        scatterInHighestReelIndex.reelIndex + 1;
    } else {
      this._currentAnimatingSuspenseReelIndex =
        scatterInHighestReelIndex.reelIndex;
      await Delay.delay(99); // let it land
    }

    this.performAllScattersSuspenseAnimation();
    await this.startSuspenseAnimationForCurrentReel();
  }

  private async handle3rdScatterAnimationAndStopSuspense(
    scatter: ScatterSymbol
  ): Promise<void> {
    scatter.performScatterSuspenseAnimation();
    await Delay.delay(34); // let it land
    await this.stopReelSetSuspenseAnimation();
    this._isSuspenseAnimationActive = false;
  }

  private performAllScattersIdleAnimation(): void {
    this.scatterSymbols.forEach((scatter, i) => {
      Delay.delay(150*i).then(()=>{
        scatter.performScatterIdleAnimation();
      })
    });
  }

  private performAllScattersSuspenseAnimation(): void {
    this.scatterSymbols.forEach((scatter) => {
      scatter.performScatterSuspenseAnimation();
    });
  }
}
